import React, { useMemo } from 'react'
import { TaxRecord } from '../../../../../generated/graphql'
import numeral from 'numeral'
type Props = {
    taxRecords: TaxRecord[]
    noTitle?: boolean
    maxRecords?: number
}
export function TaxRecords(props: Props) {
    const maxRecords = props.maxRecords || 9
    const startReords = props?.maxRecords > props.taxRecords.length ? 0 : props.taxRecords.length - maxRecords

    const parcelIds = useMemo(() => {
        if (!props.taxRecords || props.taxRecords.length == 0) return null
        const parcelIds = []
        for (const t of props.taxRecords) {
            if (!parcelIds.includes(t.parcelId)) parcelIds.push(t.parcelId)
        }

        return parcelIds
    }, [props.taxRecords])

    const taxRecords = useMemo(() => {
        if (!props.taxRecords || props.taxRecords.length == 0) return null
        if (parcelIds.length <= 1) {
            return maxRecords ? props.taxRecords.slice(startReords) : props.taxRecords
        } else {
            const taxRecords = []
            for (const pId of parcelIds) {
                const records = props.taxRecords.filter((t) => t.parcelId == pId)
                records.sort((a: TaxRecord, b: TaxRecord) => {
                    const yearA = +a.year
                    const yearB = +b.year
                    if (yearA > yearB) return -1
                    else if (yearA == yearB) return 0

                    return 1
                })
                taxRecords.push(records[0])
            }

            return taxRecords
        }
    }, [maxRecords, parcelIds, props.taxRecords])

    if (!props.taxRecords || props.taxRecords.length == 0) return null
    return (
        <section className="cblock" id="tax-records">
            <div className="container">
                <div className="row">
                    <div className="cblock__head">
                        {!props?.noTitle && (
                            <p className="cblock__sect">
                                <span>Tax Records</span>
                            </p>
                        )}
                    </div>
                </div>
                <table className=" table table-borderless table-striped table--smallmobile">
                    <thead className="">
                        <tr className="">
                            <th></th>
                            {taxRecords.map((record) => (
                                <th key={`${record.id}`} className="">
                                    {record.year}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="">
                        {parcelIds.length > 1 && (
                            <>
                                <tr className="font-weight-bold">
                                    <td className="">Parcel Id</td>
                                    {taxRecords.map((record) => (
                                        <td key={`${record.id}`} className="">
                                            {record.parcelId}
                                        </td>
                                    ))}
                                </tr>
                                <tr className="">
                                    <td className="">Parcel Address</td>
                                    {taxRecords.map((record) => (
                                        <td key={`${record.id}`} className="">
                                            {record.address}
                                        </td>
                                    ))}
                                </tr>
                            </>
                        )}
                        <tr className="font-weight-bold">
                            <td className="">Tax Amount</td>
                            {taxRecords.map((record) => (
                                <td key={`${record.id}`} className="">
                                    {formatCurrency(record.taxAmount)}
                                </td>
                            ))}
                        </tr>
                        <tr className="">
                            <td className="">YoY Change in Tax Amount</td>
                            {taxRecords.map((record) => {
                                let color = ''
                                if (record.YoYchange > 0) {
                                    color = '#20c997'
                                } else if (record.YoYchange < 0) {
                                    color = '#e83e8c'
                                }
                                return (
                                    <td key={`${record.id}`} style={{ color }}>
                                        {formatPercent(record.YoYchange, 'Unchanged')}
                                    </td>
                                )
                            })}
                        </tr>
                        <tr className="">
                            <td className="">Assessed Land Value</td>
                            {taxRecords.map((record) => {
                                return (
                                    <td key={`${record.id}`} className="">
                                        {formatCurrency(record.assessedLandValue)}
                                    </td>
                                )
                            })}
                        </tr>
                        <tr className="">
                            <td className="">Assessed Improvement Value</td>
                            {taxRecords.map((record) => (
                                <td key={`${record.id}`} className="">
                                    {formatCurrency(record.assessedImprovementValue)}
                                </td>
                            ))}
                        </tr>
                        <tr className="">
                            <td className="">Total Assessed Value</td>
                            {taxRecords.map((record) => (
                                <td key={`${record.id}`} className="">
                                    {formatCurrency(record.totalAssessedValue)}
                                </td>
                            ))}
                        </tr>
                        <tr className="">
                            <td className="">Applied Tax Rate</td>
                            {taxRecords.map((record) => (
                                <td className="" key={`${record.id}`}>
                                    {formatPercent(record.appliedTaxRate)}
                                </td>
                            ))}
                        </tr>
                        <tr className="">
                            <td className="">Land Market Value</td>
                            {taxRecords.map((record) => (
                                <td className="" key={`${record.id}`}>
                                    {formatCurrency(record.landMarketValue)}
                                </td>
                            ))}
                        </tr>
                        <tr className="">
                            <td className="">Improvements Market Value</td>
                            {taxRecords.map((record) => (
                                <td className="" key={`${record.id}`}>
                                    {formatCurrency(record.improvementMarketValue)}
                                </td>
                            ))}
                        </tr>
                        <tr className="">
                            <td className="">Total Market Value</td>
                            {taxRecords.map((record) => (
                                <td className="" key={`${record.id}`}>
                                    {formatCurrency(record.totalMarketValue)}
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
            <style jsx>{`
                .table {
                    color: white;
                }
            `}</style>
        </section>
    )
}
function formatCurrency(value) {
    if (isNaN(value)) return '-'
    return numeral(value).format('$0,0.[00]')
}
function formatPercent(value, def = '-') {
    if (!value) return def
    return numeral(value).format('0,0.[00]') + '%'
}
