import React from 'react'
import { ApexChart } from '../../../../charts/ApexChart'
export function MortgageRecords(props: { propertyEnrichments: any; noTitle?: boolean }) {
    if (!props.propertyEnrichments || !props.propertyEnrichments.mortgagerecords) return null
    //mortgage records chart
    const project = props.propertyEnrichments
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const mortgageChartData = []
    const linearChartData = {
        labels: [],
        series: [
            {
                name: '',
                data: [],
            },
        ],
    }
    const barChartDataMortgage = {
        series: [],
        labels: [],
    }
    if (project.mortgageRecordsChartVisible && +project.mortgageRecordsChartVisible === 1) {
        for (let kk = 0; kk < project.mortgagerecords.length; kk++) {
            const date1 = new Date(Date.parse(project.mortgagerecords[kk].start_date))
            mortgageChartData.push({ col: date1, row: +project.mortgagerecords[kk].amount })
            linearChartData.labels.push(date1)
            linearChartData.series[0].data.push(+project.mortgagerecords[kk].amount)
        }
        //
        mortgageChartData.sort(function (a, b) {
            return a.col - b.col
        })
        //
        for (let jj = 0; jj < mortgageChartData.length; jj++) {
            mortgageChartData[jj].col =
                monthNames[mortgageChartData[jj].col.getMonth()] +
                ' ' +
                mortgageChartData[jj].col.getDate() +
                ',' +
                mortgageChartData[jj].col.getFullYear()
        }
        //

        if (project.mortgageRecordsChartType === 'linear') {
            // createLinearChart(mortgageChartAchor, mortgageChartData, '', 'Amount')
        }

        if (project.mortgageRecordsChartType === 'bar') {
            for (let i = 0; i < mortgageChartData.length; i++) {
                barChartDataMortgage.labels.push(mortgageChartData[i].col)
                barChartDataMortgage.series.push(mortgageChartData[i].row)
            }

            // console.log(barChartData)

            //createVerticalBarChart(mortgageChartAchor, barChartDataMortgage)
        }
    }

    if (!props?.propertyEnrichments?.mortgagerecords || props?.propertyEnrichments?.mortgagerecords?.length == 0) {
        return null
    }

    return (
        <section className={'cblock'} id={'mort'}>
            <div className={'container'}>
                <div className={'row'}>
                    <div className="cblock__head">
                        {!props?.noTitle && (
                            <p className="cblock__sect">
                                <span>Mortgage Records</span>
                            </p>
                        )}
                        <div className="cblock__body">
                            <div className="table-responsive">
                                <table className="table table-borderless table-striped table--smallmobile">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Maturity Date</th>
                                            <th>Lender(s)</th>
                                            <th className="text-right">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody id="mortgagerecords">
                                        {props.propertyEnrichments.mortgagerecords.map((record, ind) => {
                                            let maturityDate = '---'
                                            if (
                                                record.maturity_date &&
                                                record.maturity_date != '' &&
                                                record.maturity_date != 'null'
                                            )
                                                maturityDate = record.maturity_date
                                            return (
                                                <tr key={`${ind}`}>
                                                    <td>{record.start_date}</td>
                                                    <td>{maturityDate}</td>
                                                    <td>{record.lender}</td>
                                                    <td className="text-right font-weight-bold">
                                                        ${parseInt(record.amount).toLocaleString()}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            <p className="small text-muted ">Source: Cherre.com</p>

                            {project.mortgageRecordsChartVisible === '1' && (
                                <div id="mortgage-records-chart">
                                    {project.mortgageRecordsChartType === 'linear' && (
                                        <ApexChart
                                            type={'line'}
                                            series={linearChartData.series}
                                            options={createLinearChartOptions(mortgageChartData, '', 'Amount')}
                                        />
                                    )}
                                    {project.mortgageRecordsChartType === 'bar' && (
                                        <ApexChart
                                            type={'bar'}
                                            series={linearChartData.series}
                                            options={createVerticalBarChartOptions(barChartDataMortgage, {})}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <style jsx>
                {`
                    #mortgagerecords {
                        color: #fff;
                    }
                `}
            </style>
        </section>
    )
}
export function createLinearChartOptions(data, chartTitle = '', tooltipTitle = '') {
    const cols = []
    const rows = []

    for (let i = 0; i < data.length; i++) {
        cols.push(data[i].col)
        rows.push(data[i].row)
    }
    return {
        chart: {
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        colors: ['#72D7E9'],
        tooltip: {
            theme: 'dark',
            x: {
                formatter: function (val) {
                    return val
                },
            },
            y: {
                formatter: function (val) {
                    return '$' + val
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'straight',
        },
        title: {
            text: chartTitle,
            align: 'left',
        },
        grid: {
            show: true,
            row: {
                colors: ['transparent'], // takes an array which will be repeated on columns
                // opacity: 0.05
            },
        },
        xaxis: {
            categories: cols,
        },
    }
}
export function createVerticalBarChartOptions(data, params) {
    const chartParams = {
        seriesTitle: '',
        yAxisTitle: '',
        valuePrefix: '',
        valueSuffix: '',
        ...params,
    }

    return {
        chart: {
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: data.labels,
        },
        yaxis: {
            title: {
                text: chartParams.yAxisTitle,
                style: {
                    color: '#fff',
                },
            },
            decimalsInFloat: 0,
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            theme: 'dark',
            y: {
                formatter: function (val) {
                    return chartParams.valuePrefix + ' ' + val + ' ' + chartParams.valueSuffix
                },
            },
        },
    }
}
/*
         var maturity_date="---";
             if(mortgagerecords[i].maturity_date && mortgagerecords[i].maturity_date!='' && mortgagerecords[i].maturity_date!='null') maturity_date=mortgagerecords[i].maturity_date;
             mortgagerecordshtml+=' <tr><td>'+mortgagerecords[i].start_date+'</td><td>'+maturity_date+'</td><td>'+mortgagerecords[i].lender+'</td><td class="text-right font-weight-bold">$'+parseInt(mortgagerecords[i].amount).toLocaleString()+'</td></tr>';

 */
