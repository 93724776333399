import React from 'react'

type Props = {
    title: string
    className?: string
    children?: React.ReactNode
    titleSmall?: boolean
    style?: any
}

export default function ReportSection(props: Props) {
    const { title, className, children } = props

    return (
        <div className={`ReportSection ${className || ''}`}>
            {props.title ? props.titleSmall ? <h4>{title}</h4> : <h3>{title}</h3> : null}
            <div className={'sectionContent'} style={props.style || {}}>
                {children}
            </div>
        </div>
    )
}
