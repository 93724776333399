import React from 'react'
import { FunctionalComponent } from 'preact'
import { useFormState } from 'react-final-form'
import { statesList } from './PropertyAndBorrower'
import numeral from 'numeral'

type Props = any

export const Summary: FunctionalComponent<Props> = (props: Props) => {
    const formState = useFormState()
    const formValues = formState.values
    return (
        <div className={'lender-publish-wrapper'}>
            {!props.isPDF && (
                <>
                    <div className="lender-publish-page-title SummaryMain mainTitle mainTitle1">
                        All done! We’ve got the request loan details covered
                    </div>
                    <div className="GIMainText">
                        Here’s a summary of what you entered. You can change information anytime on the General Inputs
                        page in the model.
                    </div>
                </>
            )}
            <div className={'tableWrapper'}>
                <div className={'column'}>
                    <div className={'innerTable'}>
                        <div className={'summaryRow summaryHeader'}>
                            <div className={'headerTitle'}>Target Dates</div>
                            <div className={'headerValue'}></div>
                        </div>
                        <div className={'summaryRow'} style={{ background: 'white' }}>
                            <div className={'title'}>Target Loan Closing Date</div>
                            <div className={'value'}>
                                {(function () {
                                    const d = new Date(formValues.loanDetails.targetLoanClosing)
                                    return d.getMonth() + 1 + '/' + d.getDate() + '/' + d.getFullYear()
                                })()}
                            </div>
                        </div>

                        <div className={'summaryRow'} style={{ height: '30px', backgroundColor: '#f5f6f9' }}></div>
                    </div>

                    <div className={'innerTable'}>
                        <div className={'summaryRow summaryHeader'}>
                            <div className={'headerTitle'}>Property Details</div>
                            <div className={'headerValue'}></div>
                        </div>
                        <div className={'summaryRow'}>
                            <div className={'title'}>Property Name</div>
                            <div className={'value'}>{formValues.propertyAndBorrower.propertyName}</div>
                        </div>

                        <div className={'summaryRow'}>
                            <div className={'title'}>Year Built</div>
                            <div className={'value'}>{formValues.propertyAndBorrower.yearBuilt}</div>
                        </div>
                        <div className={'summaryRow'}>
                            <div className={'title'}>Last Renovated</div>
                            <div className={'value'}>{formValues.propertyAndBorrower.yearRenovated}</div>
                        </div>
                        <div className={'summaryRow'}>
                            <div className={'title'}>Number Of Units</div>
                            <div className={'value'}>{formValues.propertyAndBorrower.numberOfUnits}</div>
                        </div>

                        <div className={'summaryRow'}>
                            <div className={'title'}>Address</div>
                            <div className={'value'}>{formValues.propertyAndBorrower.address}</div>
                        </div>
                        <div className={'summaryRow'}>
                            <div className={'title'}>City</div>
                            <div className={'value'}>{formValues.propertyAndBorrower.city}</div>
                        </div>
                        <div className={'summaryRow'}>
                            <div className={'title'}>County</div>
                            <div className={'value'}>{formValues.propertyAndBorrower.county}</div>
                        </div>
                        <div className={'summaryRow'}>
                            <div className={'title'}>State</div>
                            <div className={'value'}>
                                {statesList.find((state) => state.value == formValues.propertyAndBorrower.state)?.label}
                            </div>
                        </div>
                        <div className={'summaryRow'}>
                            <div className={'title'}>Zip Code</div>
                            <div className={'value'}>{formValues.propertyAndBorrower.zip}</div>
                        </div>

                        <div className={'summaryRow'}>
                            <div className={'title'}>MSA</div>
                            <div className={'value'}>{formValues.propertyAndBorrower.msa}</div>
                        </div>
                        <div className={'summaryRow'}>
                            <div className={'title'}>Census Tract</div>
                            <div className={'value'}>{formValues.propertyAndBorrower.censusTract}</div>
                        </div>
                        <div className={'summaryRow'}>
                            <div className={'title'}>Property Type</div>
                            <div className={'value'}>{formValues.propertyAndBorrower.propertyType}</div>
                        </div>
                        <div className={'summaryRow'}>
                            <div className={'title'}>Building Type</div>
                            <div className={'value'}>{formValues.propertyAndBorrower.buildingType}</div>
                        </div>
                        <div className={'summaryRow'}>
                            <div className={'title'}>MAH (Gateway)</div>
                            <div className={'value'}>{formValues.propertyAndBorrower.MAH ? 'Yes' : 'No'}</div>
                        </div>
                    </div>
                </div>
                <div className={'column'}>
                    <div className={'innerTable'}>
                        <div className={'summaryRow summaryHeader'}>
                            <div className={'headerTitle'}>Loan Details</div>
                            <div className={'headerValue'}></div>
                        </div>

                        <div className={'summaryRow'}>
                            <div className={'title'}>Requested Loan Amount</div>
                            <div className={'value'}>
                                {numeral(formValues.loanDetails.requestedLoanAmount).format('$0,0.[00]')}
                            </div>
                        </div>
                        <div className={'summaryRow'}>
                            <div className={'title'}>Requested Loan Term</div>
                            <div className={'value'}>{formValues.loanDetails.requestedLoanTerm}</div>
                        </div>
                        <div className={'summaryRow'}>
                            <div className={'title'}>Requested IO</div>
                            <div className={'value'}>{formValues.loanDetails.requestedLoanIO}</div>
                        </div>
                        <div className={'summaryRow'}>
                            <div className={'title'}>Requested Prepayment (lock) period</div>
                            <div className={'value'}>{formValues.loanDetails.requestedLoanPrePaymentLock}</div>
                        </div>

                        <div className={'summaryRow'}>
                            <div className={'title'}>Requested Tier Level</div>
                            <div className={'value'}>{formValues.loanDetails.requestedTierLevel}</div>
                        </div>

                        <div className={'summaryRow'}>
                            <div className={'title'}>Interest Type</div>
                            <div className={'value'}>{formValues.loanDetails.interestType}</div>
                        </div>

                        <div className={'summaryRow'}>
                            <div className={'title'}>Lien Position</div>
                            <div className={'value'}>{formValues.loanDetails.lienPosition}</div>
                        </div>
                        <div className={'summaryRow'}>
                            <div className={'title'}>Acquisition or Refinance</div>
                            <div className={'value'}>{formValues.loanDetails.acquisitionOrRefinance}</div>
                        </div>
                        <div className={'summaryRow'}>
                            <div className={'title'}>Borrower Loan Purpose (4662)</div>
                            <div className={'value'}>{formValues.loanDetails.borrowerLoanPurpose}</div>
                        </div>

                        <div className={'summaryRow'}>
                            <div className={'title'}>Loan Purpose (Gateway)</div>
                            <div className={'value'}>{formValues.loanDetails.loanPurposeGateway}</div>
                        </div>
                        <div className={'summaryRow'}>
                            <div className={'title'}>ARM Type</div>
                            <div className={'value'}>{formValues.loanDetails.ARMType}</div>
                        </div>
                        <div className={'summaryRow'}>
                            <div className={'title'}>MSupplemental</div>
                            <div className={'value'}>{formValues.loanDetails.supplemental ? 'Yes' : 'No'}</div>
                        </div>
                        <div className={'summaryRow'}>
                            <div className={'title'}>Assumption</div>
                            <div className={'value'}>{formValues.loanDetails.assumption ? 'Yes' : 'No'}</div>
                        </div>
                        <div className={'summaryRow'}>
                            <div className={'title'}>Transfer</div>
                            <div className={'value'}>{formValues.loanDetails.transfer ? 'Yes' : 'No'}</div>
                        </div>
                    </div>
                </div>
                <div className={'column'}>
                    <div className={'innerTable'}>
                        <div className={'summaryRow summaryHeader'}>
                            <div className={'headerTitle'}>Deal Details</div>
                            <div className={'headerValue'}></div>
                        </div>

                        <div className={'summaryRow'}>
                            <div className={'title'}>Purchase Price</div>
                            <div className={'value'}>
                                {numeral(formValues.dealDetails.purchasePrice).format('$0,0.[00]')}
                            </div>
                        </div>

                        <div className={'summaryRow'}>
                            <div className={'title'}>Current UPB</div>
                            <div className={'value'}>
                                {numeral(formValues.dealDetails.currentUPB).format('$0,0.[00]')}
                            </div>
                        </div>

                        <div className={'summaryRow'}>
                            <div className={'title'}>Cash Out</div>
                            <div className={'value'}>{formValues.dealDetails.cashout ? 'Yes' : 'No'}</div>
                        </div>

                        <div className={'summaryRow'}>
                            <div className={'title'}>Construction Cost</div>
                            <div className={'value'}>
                                {numeral(formValues.dealDetails.constructionCost).format('$0,0.[00]')}
                            </div>
                        </div>

                        <div className={'summaryRow'}>
                            <div className={'title'}>Current/Recent Construction</div>
                            <div className={'value'}>{formValues.dealDetails.recentConstruction ? 'Yes' : 'No'}</div>
                        </div>

                        <div className={'summaryRow'}>
                            <div className={'title'}>Escrowed Improvements</div>
                            <div className={'value'}>
                                {numeral(formValues.dealDetails.escrowedImprovements).format('$0,0.[00]')}
                            </div>
                        </div>

                        <div className={'summaryRow'}>
                            <div className={'title'}>Prepay/Additional Payoffs</div>
                            <div className={'value'}>{numeral(formValues.dealDetails.prepay).format('$0,0.[00]')}</div>
                        </div>

                        <div className={'summaryRow'}>
                            <div className={'title'}>Cap Ex</div>
                            <div className={'value'}>
                                {numeral(formValues.dealDetails.budgetedCapEx).format('$0,0.[00]')}
                            </div>
                        </div>

                        <div className={'summaryRow'} style={{ height: '5px' }}></div>

                        <div className={'summaryRow summaryHeader'}>
                            <div className={'headerTitle'}>Green</div>
                            <div className={'headerValue'}></div>
                        </div>

                        <div className={'summaryRow'}>
                            <div className={'title'}>Green Financing Type</div>
                            <div className={'value'}>{formValues.dealDetails.greenFinancingType}</div>
                        </div>
                        <div className={'summaryRow'}>
                            <div className={'title'}>Green Building Certification Type</div>
                            <div className={'value'}>{formValues.dealDetails.greenBuildingCertificationType}</div>
                        </div>

                        <div className={'summaryRow summaryHeader'}>
                            <div className={'headerTitle'}>Rehabilitation</div>
                            <div className={'headerValue'}></div>
                        </div>

                        <div className={'summaryRow'}>
                            <div className={'title'}>Moderate Rehabilitation (Gateway)</div>
                            <div className={'value'}>
                                {formValues.dealDetails.moderateRehabilitationGateway ? 'Yes' : 'No'}
                            </div>
                        </div>

                        <div className={'summaryRow'}>
                            <div className={'title'}>Rehabilitation Work ($/Unit)</div>
                            <div className={'value'}>
                                {numeral(formValues.dealDetails.rehabilitationWorkPerUnit).format('$0,0.[00]')}
                            </div>
                        </div>
                        <div className={'summaryRow'}>
                            <div className={'title'}>Rehabilitation Work Escrow Amount</div>
                            <div className={'value'}>
                                {numeral(formValues.dealDetails.rehabilitationWorkEscrowAmount).format('$0,0.[00]')}
                            </div>
                        </div>

                        <div className={'summaryRow summaryHeader'}>
                            <div className={'headerTitle'}>Stabilization</div>
                            <div className={'headerValue'}></div>
                        </div>
                        <div className={'summaryRow'}>
                            <div className={'title'}>Condominium Regime Type</div>
                            <div className={'value'}>{formValues.dealDetails.condominiumRegimeType}</div>
                        </div>

                        <div className={'summaryRow'}>
                            <div className={'title'}>Property Stabilization Type</div>
                            <div className={'value'}>{formValues.dealDetails.propertyStabilizationLevel}</div>
                        </div>

                        <div className={'summaryRow'}>
                            <div className={'title'}>Land Ownership Rights (4662)</div>
                            <div className={'value'}>{formValues.dealDetails.landOwnershipRights}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'row'} style={{ marginBottom: '150px' }}></div>
            {/*language=scss*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .SummaryMain {
                        margin-bottom: 10px;
                    }
                    .GIMainText {
                        font-family: 'Inter';
                        font-style: normal;

                        margin-top: 10px;
                        max-width: 515px;

                        font-weight: 400 !important;
                        font-size: 14px !important;
                        line-height: 19.6px;
                        color: #808fa7;
                    }
                    .tableWrapper {
                        display: flex;
                        gap: 20px;
                    }

                    .column {
                        width: 430px;
                        background-color: white;
                    }

                    .innerTable {
                        background-color: white;
                        border: 1px solid #d9e0e5;
                        border-radius: 3px;
                        width: 100%;
                        .summaryRow {
                            display: flex;
                            gap: 5px;
                            width: 100%;
                            //height: 35px;

                            .headerTitle {
                                margin-left: 10px;
                                margin-top: 15px;
                                width: 64%;
                                line-height: 18px;
                            }
                            .headerValue {
                                margin-left: 10px;
                                margin-top: 15px;
                                width: 35%;
                                line-height: 18px;
                            }
                        }
                        .summaryRow:nth-child(odd) {
                            background: #ffffff;
                        }
                        .summaryRow:nth-child(even) {
                            background: rgba(217, 224, 229, 0.15);
                            mix-blend-mode: normal;
                        }
                        .summaryHeader {
                            height: 55px !important;
                        }

                        .title {
                            width: 95%;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            color: #687893;
                            margin: 10px;
                        }
                        .value {
                            width: 80%;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 15px;
                            line-height: 18px;
                            color: #161c26;
                            margin: 10px;
                        }
                    }
                `}
            </style>
        </div>
    )
}
