import React, { useEffect, useMemo, useState } from 'react'
import OpeningPage from './OpeningPage'

import DealInformationSections from './DealInformationSections'

import { SubMarketInsights } from './om/SubMarketInsights'
import BasePageLayoutOM from './BasePageLayoutOM'
import { DealHighlightsLoans } from '../../expose/dealhighlights/DealHighlightsLoans'
import { DealHighlightsFinancingRequirements } from '../../expose/dealhighlights/DealHighlightsFinancingRequirements'
import { DealHighlightsTables } from '../../expose/dealhighlights/DealHighlightsTables'
import { DealHighlightsDetails } from '../../expose/dealhighlights/DealHighlightsDetails'
import ReportSection from './ReportSection'
import NMAboutUS from './om/NMAboutUS'
import { Property } from './om/Property'
import { Construction } from './om/Construction'
import { GoogleApiWrapper } from 'google-maps-react'
import { mapApiKey } from '../../../defaultElements/MapAdapter'
import { Comparables } from './om/Comparables'
import { T12Financials } from './om/T12Financials'
import { GeneralInputs } from './om/GeneralInputs'

const DEBUG_NOT_SHOW_PAGE = false
export function HtmlReporOM(props: any) {
    // console.log('HtmlReporOM props', props)

    const { narrative } = props

    const headerSubtitle = [narrative?.dealName, narrative?.propertyAddress].filter((item) => !!item).join(', ')

    const [isNM, setIsNM] = useState(props?.user?.isAdmin || false)
    useEffect(() => {
        if (props?.user?.isAdmin) setIsNM(true)
        if (localStorage) {
            const accountName = localStorage.getItem('accountName')
            if (accountName?.toLowerCase().indexOf('northmarq') > -1) setIsNM(true)
            if (accountName?.toLowerCase().indexOf('Kobi test') > -1) setIsNM(true)
        }
    }, [props?.user?.isAdmin])
    return (
        <>
            <div id="dpi" />

            <div className="HtmlReport">
                <div className={'reportPage page a4-150dpiSpecialOM'}>
                    <OpeningPage narrative={narrative} />
                </div>
                {(false || !DEBUG_NOT_SHOW_PAGE) && isNM && (
                    <div className={'reportPage page a4-150dpiSpecialOM'}>
                        <div style={{ backgroundColor: '#013da8' }}>
                            <BasePageLayoutOM title={'About US'} subtitle={headerSubtitle}>
                                <NMAboutUS />
                            </BasePageLayoutOM>
                        </div>
                    </div>
                )}
                {(false || !DEBUG_NOT_SHOW_PAGE) && (
                    <div className={'reportPage page a4-150dpiSpecialOM'}>
                        <BasePageLayoutOM title={'Deal Information'} subtitle={headerSubtitle}>
                            <DealInformationSections narrative={narrative} />
                        </BasePageLayoutOM>
                    </div>
                )}

                {(false || !DEBUG_NOT_SHOW_PAGE) && <GeneralInputs {...props} headerSubtitle={headerSubtitle} />}

                {(false || !DEBUG_NOT_SHOW_PAGE) &&
                    props?.commonInfo?.sideMenu?.find((m) => m.id == 'Deal Highlights') && (
                        <div className={'reportPage page a4-150dpiSpecialOM'}>
                            <BasePageLayoutOM title={'Deal Highlights'} subtitle={headerSubtitle}>
                                <ReportSection
                                    title={'Deal Highlights'}
                                    className={'DealHighlights'}
                                    titleSmall={false}
                                >
                                    <DealHighlightsLoans dealHighlights={props.dealHighlights} noTitle={true} />
                                </ReportSection>

                                {/*financing requirements section*/}
                                <DealHighlightsFinancingRequirements dealOutline={props.dealOutline} />

                                {/*tables section*/}
                                <DealHighlightsTables dealHighlights={props.dealHighlights} />

                                {/*details section*/}
                                <DealHighlightsDetails dealHighlights={props.dealHighlights} />
                            </BasePageLayoutOM>
                        </div>
                    )}
                {(false || !DEBUG_NOT_SHOW_PAGE) && <Property {...props} headerSubtitle={headerSubtitle} />}

                {(false || !DEBUG_NOT_SHOW_PAGE) && (
                    <T12Financials {...props} headerSubtitle={headerSubtitle}></T12Financials>
                )}

                {(true || !DEBUG_NOT_SHOW_PAGE) && <SubMarketInsights {...props} headerSubtitle={headerSubtitle} />}
                {(false || !DEBUG_NOT_SHOW_PAGE) && props.construction.enabled && (
                    <Construction {...props} headerSubtitle={headerSubtitle} />
                )}
                {(false || !DEBUG_NOT_SHOW_PAGE) && <Comparables {...props} allowDelete={false} />}

                {/*language=SCSS*/}
                <style jsx>
                    {`
                        #propertyOverviewImg {
                            display: block;
                            margin: 0 auto;
                        }
                    `}
                </style>
            </div>
        </>
    )
}

export default GoogleApiWrapper({
    apiKey: mapApiKey,
    // @ts-ignore
})(HtmlReporOM)
