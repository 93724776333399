import React, { useMemo } from 'react'

import numeral from 'numeral'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../store'
import BasePageLayoutOM from '../../BasePageLayoutOM'
import ReportSection from '../../ReportSection'
import { InsightsTable } from '../../../../operating-assumptions/submarket-insights/InsightsTable'
import { ApexPropertyVsCompsChart } from '../../../../operating-assumptions/submarket-insights/comparison/ApexAskingRentPropertyVsCompsChart'
import { CalculatedInfo } from '../../../../operating-assumptions/submarket-insights/CalculatedInfo'
import { ApexPropertyVsSubmarketChart } from '../../../../operating-assumptions/submarket-insights/comparison/ApexAskingRentPropertyVsSubmarketChart'

type Props = {
    chartDataSubmarket?: {
        labels: []
        values: []
        colors: []
    }
    chartDataComps?: {
        labels: []
        values: []
        colors: []
        median: number
    }
    tableDataSub: any
    tableDataComps: any
    isOM?: boolean
    headerSubtitle?: string
}

export const AskingRentDistributionOM = (props: any) => {
    const dataSub = useSelector(
        (state: ReduxStoreState) =>
            state.operatingAssumptions.submarketInsights.comparison.askingRentDistributionSubmarket,
    )
    const dataComps = useSelector(
        (state: ReduxStoreState) => state.operatingAssumptions.submarketInsights.comparison.askingRentDistributionComps,
    )
    const chartDataSubmarket = {
        labels: [] as string[],
        values: [] as number[],
        colors: [] as string[],
    }
    const chartDataComps = {
        labels: [] as string[],
        values: [] as number[],
        colors: [] as string[],
        median: 0,
    }
    chartDataSubmarket.labels = useMemo(() => {
        const out = []
        for (const [i, item] of dataSub.distribution.entries()) {
            out.push(`${i == 0 ? 'Under' : numeral(item.low).format('$0,0')} - ${numeral(item.high).format('$0,0')}`)
        }
        return out
    }, [dataSub])
    chartDataSubmarket.values = useMemo(() => {
        return dataSub.distribution.map((item) => item.value)
    }, [dataSub])
    chartDataSubmarket.colors = ['#5791FF', '#DC82CF']

    chartDataComps.labels = useMemo(() => {
        return ['Property'].concat(dataComps.comps.map((item) => `${item.name || item.address}`))
    }, [])
    chartDataComps.values = [dataComps.property].concat(dataComps.comps.map((item) => item.value))
    chartDataComps.colors = ['#5791FF', '#DC82CF']
    chartDataComps.median = dataComps.compsAverage

    const tableDataSub = useMemo(() => {
        return {
            header: [
                {
                    cells: [
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: 'Low',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: '25%',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: 'Mean',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: 'Median',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: '75%',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: 'High',
                            bordering: false,
                            align: 'right',
                        },
                    ],
                },
            ],
            body: [
                {
                    title: '',
                    cells: [
                        {
                            content: 'Asking Rent Distribution',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: numeral(dataSub.low).format('$0,0'),
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: numeral(dataSub.p25).format('$0,0'),
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: numeral(dataSub.mean).format('$0,0'),
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: numeral(dataSub.median).format('$0,0'),
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: numeral(dataSub.p75).format('$0,0'),
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: numeral(dataSub.high).format('$0,0'),
                            bordering: false,
                            align: 'right',
                        },
                    ],
                },
            ],
        }
    }, [dataSub])

    const tableDataComps = useMemo(() => {
        return {
            header: [
                {
                    cells: [
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: 'Effective Rent',
                            bordering: false,
                            align: 'right',
                        },
                    ],
                },
            ],
            body: [
                {
                    title: '',
                    cells: [
                        {
                            content: 'The Property',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: numeral(dataComps.property).format('$0,0'),
                            bordering: false,
                            align: 'right',
                        },
                    ],
                },
            ].concat(
                dataComps.comps.map((comp) => ({
                    title: '',
                    cells: [
                        {
                            content: `${comp.name ? `${comp.name}:` : ''}${comp.address}`,
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: numeral(comp.value).format('$0,0'),
                            bordering: false,
                            align: 'right',
                        },
                    ],
                })),
            ),
        }
    }, [dataComps])

    return (
        <>
            {props.isOM && (
                <>
                    <div className={'reportPage page a4-150dpiSpecialOM'}>
                        <BasePageLayoutOM title={'SubMarket Insights'} subtitle={props.headerSubtitle}>
                            <ReportSection
                                title={null}
                                className={'Comparison'}
                                titleSmall={true}
                                style={{ position: 'relative', left: '-20px' }}
                            >
                                <h3 className=" mb-3">Asking Rent Distribution</h3>
                                <CalculatedInfo />
                                <h5 className=" mb-3">Property vs Submarket</h5>
                                <div className="graphrow">
                                    <ApexPropertyVsSubmarketChart
                                        values={chartDataSubmarket.values}
                                        labels={chartDataSubmarket.labels}
                                        colors={chartDataSubmarket.colors}
                                        height={380}
                                        highlightIndex={useMemo(
                                            () =>
                                                dataSub.distribution.reduce((acc, item, i) => {
                                                    if (
                                                        dataComps.property >= item.low &&
                                                        dataComps.property < item.high
                                                    ) {
                                                        acc = i
                                                    }
                                                    if (
                                                        i + 1 == dataSub.distribution.length &&
                                                        dataComps.property == item.high
                                                    ) {
                                                        acc = i
                                                    }
                                                    return acc
                                                }, 0),
                                            [dataSub],
                                        )}
                                    />
                                </div>

                                <div className="row">
                                    <InsightsTable tableHeader={tableDataSub.header} tableBody={tableDataSub.body} />
                                </div>
                            </ReportSection>
                        </BasePageLayoutOM>
                    </div>
                    <div className={'reportPage page a4-150dpiSpecialOM'}>
                        <BasePageLayoutOM title={'SubMarket Insights'} subtitle={props.headerSubtitle}>
                            <ReportSection
                                title={null}
                                className={'Comparison'}
                                titleSmall={true}
                                style={{ position: 'relative', left: '-20px' }}
                            >
                                <h3 className=" mb-3">Asking Rent Distribution</h3>
                                <CalculatedInfo />
                                <h5 className=" mb-3">Property vs Comps</h5>

                                <div className="graphrow">
                                    <ApexPropertyVsCompsChart
                                        values={chartDataComps.values}
                                        labels={chartDataComps.labels}
                                        colors={chartDataComps.colors}
                                        median={chartDataComps.median}
                                        height={380}
                                    />
                                </div>
                                <div className="row">
                                    <InsightsTable
                                        tableHeader={tableDataComps.header}
                                        tableBody={tableDataComps.body}
                                    />
                                </div>
                            </ReportSection>
                        </BasePageLayoutOM>
                    </div>
                </>
            )}

            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';

                .graphrow {
                    width: 100%;
                }
            `}</style>
        </>
    )
}
