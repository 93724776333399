import React, { useMemo } from 'react'
import { LenderCashFlow } from '../../../dashboard/tabs/Cashflow/cashFlowTabs/LenderCashFlow/LenderCashFlow'
import BasePageLayoutOM from '../BasePageLayoutOM'
import ReportSection from '../ReportSection'
import { OriginationModelReworked } from '@generated/graphql'
import { ReduxHelper } from '../../../../../store'
import { myStorage } from '../../../../../store/storage'
import { CashFlowEdit } from '../../../dashboard/tabs/Cashflow/CashFlowEdit'
import { recalcProforma } from 'origination-model'
const cloneDeep = require('lodash.clonedeep')
const DEBUG_NOT_SHOW_PAGE = false
export const T12Financials = (props: any) => {
    const model = props.cfModel as OriginationModelReworked

    const loadExtraData = function () {
        const cashFlow = props.cashFlow
        const originationModel = props.originationModel
        if (cashFlow?.noi?.aprDetails?.length) {
            ReduxHelper.setIn(['operatingAssumptions', 'historicalTab', 'income', 'chartPeriodSelected'], {
                value: `t${cashFlow.noi.aprDetails.length.toString()}`,
                type: 'month',
            })
        }
        Object.keys(cashFlow).forEach((item1, ind) => {
            if (cashFlow[item1]?.comment) {
                ReduxHelper.setIn(['lenderDashboard', 'lenderCashFlowTab', 'showComments'], false)
            } else if (!cashFlow[item1]?.comment) {
                !cashFlow[item1]?.hasOwnProperty('id') &&
                    cashFlow[item1] &&
                    Object.keys(cashFlow[item1]).forEach((item2, ind) => {
                        if (cashFlow[item1][item2].comment) {
                            ReduxHelper.setIn(['lenderDashboard', 'lenderCashFlowTab', 'showComments'], false)
                        } else if (cashFlow[item1][item2].subRows) {
                            Object.keys(cashFlow[item1][item2].subRows).forEach((subRow, ind) => {
                                if (cashFlow[item1][item2]['subRows'][subRow].comment) {
                                    ReduxHelper.setIn(['lenderDashboard', 'lenderCashFlowTab', 'showComments'], false)
                                }
                            })
                        }
                    })
            }
        })

        const reduxOut = []
        cashFlow.operatingExpenses.map((itm, ind) => {
            reduxOut.push(itm.title.replace('Total ', ''))
        })
        // ReduxHelper.setIn(['financials', 'historicalTab', 'expenses', 'expensesTrends'], reduxOut)

        originationModel.cashFlow = cashFlow

        ReduxHelper.setIn(['lender', 'originationModel'], originationModel || null)
        ReduxHelper.setIn(['lender', 'originationModelDefault'], cloneDeep(originationModel) || null)
        ReduxHelper.setIn(['lender', 'unitMix'], cloneDeep(props.unitMix) || null)
        // hide T12 in lender cashflow if orinalApr of totals is zero, it means that no t12 was uploaded
        if (cashFlow?.noi?.originalApr > 0 || cashFlow?.totalOperatingExpenses?.originalApr > 0) {
        }
        ReduxHelper.updateIn(['lenderDashboard', 'lenderCashFlowTab', 'columnsSelected'], (tabs: string[]) => {
            return ['t12']
        })
        if (originationModel?.sources?.mezzanine > 0) {
            myStorage.setItem('hasMez_' + props.projectId, { val: true })
        } else {
            myStorage.setItem('hasMez_' + props.projectId, { val: false })
        }

        // ReduxHelper.setIn(['lender', 'proforma'], props.cashFlow)
        ReduxHelper.setIn(['lender', 'proforma'], props.borrowerProforma)

        ReduxHelper.setIn(['lender', 't12History'], props.t12History)
    }
    const projectInfo = props.projectInfo

    if (!projectInfo?.files) {
        projectInfo.files = []
    }
    ReduxHelper.setIn(['lenderProjects', 'project'], projectInfo || null)

    if (model) loadExtraData()

    const modelForProforma = useMemo(() => {
        const modelForProforma = cloneDeep(model)
        /*
        delete modelForProforma.t12AdjustmentsIncome
        delete modelForProforma.t12Adjustments
        delete modelForProforma.t12AdjustmentsIncomeRetail
        delete modelForProforma.t12AdjustmentsIncome
        delete modelForProforma.underwritingAssumption
        delete modelForProforma.adjustmentsSettings
        delete modelForProforma.cashFlowFieldsToIgnore
        */
        return modelForProforma
    }, [model])

    //console.log('props?.cfModel', props)
    return (
        <>
            {(false || !DEBUG_NOT_SHOW_PAGE) && props?.borrowerProforma?.[0] && (
                <div className={'reportPage page a4-150dpiSpecialOM'}>
                    <BasePageLayoutOM title={'Financials'} subtitle={props.headerSubtitle}>
                        <ReportSection
                            title={'Borrower Proforma'}
                            className={'BorrowerProforma'}
                            titleSmall={true}
                            style={{ width: '115%', marginLeft: '-80px' }}
                        >
                            <CashFlowEdit
                                data={recalcProforma({ proforma: props.borrowerProforma[0] })}
                                save={() => null}
                                mode={'view'}
                                model={modelForProforma}
                                isPDF={true}
                                showOther={false}
                                showKPIs={false}
                                isProforma={true}
                            />
                        </ReportSection>
                    </BasePageLayoutOM>
                </div>
            )}
            {(false || !DEBUG_NOT_SHOW_PAGE) && props?.cfT12?.rentalIncome?.[0]?.t12 && (
                <div className={'reportPage page a4-150dpiSpecialOM'}>
                    <BasePageLayoutOM title={'Financials'} subtitle={props.headerSubtitle}>
                        <ReportSection
                            title={'T12'}
                            className={'T12Financials'}
                            titleSmall={true}
                            style={{ width: '85%' }}
                        >
                            <LenderCashFlow isPDF={true} showComments={false} />
                        </ReportSection>
                    </BasePageLayoutOM>
                </div>
            )}
        </>
    )
}
