import BasePageLayoutOM from '../../BasePageLayoutOM'
import ReportSection from '../../ReportSection'

import React from 'react'
import { Housing } from '../../../../expose/housing/Housing'

export const HousingWrapper = (props: any) => {
    return (
        <>
            {props?.contentHousing?.content && props?.contentHousing?.content != '' && (
                <div className={'reportPage page a4-150dpiSpecialOM'}>
                    <BasePageLayoutOM title={'Market Overview'} subtitle={props.headerSubtitle}>
                        <div className={'expose-wrapper white-theme'}>
                            <main>
                                <div className={'cblock'} style={{ marginTop: '-80px' }}>
                                    <ReportSection
                                        title={'Housing Information'}
                                        className={'HousingInformation'}
                                        titleSmall={true}
                                    >
                                        <Housing
                                            content={props.contentHousing}
                                            location={props.location}
                                            headerSubtitle={props.headerSubtitle}
                                            noTtitle={true}
                                            noShowCharts={true}
                                        />
                                    </ReportSection>
                                </div>
                            </main>
                        </div>
                    </BasePageLayoutOM>
                </div>
            )}
        </>
    )
}
