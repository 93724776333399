import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import numeral from 'numeral'
import { ReduxStoreState } from '../../../../../../store'
import { Spinner } from '../../../../../controls/Spinner'
import { ApexUnitMixChart } from '../../../../operating-assumptions/submarket-insights/multi-family-performance/ApexUnitMixChart'
import { InsightsTable } from '../../../../operating-assumptions/submarket-insights/InsightsTable'
import BasePageLayoutOM from '../../BasePageLayoutOM'
import ReportSection from '../../ReportSection'

export const SubMarketUnitMix = (props: any) => {
    const unitMixDetails = useSelector(
        (state: ReduxStoreState) => state.operatingAssumptions?.submarketInsights?.unitMixDetails,
    )
    const submarketName = useSelector(
        (state: ReduxStoreState) => state.operatingAssumptions.submarketInsights.submarketName,
    )
    const project = useSelector((store: ReduxStoreState) => store.lenderProjects.project)

    const submarketNameSplitted = submarketName.split(', ')
    const subName = submarketNameSplitted.length === 1 ? null : submarketNameSplitted[0]
    const metroName = submarketNameSplitted.length === 1 ? submarketNameSplitted[0] : submarketNameSplitted[1]

    const tableData = useMemo(() => {
        return {
            header: [
                {
                    cells: [
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: 'AVG.RENT',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: 'AVG.SF',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: 'AVG. RENT PSF',
                            bordering: false,
                            align: 'right',
                        },
                    ],
                },
            ],
            body: []
                .concat(
                    [1, 2, 3].map((item, idx) => {
                        const data =
                            idx === 0
                                ? unitMixDetails.subject?.studio
                                : idx === 1
                                ? unitMixDetails.market?.studio
                                : unitMixDetails.rentComps?.studio

                        return {
                            title: '',
                            cells: [
                                {
                                    content: idx === 0 ? 'Studio / Efficiency' : '',
                                    bordering: false,
                                    align: 'left',
                                    bold: true,
                                },
                                {
                                    content:
                                        idx === 0
                                            ? 'Subject'
                                            : idx === 1
                                            ? `${!!subName ? 'Submarket' : 'Metro'}`
                                            : 'Rent Comps',
                                    bordering: false,
                                    align: 'left',
                                },
                                {
                                    content:
                                        data?.averageRent === 0 || !!data?.averageRent
                                            ? numeral(data.averageRent).format('$0,0')
                                            : 'n/a',
                                    bordering: false,
                                    align: 'right',
                                },
                                {
                                    content:
                                        data?.averageSize === 0 || !!data?.averageSize
                                            ? numeral(data.averageSize).format('0,0')
                                            : 'n/a',
                                    bordering: false,
                                    align: 'right',
                                },
                                {
                                    content:
                                        data?.averageRentPerSf === 0 || !!data?.averageRentPerSf
                                            ? numeral(data.averageRentPerSf).format('$0.00')
                                            : 'n/a',
                                    bordering: false,
                                    align: 'right',
                                },
                            ],
                        }
                    }),
                )
                .concat(
                    [1, 2, 3].map((item, idx) => {
                        const data =
                            idx === 0
                                ? unitMixDetails.subject?.oneBedroom
                                : idx === 1
                                ? unitMixDetails.market?.oneBedroom
                                : unitMixDetails.rentComps?.oneBedroom

                        return {
                            title: '',
                            cells: [
                                {
                                    content: idx === 0 ? 'One Bedroom' : '',
                                    bordering: false,
                                    align: 'left',
                                    bold: true,
                                },
                                {
                                    content:
                                        idx === 0
                                            ? 'Subject'
                                            : idx === 1
                                            ? `${!!subName ? 'Submarket' : 'Metro'}`
                                            : 'Rent Comps',
                                    bordering: false,
                                    align: 'left',
                                },
                                {
                                    content:
                                        data?.averageRent === 0 || !!data?.averageRent
                                            ? numeral(data.averageRent).format('$0,0')
                                            : 'n/a',
                                    bordering: false,
                                    align: 'right',
                                },
                                {
                                    content:
                                        data?.averageSize === 0 || !!data?.averageSize
                                            ? numeral(data.averageSize).format('0,0')
                                            : 'n/a',
                                    bordering: false,
                                    align: 'right',
                                },
                                {
                                    content:
                                        data?.averageRentPerSf === 0 || !!data?.averageRentPerSf
                                            ? numeral(data.averageRentPerSf).format('$0.00')
                                            : 'n/a',
                                    bordering: false,
                                    align: 'right',
                                },
                            ],
                        }
                    }),
                )
                .concat(
                    [1, 2, 3].map((item, idx) => {
                        const data =
                            idx === 0
                                ? unitMixDetails.subject?.twoBedroom
                                : idx === 1
                                ? unitMixDetails.market?.twoBedroom
                                : unitMixDetails.rentComps?.twoBedroom

                        return {
                            title: '',
                            cells: [
                                {
                                    content: idx === 0 ? 'Two Bedroom' : '',
                                    bordering: false,
                                    align: 'left',
                                    bold: true,
                                },
                                {
                                    content:
                                        idx === 0
                                            ? 'Subject'
                                            : idx === 1
                                            ? `${!!subName ? 'Submarket' : 'Metro'}`
                                            : 'Rent Comps',
                                    bordering: false,
                                    align: 'left',
                                },
                                {
                                    content:
                                        data?.averageRent === 0 || !!data?.averageRent
                                            ? numeral(data.averageRent).format('$0,0')
                                            : 'n/a',
                                    bordering: false,
                                    align: 'right',
                                },
                                {
                                    content:
                                        data?.averageSize === 0 || !!data?.averageSize
                                            ? numeral(data.averageSize).format('0,0')
                                            : 'n/a',
                                    bordering: false,
                                    align: 'right',
                                },
                                {
                                    content:
                                        data?.averageRentPerSf === 0 || !!data?.averageRentPerSf
                                            ? numeral(data.averageRentPerSf).format('$0.00')
                                            : 'n/a',
                                    bordering: false,
                                    align: 'right',
                                },
                            ],
                        }
                    }),
                )
                .concat(
                    [1, 2, 3].map((item, idx) => {
                        const data =
                            idx === 0
                                ? unitMixDetails.subject?.threeBedroom
                                : idx === 1
                                ? unitMixDetails.market?.threeBedroom
                                : unitMixDetails.rentComps?.threeBedroom

                        return {
                            title: '',
                            cells: [
                                {
                                    content: idx === 0 ? 'Three Bedroom' : '',
                                    bordering: false,
                                    align: 'left',
                                    bold: true,
                                },
                                {
                                    content:
                                        idx === 0
                                            ? 'Subject'
                                            : idx === 1
                                            ? `${!!subName ? 'Submarket' : 'Metro'}`
                                            : 'Rent Comps',
                                    bordering: false,
                                    align: 'left',
                                },
                                {
                                    content:
                                        data?.averageRent === 0 || !!data?.averageRent
                                            ? numeral(data.averageRent).format('$0,0')
                                            : 'n/a',
                                    bordering: false,
                                    align: 'right',
                                },
                                {
                                    content:
                                        data?.averageSize === 0 || !!data?.averageSize
                                            ? numeral(data.averageSize).format('0,0')
                                            : 'n/a',
                                    bordering: false,
                                    align: 'right',
                                },
                                {
                                    content:
                                        data?.averageRentPerSf === 0 || !!data?.averageRentPerSf
                                            ? numeral(data.averageRentPerSf).format('$0.00')
                                            : 'n/a',
                                    bordering: false,
                                    align: 'right',
                                },
                            ],
                        }
                    }),
                ),
        }
    }, [unitMixDetails])

    const chartData = useMemo(() => {
        return {
            labels: [] as string[],
            values: [] as number[],
            chartSeries: [
                {
                    name: 'Studio',
                    type: 'column',
                    data: [
                        unitMixDetails.subject?.studio.averageRent,
                        unitMixDetails.market?.studio.averageRent,
                        unitMixDetails.rentComps?.studio.averageRent,
                    ],
                },
                {
                    name: 'One Bedroom',
                    type: 'column',
                    data: [
                        unitMixDetails.subject?.oneBedroom.averageRent,
                        unitMixDetails.market?.oneBedroom.averageRent,
                        unitMixDetails.rentComps?.oneBedroom.averageRent,
                    ],
                },
                {
                    name: 'Two Bedroom',
                    type: 'column',
                    data: [
                        unitMixDetails.subject?.twoBedroom.averageRent,
                        unitMixDetails.market?.twoBedroom.averageRent,
                        unitMixDetails.rentComps?.twoBedroom.averageRent,
                    ],
                },
                {
                    name: 'Three Bedroom',
                    type: 'column',
                    data: [
                        unitMixDetails.subject?.threeBedroom.averageRent,
                        unitMixDetails.market?.threeBedroom.averageRent,
                        unitMixDetails.rentComps?.threeBedroom.averageRent,
                    ],
                },
            ],
            categories: ['Subject', `${!!subName ? 'Submarket' : 'Metro'}`, 'Rent Comps'],
            colors: ['#5791FF', '#DC82CF', '#71CAC4', '#E1A478'],
        }
    }, [unitMixDetails])

    return !!unitMixDetails ? (
        <>
            <div className={'reportPage page a4-150dpiSpecialOM'}>
                <BasePageLayoutOM title={'SubMarket Insights'} subtitle={props.headerSubtitle}>
                    <ReportSection
                        title={'MultiFamily Performance'}
                        className={'MultiFamilyPerformance'}
                        titleSmall={true}
                    >
                        <h3 className=" mb-3">Unit Mix</h3>
                        <div style={{ position: 'relative', left: '-20px' }}>
                            <ApexUnitMixChart
                                series={chartData.chartSeries}
                                categories={chartData.categories}
                                colors={chartData.colors}
                                height={280}
                            />
                        </div>
                        <div className="names-wrapper">
                            {!!subName && (
                                <div>
                                    <span>Submarket:</span> {subName}
                                </div>
                            )}
                            {!!metroName && (
                                <div>
                                    <span>Metro:</span> {metroName}
                                </div>
                            )}
                            {!!project?.address && (
                                <div>
                                    <span>Subject:</span> {project.address}
                                </div>
                            )}
                        </div>
                        <div className="table-wrapper">
                            <InsightsTable tableHeader={tableData.header} tableBody={tableData.body} />
                        </div>
                    </ReportSection>
                </BasePageLayoutOM>
            </div>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    .tab-wrapper {
                        width: 100%;
                    }
                    .names-wrapper {
                        display: flex;
                        gap: 20px;
                        font-size: 1.1rem;
                        margin-bottom: 10px;

                        span {
                            font-weight: 600;
                        }
                    }
                `}
            </style>
        </>
    ) : (
        <Spinner />
    )
}
