import React from 'react'

export function DealHighlightsDetails(props: { dealHighlights: any }) {
    let investmentDetails = []
    if (props.dealHighlights?.details) {
        try {
            const details = JSON.parse(props.dealHighlights.details)
            investmentDetails = details.details
        } catch (e) {
            console.log(e.message)
        }
    }

    return (
        <section className="cblock markPdfPageBreak" id="detailssec">
            <div className="container" id="details">
                {investmentDetails.map((investmentDetail, index) => {
                    return (
                        <div className="row mb-4" key={index}>
                            <div className="col-lg-6 animatedin in stop">
                                <p
                                    className="cblock__highlight"
                                    style={{
                                        fontWeight: 700,
                                    }}
                                >
                                    {investmentDetail.title}
                                </p>
                            </div>
                            <div className="col-lg-6 animatedin in stop">
                                <p className="text-justify">{investmentDetail.description}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </section>
    )
}
