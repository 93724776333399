import React from 'react'

export function DealHighlightsTables(props: { dealHighlights: any }) {
    const tables = {
        tables_short: [],
        tables_long: [],
    }
    if (props.dealHighlights?.details) {
        try {
            const details = JSON.parse(props.dealHighlights.details)
            tables.tables_short = details?.tables_short || []
            tables.tables_long = details?.tables_long || []
        } catch (e) {
            console.log(e.message)
        }
    }

    if (
        (!tables?.tables_short || tables?.tables_short?.length == 0) &&
        (!tables?.tables_long || tables?.tables_long?.length == 0)
    ) {
        return null
    }
    const markPdfPageBreak = tables?.tables_short?.length > 0 ? ' markPdfPageBreak' : ''

    return (
        <section className={'cblock' + markPdfPageBreak} id="tablessec">
            <div className="container" id="tables">
                {/*short table*/}
                <div className="row highlightsrow">
                    {tables.tables_short?.map((table, index) => {
                        if (!table.head || !table.data) return null
                        return (
                            <table
                                key={index}
                                className="table table-borderless table-striped table--smallmobile animatedin highlightstable highlightstableEven in stop"
                            >
                                <thead>
                                    <tr className="row-highlight">
                                        {table.head.map((head, index) => {
                                            return <th key={index}>{head}</th>
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {table.data.map((data, index) => {
                                        return (
                                            <tr key={index}>
                                                {data.map((cell, cellIndex) => {
                                                    if (cellIndex !== 0) {
                                                        return (
                                                            <td
                                                                className={data?.[0] == 1 ? 'font-weight-bold' : ''}
                                                                key={cellIndex}
                                                            >
                                                                {cell}
                                                            </td>
                                                        )
                                                    }
                                                    return null
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        )
                    })}
                </div>

                {/*long table*/}
                <div className="row	">
                    {tables.tables_long?.map((table, index) => {
                        if (!table.head || !table.data) return null
                        return (
                            <table
                                className="table table-borderless table-striped table--smallmobile animatedin highlightstable in stop"
                                key={index}
                            >
                                <thead>
                                    <tr className="row-highlight">
                                        {table.head.map((head, index) => {
                                            return <th key={index}>{head}</th>
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {table.data.map((data, index) => {
                                        return (
                                            <tr key={index}>
                                                {data.map((cell, cellIndex) => {
                                                    if (cellIndex !== 0) {
                                                        return <td key={cellIndex}>{cell}</td>
                                                    }
                                                    return null
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        )
                    })}
                </div>
                <div className="cblock__sect" />
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                tbody {
                    color: white;
                }
                .highlightsrow {
                    display: flex;
                    flex-wrap: wrap !important;
                    justify-content: space-between !important;
                    &:after {
                        display: none !important;
                    }
                    .table {
                        max-width: 45%;
                        margin-right: 0;
                    }
                }
            `}</style>
        </section>
    )
}
