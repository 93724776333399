import React, { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import ErrorModal from '../../components/pages/dealnarrative/modals/ErrorModal'

import ReportButtonBar from '../../components/pages/dealnarrative/navigation/ReportButtonBar'
import { NextPageContext } from 'next'
import { gql, useQuery } from '@apollo/client'
import { FullNarrativeFragment } from '../../store/fragments/dealnarrative/fragments'
import { graphQLRequest } from '../../store/graphQLClient'
import { omServerSideWrapper } from '../../utils/server-side/omServerSideWrapper'
import Head from 'next/head'
import { GoogleAnalytics } from '../../components/pages/expose/GoogleAnalytics'
import { store } from '../../store'
import { Provider } from 'react-redux'
import { getEnv } from '../../utils/server-side/getEnv'
import HtmlReportOM from '../../components/pages/dealnarrative/report/HtmlReportOM'
import { getAxios } from '../../store/axiosClient'
import { API } from '../../utils/constants/api'
import { getMinisiteCommonInfo, getProjectId } from '../../utils/server-side/minisite'
import { getUserInfo } from '../../utils/server-side/getUserInfo'
import { miniSiteGlobalStyles } from '../../components/pages/dealnarrative/report/om/theme/miniSiteGlobalStyles'
import { ThemeContextProvider } from '../../components/layouts/ThemeContext'
import { orgenizeCFForPresentation, regenerateCashFlow } from 'origination-model'
import { FormContext } from '../../components/pages/dashboard/Dashboard'
const cloneDeep = require('lodash.clonedeep')

export default function ReportOMViewerPage(props: any) {
    //  console.log('ReportOMViewerPage props', props)
    const [isPageLoading, setIsPageLoading] = useState(true)
    const [error, setError] = useState(undefined)

    const router = useRouter()

    // Fetch narrative if a narrativeId is provided in the URL.
    // Performed only after rehydration on the client because router.query is not available until then.
    // Performed only on first page load; subsequent calls look into the Apollo cache (the entity returned must have a prop named "id")
    const { data: narrativeData, loading: isNarrativeLoading, error: narrativeError } = props

    const narrative = narrativeData?.narrativeById

    // Page is loading until router is ready and we are able to get the narrativeId
    // In that case, page is loading until fetching the narrative/project is done.
    useEffect(() => {
        if (router.isReady && !!router.query.narrativeId) setIsPageLoading(isNarrativeLoading)
    }, [router.isReady, isNarrativeLoading, router.query.narrativeId])

    // Set error, if any (we must do that in an effect because we depend on router.isReady).
    useEffect(() => {
        if (router.isReady && !router.query.narrativeId) {
            // URL query must specify narrativeId (fetch narrative)
            setError('You must provide a narrative ID')
        } else if (router.isReady && router.query.narrativeId) {
            // A fetch with narrativeId was attempted
            if (narrativeError)
                // Fetch ended with errors
                setError('Error in response while fetching the narrative')
            else if (!isNarrativeLoading && !narrative)
                // Fetch ended without error but returned nothing
                setError('Cannot find a narrative with this ID')
            else setError(undefined)
        } else {
            // New narrative exists, so no error until we send the mutation
            setError(undefined)
        }
    }, [router.isReady, router.query.narrativeId, narrativeError, isNarrativeLoading, narrative])

    const onClose = useCallback(() => {
        // window.close()
        window.location.href = `/v1/`
    }, [])

    const onDownloadPdf = useCallback(() => {
        window.print()
        /*
        const url = `/export-narrative-pdf/${router.query.id}?id=${router.query.narrativeId}`
        console.log('Download PDF', url)
        window.open(url, '_blank')

         */
    }, [])

    const onShare = useCallback(() => {
        console.log('Share (to be implemented)')
    }, [])

    let content: React.ReactNode

    if (isPageLoading) {
        // Do not display the form until the page has loaded and narrative has been fetched
        content = <div>Loading...</div>
    } else if (error || !narrative) {
        // Page is done loading, but errors occurred
        content = <ErrorModal show={!!error} message={error} />
    } else {
        content = <HtmlReportOM narrative={narrative} {...props} />
    }

    useEffect(() => {
        try {
            if (typeof window != 'undefined') {
                // @ts-ignore
                window.isPDFFullyLoaded = () => {
                    // @ts-ignore
                    if (window.isSubMarketLoaded) return true
                    return false
                }
            }
        } catch (e) {}
    }, [])

    return (
        <FormContext.Provider
            value={{
                dealModified: false,
                dealWasSaved: false,
                formModified: false,
                setFormModified: () => {},
                formModifiedViaMutators: false,
                setFormModifiedViaMutators: () => {},
                formVisited: false,
                setFormVisited: () => {},
            }}
        >
            <Provider store={store}>
                <Head>
                    <link
                        href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined"
                        rel="stylesheet"
                    />
                </Head>
                <ThemeContextProvider value={{ theme: 'white' }}>
                    <style jsx global>
                        {miniSiteGlobalStyles}
                    </style>
                    <div className="ReportViewerPage">
                        <div className="mainContainer">
                            <div className={'centerCol'}>{content}</div>
                        </div>
                        <div className="buttonBarContainer d-print-none">
                            <ReportButtonBar onClose={onClose} onDownloadPdf={onDownloadPdf} onShare={onShare} />
                        </div>
                    </div>
                    <script src={'/_js/jquery-3.3.1.min.js'} />
                    <script src={'/_js/bootstrap.bundle.js'} />
                </ThemeContextProvider>
                <GoogleAnalytics
                    apiKey={props.env.GOOGLE_ANALYTICS_KEY}
                    narrativeId={+router?.query?.narrativeId}
                    projectId={+router?.query?.id}
                />
            </Provider>
        </FormContext.Provider>
    )
}

export async function getServerSideProps(context: NextPageContext) {
    //console.log('dealnarrative report getServerSideProps')
    try {
        return await omServerSideWrapper(context, async (context) => {
            const id = +context.query.narrativeId
            const projectId = +context.query.id
            const ids = [projectId]

            // console.log('dealnarrative report omServerSideWrapper', id)
            const res = await graphQLRequest(
                gql`
                    ${FullNarrativeFragment}
                    query narrativeById($projectId: Int!, $id: ID!) {
                        narrativeById(projectId: $projectId, id: $id) {
                            ...FullNarrativeFragment
                        }
                    }
                `,
                {
                    projectId,
                    id,
                },
                { 'server-user': 'TRUE' },
            )
            const user = await getUserInfo(context)
            const commonInfo = await getMinisiteCommonInfo(context)
            const projectid = await getProjectId(context)
            const responseDealHighlights = await getAxios().get(API.FULL_PROJECT.DEAL_HIGHLIGHTS_PART_I, {
                params: {
                    projectid: projectid,
                },
            })
            const responseDealOutline = await getAxios().get(API.FULL_PROJECT.DEAL_OUTLINE, {
                params: {
                    projectid,
                    extend: 1,
                },
            })

            const responseProperty = await getAxios().get(API.FULL_PROJECT.THE_PROPERTY, {
                params: {
                    projectid: projectId,
                },
            })
            const responseProforma = await getAxios().get(API.FULL_PROJECT.PRO_FORMA_USER_INPUT, {
                params: {
                    projectid: projectId,
                },
            })
            const responsePropertyEnrichments = await getAxios().get(API.FULL_PROJECT.PROPERTY_ENRICHMENTS, {
                params: {
                    projectid: projectId,
                },
            })
            const responseComparables = await getAxios().get(API.FULL_PROJECT.COMPARABLES, {
                params: {
                    projectid: projectId,
                    type: 'full',
                },
            })

            const responsRetail = await graphQLRequest(
                gql`
                    query ($projectId: String!) {
                        retailStats(projectId: $projectId) {
                            hasRetail
                            numberOfRetailUnits
                            totalRetailSF
                        }
                    }
                `,
                { projectId: '' + projectId },
            )
            const responseOfInt = await graphQLRequest(
                gql`
                    query ($projectId: Int!, $pageType: String!) {
                        taxRecords(projectId: $projectId) {
                            taxDataVisible
                            taxRecords {
                                id
                                year
                                taxAmount
                                assessedImprovementValue
                                assessedLandValue
                                appliedTaxRate
                                YoYchange
                                totalAssessedValue
                                improvementMarketValue
                                landMarketValue
                                totalMarketValue
                                parcelId
                                address
                            }
                        }
                        getConstructionData(projectId: $projectId) {
                            enabled
                            projectedOccupancy
                            location {
                                latitude
                                longitude
                            }
                            planned {
                                name
                                address
                                location {
                                    latitude
                                    longitude
                                }
                                noUnits
                                subMarket
                                imprRating
                                locRating
                                distance
                                status
                                details
                            }
                            underConstruction {
                                name
                                address
                                location {
                                    latitude
                                    longitude
                                }
                                noUnits
                                subMarket
                                imprRating
                                locRating
                                distance
                                status
                                details
                            }
                            supplyAndDemand {
                                year
                                occupancy
                                supply
                                demand
                            }
                        }

                        rentComparable(projectId: $projectId) {
                            enabled
                            effectiveRent
                            effectiveRentPerSqFt
                            marketRent
                            marketRentPerSqFt
                            marketTrend
                            propertyTrend
                            floorPlanComparables {
                                title
                                properties {
                                    title
                                    type
                                    units
                                    sf
                                    effectiveRent
                                }
                            }
                            propertyComparables {
                                id
                                title
                                address
                                occupancy
                                yearBuilt
                                yearRenovated
                                image
                                avgUnitRent
                                totalUnits
                                buildingClass
                                units {
                                    type
                                    unitsCount
                                    sf
                                    marketAverageRent
                                    effectiveAverageRent
                                }
                                location {
                                    lat
                                    lng
                                }
                            }
                        }
                        projectLocation(projectId: $projectId) {
                            lat
                            lng
                        }

                        getUnitMixList(projectId: $projectId) {
                            id
                            projectid
                            numofunits
                            floorPlan
                            bedrooms
                            rentprice
                            baths
                            unitsize
                            status
                            unitsToRenovate
                            stabilizedRentPrice
                            overhang
                        }
                        getMarketAreaInformation(projectId: $projectId) {
                            marketAreas {
                                radius
                                properties
                                totalUnits
                                totalUnitsSQFt
                                householdChildrenVisible
                                occupancyVisible
                                familyHouseHoldsVisible
                                houseHoldStructureVisible
                                incomeVisible
                                householdOwnerShipVisible
                                yearBuiltVisible
                                householdOwnerShip {
                                    key
                                    value
                                }
                                householdChildren {
                                    key
                                    value
                                }
                                occupancy {
                                    key
                                    value
                                }
                                familyHouseHolds {
                                    key
                                    value
                                }
                                houseHoldStructure {
                                    key
                                    value
                                }
                                income {
                                    key
                                    value
                                }
                                yearBuilt {
                                    key
                                    value
                                }
                            }
                            enabled
                        }

                        getMarketHouseContent(projectId: $projectId, pageType: $pageType) {
                            title
                            content
                            data {
                                key
                                value
                                items {
                                    key
                                    type
                                    value
                                    items {
                                        key
                                        value
                                        type
                                        items {
                                            key
                                            value
                                            type
                                        }
                                    }
                                }
                                type
                            }
                        }

                        getMinisiteAccountInfo(projectId: $projectId) {
                            id
                            name
                            logo
                            email
                            phone
                            url
                            contact
                            isBlocked
                            accountType
                        }

                        proforma(projectId: $projectId) {
                            totalOperatingExpenses {
                                id
                                title
                                years
                            }
                            effectiveGrossRent {
                                id
                                title
                                years
                            }
                            effectiveGrossIncome {
                                id
                                title
                                years
                            }
                            totalPotentialGrossRent {
                                id
                                title
                                years
                            }
                            noi {
                                id
                                title
                                years
                            }
                            cashFlowAfterDebtService {
                                id
                                title
                                years
                            }
                            rentalIncome {
                                id
                                title
                                years
                            }
                            rentalLoss {
                                id
                                title
                                years
                            }
                            operatingExpenses {
                                id
                                title
                                years
                                subRows {
                                    id
                                    title
                                    years
                                }
                            }
                            otherIncome {
                                id
                                title
                                years
                            }
                            other {
                                id
                                title
                                years
                            }
                        }

                        project(projectId: $projectId) {
                            id
                            name
                            type
                            address
                            fileName
                            filePath
                            status
                            hashId
                            accountType
                            notesUrl
                            hasUnitMix
                            files {
                                id
                                parentId
                                projectId
                                isParsed
                                fileName
                                filePath
                                type
                                year
                                isHistorical
                                fieldMapping {
                                    sheets {
                                        name
                                        index
                                        rowCount
                                        columnCount
                                    }
                                    warnings
                                    fields {
                                        row
                                        column
                                        columnName
                                        title
                                        id
                                        previews
                                        extractBedroomsBathrooms
                                        rentPriceHasTotal
                                        rentPriceIsTenantPaidRent
                                        extractRenovationStatus
                                        dataIsGrouped
                                        isTotal
                                        isSubrow
                                        useLeaseStartAsMoveIn
                                        useLeaseExpireAsMoveOut
                                        useMoveInAsLeaseStart
                                        useMoveOutAsLeaseExpire
                                    }
                                    columns {
                                        column
                                        id
                                        title
                                    }
                                    fillRequiredWithZeros
                                    range {
                                        sheet
                                        top
                                        left
                                        right
                                        bottom
                                        titleIsDouble
                                    }
                                }
                            }
                            webReviews {
                                url
                                name
                                vote
                                score
                            }
                            isDataScraped
                        }

                        getProjectGeneralInputs(projectId: $projectId) {
                            propertyAndBorrower {
                                propertyName
                                numberOfUnits
                                yearBuilt
                                yearRenovated
                                address
                                city
                                zip
                                state
                                county
                                msa
                                censusTract
                                propertyType
                                buildingType
                                MAH
                                borrowerName
                                principals {
                                    name
                                    guarantor
                                }
                            }
                            loanDetails {
                                targetLoanClosing
                                requestedLoanAmount
                                requestedLoanAmortization
                                requestedLoanTerm
                                requestedLoanIO
                                requestedLoanPrePaymentLock
                                requestedTierLevel
                                interestType
                                lienPosition
                                acquisitionOrRefinance
                                borrowerLoanPurpose
                                loanPurposeGateway
                                fannieMaeRefinanceType
                                ARMType
                                supplemental
                                assumption
                                transfer
                                _currentOldSeniorLoanAmount
                            }
                            dealDetails {
                                purchasePrice
                                currentUPB
                                cashout
                                constructionCost
                                recentConstruction
                                escrowedImprovements
                                prepay
                                budgetedCapEx
                                greenFinancingType
                                greenBuildingCertificationType
                                moderateRehabilitationGateway
                                rehabilitationWorkPerUnit
                                rehabilitationWorkEscrowAmount
                                condominiumRegimeType
                                propertyStabilizationLevel
                                expectedtoReachStable
                                landOwnershipRights
                            }
                            team {
                                teamName
                                producer
                                analyst
                                originatorOffice
                                productionManager
                                productionManagementAnalyst
                                underwriter
                                underwritingAnalyst
                                underwritingManager
                                chiefUnderwriter
                                loanNumberTitle
                                loanNumber
                            }
                        }
                    }
                `,
                { projectId: +projectId, pageType: 'market_house' },
                { 'server-user': 'TRUE' },
            )

            const originationModel = await graphQLRequest(
                gql`
                    query ($projectId: String!) {
                        originationModel(projectId: $projectId) {
                            numberOfUnits
                            totalUnitsFromDataProvider
                            totalSQFTFromDataProvider
                            yearBuilt
                            yearRenovated
                        }
                    }
                `,
                {
                    projectId: projectId.toString(),
                },
                { 'server-user': 'TRUE' },
            )

            const fullAddress = responseDealOutline?.data?.project?.address

            const responseMarket = await getAxios().get(API.FULL_PROJECT.MARKET_OVERVIEW, {
                params: {
                    projectid: projectId,
                    type: 'full',
                },
            })
            let markets = []
            for (const id of ids) {
                const responseMarket = await getAxios().get(API.FULL_PROJECT.MARKET_OVERVIEW, {
                    params: {
                        projectid: id,
                        type: 'full',
                    },
                })
                markets.push(responseMarket?.data?.project)
            }
            if (markets.length == 0 || markets[0] == undefined) {
                markets = [{}]
            }
            const responseEmployment = await getAxios().get(API.FULL_PROJECT.EMPLOYMENT, {
                params: {
                    projectid: projectId,
                },
            })

            let geocodeResponse = null

            let demographicResponse = null
            let address = fullAddress
            if (address) {
                address = address.replace(/[0-9]/g, '').toLowerCase()
                address = address.split(',')
                if (address[address.length - 2][0] == ' ')
                    address[address.length - 2] = address[address.length - 2].substr(1)
                const address1 =
                    address[address.length - 2].replace(/ /g, '-') + '-' + address[address.length - 1].replace(/ /g, '')

                geocodeResponse = await getAxios().get(API.PROXY, {
                    params: {
                        cmd: 'getgeocode',
                        address: address1,
                    },
                })

                demographicResponse = await graphQLRequest(
                    gql`
                        query ($projectId: Int!) {
                            demographicData(projectId: $projectId)
                        }
                    `,
                    {
                        projectId: projectId,
                    },
                )
            }
            let crimeInfo = {
                map: [],
                description: ' \n \n ',
                stats: {},
            }
            try {
                const crimeMapRequest = await graphQLRequest(
                    gql`
                        query ($projectId: Int!) {
                            crimeInfo(projectId: $projectId) {
                                map {
                                    name
                                    level
                                    path {
                                        lat
                                        lng
                                    }
                                }
                                description
                                stats {
                                    violentCity
                                    violentCounty
                                    propertyCity
                                    propertyCountry
                                }
                                mapCenter {
                                    lat
                                    lng
                                    address
                                }
                            }
                        }
                    `,
                    {
                        projectId: projectId,
                    },
                )
                crimeInfo = crimeMapRequest.data.crimeInfo
            } catch (e) {
                console.log(e.message)
            }

            let schoolsRequest = null
            try {
                schoolsRequest = await graphQLRequest(
                    gql`
                        query ($projectId: Int!) {
                            schools(projectId: $projectId) {
                                studentCount
                                schoolCount
                                mapCenter {
                                    lat
                                    lng
                                }
                                elementary {
                                    name
                                    location {
                                        lat
                                        lng
                                    }
                                    type
                                }
                                middle {
                                    name
                                    location {
                                        lat
                                        lng
                                    }
                                    type
                                }
                                high {
                                    name
                                    location {
                                        lat
                                        lng
                                    }
                                    type
                                }
                            }
                        }
                    `,
                    {
                        projectId: +projectId,
                    },
                )
            } catch (e) {
                console.log(e.message)
            }

            let employmentIndustryAndOccupation = null

            try {
                const res = await graphQLRequest(
                    gql`
                        query ($projectId: Int!) {
                            employmentStats(projectId: $projectId) {
                                industry {
                                    label
                                    value
                                }
                                occupation {
                                    label
                                    value
                                }
                                specialisations
                            }
                        }
                    `,
                    { projectId },
                )
                employmentIndustryAndOccupation = res.data?.employmentStats
            } catch (e) {
                console.log(`Error ${API.DATA_USA} `, e)
            }

            let responseModelAndCF = null

            try {
                responseModelAndCF = await graphQLRequest(
                    gql`
                        fragment CFRow on CashFlowRowGraphQL {
                            id
                            title
                            apr
                            originalApr
                            years
                            aprDetails
                            months
                            comment
                            t12
                            t12Total
                            adjustedT12
                            label
                            parent
                            originalCFGroup
                        }
                        fragment adjRow on T12AdjustedRowGraphQL {
                            id
                            title
                            label
                            value
                            comment
                            group
                            defaultValue
                        }
                        fragment costList on CostItemGraphQL {
                            type
                            abs
                            percentage
                            title
                            monthStart
                            monthEnd
                        }
                        fragment loanDrawList on LoanDrawItemGraphQL {
                            abs
                            title
                            monthStart
                        }
                        query ($projectId: String!) {
                            cashFlow(projectId: $projectId) {
                                totalOperatingExpenses {
                                    ...CFRow
                                }
                                effectiveGrossRent {
                                    ...CFRow
                                }
                                effectiveGrossIncome {
                                    ...CFRow
                                }
                                totalPotentialGrossRent {
                                    ...CFRow
                                }
                                noi {
                                    ...CFRow
                                }
                                cashFlowAfterDebtService {
                                    ...CFRow
                                }
                                rentalIncome {
                                    ...CFRow
                                    subRows {
                                        ...CFRow
                                    }
                                }
                                rentalLoss {
                                    ...CFRow
                                    subRows {
                                        ...CFRow
                                    }
                                }
                                operatingExpenses {
                                    ...CFRow
                                    subRows {
                                        ...CFRow
                                    }
                                }
                                fcfBeforeDebt {
                                    ...CFRow
                                    subRows {
                                        ...CFRow
                                    }
                                }
                                removedLineItems {
                                    ...CFRow
                                    subRows {
                                        ...CFRow
                                    }
                                }
                                otherIncome {
                                    ...CFRow
                                    subRows {
                                        ...CFRow
                                    }
                                }
                                retailRentalIncome {
                                    ...CFRow
                                }
                                retailRentalLoss {
                                    ...CFRow
                                }
                                retailOperatingExpenses {
                                    ...CFRow
                                    subRows {
                                        ...CFRow
                                    }
                                }
                                retailOtherIncome {
                                    ...CFRow
                                }
                                other {
                                    ...CFRow
                                }
                                totals {
                                    ...CFRow
                                }
                                t12FirstMonth
                                useLabelsAsTitle
                            }
                            originationModel(projectId: $projectId) {
                                t12Annualized
                                numberOfUnits
                                totalUnitsFromDataProvider
                                totalSQFTFromDataProvider
                                totalSF
                                hasRetail
                                numberOfRetailUnits
                                totalRetailSF
                                yearBuilt
                                yearRenovated
                                useUnitMixForGPR
                                useRetailUnitMixForGPR
                                useNCFnotNOI
                                allowFannieAPricing
                                marketCapRate
                                t12CollectionAnalysisComments
                                t12AnalysisComments
                                t12smoothStr
                                cashFlowFieldsToIgnore
                                t12AdsutmentDefaults {
                                    defaults {
                                        id
                                        subid
                                        value
                                        comment
                                    }
                                }
                                rentRollTab {
                                    comments
                                    fileDate
                                    rentRollSettings {
                                        allowFannieAffordability
                                        showComments
                                        threshold
                                        trendType
                                        floorPlanSelected
                                        isAffordabilityDeal
                                    }
                                }
                                sources {
                                    seniorDebt
                                    seniorDebtPercent
                                    seniorDebtType
                                    mezzanine
                                    mezzaninePercent
                                    mezzanineType
                                    totalEquity
                                    totalEquityPercent
                                    totalEquityType
                                }
                                uses {
                                    purchasePrice
                                    transactionCosts
                                    transactionCostsPercent
                                    transactionCostsType
                                    lenderFee
                                    lenderFeePercent
                                    lenderFeeType
                                    renovationProgram
                                    renovationProgramPercent
                                    renovationProgramType
                                    preFunded
                                    preFundedPercent
                                    preFundedType
                                    hardCost
                                    hardCostPercent
                                    hardCostType
                                    softCost
                                    softCostPercent
                                    softCostType
                                    financingCost
                                    financingCostPercent
                                    financingCostType
                                    other {
                                        title
                                        id
                                        percentage
                                        abs
                                        type
                                    }
                                }
                                exitAssumption {
                                    startAnalysisDate
                                    saleYearAnalysisPeriod
                                    saleDate
                                    saleCost
                                    exitNoi
                                    exitCapRate
                                    capRateSpread
                                }
                                returnMetrics {
                                    netProfit
                                    dealEquityMultiple
                                    cocYear1
                                    cocAvg
                                    roeYear1
                                    roeAvg
                                }

                                underwritingAssumption {
                                    organicRentGrowth
                                    otherIncomeGrowth
                                    mtm
                                    lossToLease
                                    vacancy
                                    badDebt
                                    concession
                                    replacementReserves
                                    expenseGrown
                                    assetManagementFee
                                    assetManagementFeeGP
                                }
                                underwritingAssumptionRetail {
                                    otherIncomeGrowth
                                    vacancy
                                    expenseGrown
                                    capitalReserves
                                }
                                debtAssumptionSenior {
                                    rate
                                    rateType
                                    loanAmount
                                    loanTerm
                                    amortizationTerm
                                    ioPeriod
                                    bc10year
                                }
                                debtAssumptionMez {
                                    rate
                                    rateType
                                    loanAmount
                                    loanTerm
                                    amortizationTerm
                                    ioPeriod
                                    bc10year
                                }
                                saleAssumption {
                                    netSalesProceeds
                                    salesPrice
                                    salesPriceTotal
                                    sellingCost
                                    sellingCostTotal
                                    seniorLoanRepayment
                                }
                                propertyTaxAssumption {
                                    taxGrowthRates
                                    mileageRate
                                    percentValueAssessed
                                    reassessedUponSale
                                    currentAssessedValue
                                    fixedChargeAssessments
                                    currentValuation
                                    landMarketValue
                                    improvementMarketValue
                                    totalMarketValue
                                    assessedLandValue
                                    assessedImprovementValue
                                    totalAssessedValue
                                    taxAmount
                                    totalTaxDue
                                    manualAdjust
                                    totalTaxDueAdjusted
                                    comments
                                    taxAbatementPeriod
                                    abatedTaxBill
                                    fullTaxBill
                                    taxAbatementGrowthRate
                                    npvRate
                                }
                                adjustmentsSettings {
                                    incomePctOption
                                    expensesPctOption
                                    incomeValueType
                                    expenseValueType
                                    trendType
                                    threshold
                                    showComments
                                    showPCT
                                    replacementReservesApplyAll
                                    mgmtFeeApplyAll
                                    columnsSelected
                                    useCollectionsAsNRI
                                    useCollectionsAsNRISelectedValue
                                    useCollectionsAsNRIType
                                    useCollectionsAsNRIEconomicValue
                                    collectionsSettings {
                                        expandT12Internal
                                        showT12WithSmooth
                                    }
                                    pnlStructureRules {
                                        addTotalLines {
                                            idsToSum
                                            placeAfterId
                                            section
                                            title
                                        }
                                    }
                                }
                                t12Adjustments {
                                    ...adjRow
                                    subRows {
                                        ...adjRow
                                    }
                                }
                                t12AdjustmentsIncome {
                                    ...adjRow
                                    subRows {
                                        ...adjRow
                                    }
                                }
                                t12AdjustmentsRetail {
                                    ...adjRow
                                    subRows {
                                        ...adjRow
                                    }
                                }
                                t12AdjustmentsIncomeRetail {
                                    ...adjRow
                                    subRows {
                                        ...adjRow
                                    }
                                }
                                unitMixRetail {
                                    id
                                    unitType
                                    floorPlan
                                    tenant
                                    unitsize
                                    rentprice
                                    originalrentprice
                                    stabilizedRentPrice
                                    leaseStart
                                    leaseEnd
                                    leaseTerm
                                    rentStart
                                    status
                                    recordType
                                    tenantFromRR
                                    overhang
                                }
                                renovationProgram {
                                    enabled
                                    startMonth
                                    endMonth
                                    renovationDuration
                                    costPerUnit
                                    maxUnitsPerMonth
                                    isDetailed
                                    quickmode_avgPremium
                                    quickmode_totalRenovatedUnits
                                }
                                breakdownCosts {
                                    disabled
                                    transactionCostsList {
                                        ...costList
                                    }
                                    loanCostsList {
                                        ...costList
                                    }
                                }
                                renovationBudgetCosts {
                                    disabled
                                    exteriorList {
                                        ...costList
                                    }
                                    interiorList {
                                        ...costList
                                    }
                                }
                                SeniorBreakdown {
                                    disabled
                                    list {
                                        ...costList
                                    }
                                }
                                MezzanineBreakdown {
                                    disabled
                                    list {
                                        ...costList
                                    }
                                }
                                renovationLoanDraws {
                                    disabled
                                    list {
                                        ...loanDrawList
                                    }
                                }
                                newDevelopmentBudget {
                                    enabled
                                }
                                newDevBudgetCosts {
                                    disabled

                                    softCostsList {
                                        ...costList
                                    }
                                    hardCostsList {
                                        ...costList
                                    }
                                    landCostsList {
                                        ...costList
                                    }
                                    financingCostsList {
                                        ...costList
                                    }
                                }
                                operatingAssumptionsSettings {
                                    usesType
                                    t12AnalysisCollectionsSettings {
                                        showSmoothAanalysis
                                        showComments
                                        trendType
                                        threshold
                                        columnsSelected
                                    }
                                    t12AnalysisSettings {
                                        showComments
                                        showMarking
                                        trendType
                                        threshold
                                    }
                                    taxSettings {
                                        showComments
                                        showTaxAbatementScenario
                                        showLeadingQuestions
                                        columnsSelected
                                    }
                                }
                                refinance {
                                    ltv
                                    noi
                                    dscr
                                    newfcf
                                    ioPeriod
                                    loanTerm
                                    exitCapRate
                                    loanRepayment
                                    refinanceType
                                    ioPeriodMonths
                                    loanTermMonths
                                    amortizationTerm
                                    lenderFeePercent
                                    existingLoanPenaltyPercentage
                                    newPropertyValue
                                    refinanceLoanCost
                                    refinanceLoanAmount
                                    refinanceIntrestRate
                                    amortizationTermMonths
                                    newAmortizationPayment
                                    remainingCashflowtoBorrower
                                }
                                promote {
                                    showOnOM
                                    equity_share {
                                        title
                                        percentage
                                    }
                                    hurdles {
                                        title
                                        ratios
                                        irr
                                    }
                                }
                                fannieAffordabilityEstimatorValues {
                                    pctUnits
                                    qualify120AMI
                                    qualify100AMI
                                    qualify80AMI
                                    qualify60AMI
                                    qualify50AMI
                                    exclusion
                                }
                                fanniePricing {
                                    valuationComparison {
                                        assumedValue
                                        capRate
                                        comment
                                        selected
                                    }
                                    loanInfo {
                                        type
                                        dateOfPricing
                                    }
                                    supplemental {
                                        name
                                        priorLien {
                                            nmLoanId
                                            fnmaId
                                            upbOriginalLoanAmount
                                            _targetLoanClosing
                                            estimatedUpbAtSupClosing
                                            upbAtmaturity
                                            intrestRate
                                            intrestBasis
                                            closed
                                            maturityDate
                                            amortizationYears
                                            ioYears
                                            minDSCR
                                            actualDSCR
                                            actualDSCRDate
                                            anualAmortization
                                            testApply
                                        }
                                        valueChanges {
                                            appraised
                                            underwritten
                                            appraisedCapRate
                                        }
                                        terms {
                                            firstPayment
                                            maturityDate
                                            termMonths
                                            termYears
                                            ymMonths
                                            ymYears
                                            actualDSCR
                                        }
                                    }
                                }
                                fannieExitScenarioRefinance {
                                    selectedOptionId
                                    refinanceAssumptions {
                                        assumptions {
                                            amortization
                                            dscr
                                            ltv
                                        }
                                        rateAssumptions {
                                            benchmarkExitRefiRate
                                            originalRefiRate
                                        }
                                    }
                                }
                                cfTrends {
                                    trendsName
                                    trends {
                                        id
                                        trend
                                    }
                                    gpr
                                    tax {
                                        override
                                        val
                                    }
                                }
                            }
                        }
                    `,
                    {
                        projectId: '' + projectId,
                    },
                    {
                        'server-user': 'TRUE',
                    },
                )
            } catch (eee) {}

            const cashflow = responseModelAndCF?.data.cashFlow
                ? await regenerateCashFlow({
                      cashFlow: responseModelAndCF?.data.cashFlow,
                      model: responseModelAndCF?.data.originationModel,
                      unitMix: responseOfInt?.data?.getUnitMixList,
                  })
                : null

            const out = {
                props: {
                    ...getEnv(),
                    commonInfo: commonInfo,
                    data: res.data,
                    user,
                    loading: false,
                    error: null,
                    dealHighlights: responseDealHighlights?.data?.project || null,
                    dealOutline: responseDealOutline?.data?.project || null,

                    retailStats: responsRetail?.data?.retailStats || null,
                    taxRecords: responseOfInt?.data?.taxRecords?.taxRecords || [],
                    taxDataVisible: responseOfInt?.data?.taxRecords?.taxDataVisible,
                    property: responseProperty?.data?.project || null,
                    proforma: responseProforma?.data?.project || null,
                    propertyEnrichments: responsePropertyEnrichments?.data?.project || null,
                    comparables: responseComparables?.data?.project || null,
                    construction: responseOfInt.data.getConstructionData,

                    rentComparables: responseOfInt.data.rentComparable,
                    location: responseOfInt.data.projectLocation,
                    originationModel: originationModel?.data?.originationModel || null,
                    unitMix: responseOfInt?.data?.getUnitMixList || [],

                    market: responseMarket?.data?.project || null,
                    markets,
                    employment: responseEmployment?.data?.project || null,
                    geocode: geocodeResponse?.data || null,
                    employmentStats: employmentIndustryAndOccupation?.specialisations || [],
                    demographic: demographicResponse?.data.demographicData || null,
                    crimeInfo,
                    schools: schoolsRequest?.data?.schools || null,
                    employmentIndustryAndOccupation: employmentIndustryAndOccupation,

                    areaInformation: responseOfInt?.data?.getMarketAreaInformation,
                    contentHousing: responseOfInt.data.getMarketHouseContent,

                    account: responseOfInt.data.getMinisiteAccountInfo,
                    cfT12: cashflow ? orgenizeCFForPresentation({ cashFlow: cashflow }) : null,
                    cfModel: responseModelAndCF?.data?.originationModel || null,
                    cashFlow: cashflow,
                    borrowerProforma: responseOfInt.data.proforma,
                    projectInfo: responseOfInt?.data?.project,

                    generalInputs: responseOfInt.data.getProjectGeneralInputs,
                },
            }
            return out
        })
    } catch (e) {
        console.log('dealnarrative om getServerSideProps error', e)
        const out = {
            props: {
                ...getEnv(),
                commonInfo: null,
                user: null,
                data: null,
                loading: false,
                error: null,
            },
        }
        return out
    }
}
