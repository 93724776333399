import React from 'react'

type Props = {
    demographic: string | null
    city: string
    noTitle?: boolean
    style?: any
}

export function NeighborhoodDemographic(props: Props) {
    const demographic = props?.demographic?.split(';')

    return (
        <section className="cblock markPdfPageBreak" id="demographics" style={props?.style || {}}>
            <div className="container">
                <div className="row">
                    {!props?.noTitle && (
                        <div className="cblock__head">
                            <p className="cblock__sect ">
                                <span>Demographics</span>
                            </p>
                        </div>
                    )}

                    <div id="demcontainer" className="cblock__body text-justify">
                        <div className="row">
                            <div className="col-md-6">
                                <p id="demliner" className="cblock__highlight " style={{ width: '125%' }}>
                                    These are the demographics for {props.city}
                                </p>
                            </div>
                        </div>
                        <div className="grid grid--demographics mt-4 mb-5 text-justify">
                            <div className="grid__item grid__item--large ">
                                <i className="seeicon-singleres" />
                                <p id="demdesc1" className="stat-val">
                                    {demographic?.[0] || '-'}
                                </p>
                                <p className="stat-desc">Single Residents</p>
                            </div>

                            <div className="grid__item grid__item--large text-justify">
                                <i className="seeicon-homeowners" />
                                <p id="demdesc2" className="stat-val">
                                    {demographic?.[1] || '-'}
                                </p>
                                <p className="stat-desc">Family Households</p>
                            </div>

                            <div className="grid__item grid__item--large text-justify">
                                <i className="seeicon-age" />
                                <p id="demdesc3" className="stat-val">
                                    {demographic?.[2] || '-'}
                                </p>
                                <p className="stat-desc">Median Age</p>
                            </div>

                            <div className="grid__item grid__item--large text-justify">
                                <i className="seeicon-income" />
                                <p id="demdesc4" className="stat-val">
                                    {demographic?.[3] || '-'}
                                </p>
                                <p className="stat-desc">Median Household Income</p>
                            </div>

                            <div className="grid__item grid__item--large text-justify">
                                <i className="seeicon-college" />
                                <p id="demdesc5" className="stat-val">
                                    {demographic?.[4] || '-'}
                                </p>
                                <p className="stat-desc">College Educated</p>
                            </div>
                        </div>
                        <div className="row text-justify" id="dempopcontent">
                            {demographic?.[15] && demographic?.[15] !== 'none' && (
                                <div className="col-lg-6">
                                    <h4 className="mb-3  mt-4 mt-lg-0">Demographics Summary</h4>
                                    <p id="dempop1" className="">
                                        {demographic?.[15] || ''}
                                    </p>
                                </div>
                            )}

                            {(demographic?.[16] || demographic?.[17]) && (
                                <div className="col-lg-6 text-justify">
                                    <h4 className="mb-3  mt-4 mt-lg-0">Population Growth</h4>
                                    <p id="dempop2" className="">
                                        {demographic?.[16] || ''}
                                    </p>
                                    <p id="dempop3" className="">
                                        {demographic?.[17] || ''}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
