import * as React from 'react'

// eslint-disable-next-line @typescript-eslint/naming-convention
export const THEME_BLACK = 'black'
// eslint-disable-next-line @typescript-eslint/naming-convention
export const THEME_WHITE = 'white'

export type ThemeContextType = {
    theme: string
}

export const ThemeContext = React.createContext<ThemeContextType>({
    theme: THEME_BLACK,
})

export const ThemeContextProvider = ThemeContext.Provider
