import React from 'react'
import ReportSection from './ReportSection'
import { formatDollars } from '../../../utils/dealnarrative/htmlUtils'

type Props = {
    narrative: any
}

export default function DealInformationSections(props: Props) {
    const { narrative } = props

    return (
        <>
            <ReportSection title={'Property Information'}>
                <div className={'tabulatedInformation'}>
                    <div className={'row'}>
                        <div className={'col fieldLabel'}>Property Name</div>
                        <div className={'col fieldValue'}>{narrative?.propertyName}</div>
                    </div>
                    <div className={'row'}>
                        <div className={'col fieldLabel'}>Property Address</div>
                        <div className={'col fieldValue'}>{narrative?.propertyAddress}</div>
                    </div>
                    <div className={'row'}>
                        <div className={'col fieldLabel'}>MSA</div>
                        <div className={'col fieldValue'}>{narrative?.msa}</div>
                    </div>
                    <div className={'row'}>
                        <div className={'col fieldLabel'}>Property Type</div>
                        <div className={'col fieldValue'}>{narrative?.propertyType}</div>
                    </div>
                    <div className={'row'}>
                        <div className={'col fieldLabel'}>Deal Type</div>
                        <div className={'col fieldValue'}>{narrative?.dealType}</div>
                    </div>
                </div>
            </ReportSection>
            <ReportSection title={'Loan Details'}>
                <div className={'row'}>
                    <div className={'col fieldLabel'}>Loan Details</div>
                    <div className={'col fieldValue'}>{formatDollars(narrative?.loanAmount)}</div>
                </div>
            </ReportSection>
            {/*
            <ReportSection title={'NorthMarq Team'}>
                <div className={'row'}>
                    <div className={'col fieldLabel'}>Reviewing Chief Underwriter</div>
                    <div className={'col fieldValue'}>{narrative?.chiefUnderwriter}</div>
                </div>
            </ReportSection>
            */}
        </>
    )
}
