import React, { useMemo, useState } from 'react'
import { useRouter } from 'next/router'

import { ConstructionNewSupply } from '../../../expose/construction/ConstructionNewSupply'
import { ConstructionSupplyAndDemand } from '../../../expose/construction/ConstructionSupplyAndDemand'
import { ConstructionTable } from '../../../expose/construction/ConstructionTable'
import { metersToMiles } from '../../../../../utils'
import { ConstructionProperty } from '@generated/graphql'
import BasePageLayoutOM from '../BasePageLayoutOM'
import ReportSection from '../ReportSection'

const perChunk = 12 // items per chunk
function splitArrToChunck(inputArray: any) {
    const result = inputArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk)

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
    }, [])
    return result
}

export function Construction(props: any) {
    const [rangeFilter, setRangeFilter] = useState<number>(5)
    const { construction } = props

    const filteredPlannedItems =
        construction?.planned?.filter((item: ConstructionProperty) => {
            return metersToMiles(item.distance) < rangeFilter
        }) || []
    const filteredUnderConstructionItems =
        construction?.underConstruction?.filter((item: ConstructionProperty) => {
            return metersToMiles(item.distance) < rangeFilter
        }) || []

    let totalFilteredItems = []

    totalFilteredItems = [...totalFilteredItems, ...filteredUnderConstructionItems]
    totalFilteredItems = [...totalFilteredItems, ...filteredPlannedItems]

    const filteredUnderConstructionItemsArr = splitArrToChunck(filteredUnderConstructionItems)
    const filteredPlannedItemsArr = splitArrToChunck(filteredPlannedItems)

    return (
        <>
            {/*}
            <div className={'reportPage page a4-150dpiSpecialOM'}>
                <BasePageLayoutOM title={'SubMarket Insights'} subtitle={props.headerSubtitle}>
                    <ReportSection title={'Construction'} className={'Construction'} titleSmall={true}>
                        <div className={'expose-wrapper white-theme'} style={{ marginTop: '-130px' }}>
                            <main>
                                <div className={'cblock'}>
                                    <ConstructionNewSupply
                                        // @ts-ignore
                                        filterRange={rangeFilter}
                                        items={totalFilteredItems}
                                        location={construction.location}
                                        google={props.google}
                                    />
                                </div>
                            </main>
                        </div>
                    </ReportSection>
                </BasePageLayoutOM>
            </div>
            */}
            <div className={'reportPage page a4-150dpiSpecialOM'}>
                <BasePageLayoutOM title={'SubMarket Insights'} subtitle={props.headerSubtitle}>
                    <ReportSection title={'Construction'} className={'Construction'} titleSmall={true}>
                        <div className={'expose-wrapper white-theme'} style={{ marginTop: '-130px' }}>
                            <main>
                                <div className={'cblock'} style={{ position: 'relative', left: '-20px' }}>
                                    <ConstructionSupplyAndDemand
                                        supplyAndDemand={construction?.supplyAndDemand}
                                        projectedOccupancy={construction?.projectedOccupancy || 0}
                                    />
                                </div>
                            </main>
                        </div>
                    </ReportSection>
                </BasePageLayoutOM>
            </div>

            {filteredPlannedItemsArr.map((arr, arrIdx) => (
                <div className={'reportPage page a4-150dpiSpecialOM'} key={'planned' + arrIdx}>
                    <BasePageLayoutOM title={'SubMarket Insights'} subtitle={props.headerSubtitle}>
                        <ReportSection title={'Construction'} className={'Construction'} titleSmall={true}>
                            <div className={'expose-wrapper white-theme'} style={{ marginTop: '-130px' }}>
                                <main>
                                    <div className={'cblock'}>
                                        <ConstructionTable
                                            items={arr}
                                            tableTitle={'Planned'}
                                            tableTitleStyle={{ marginBottom: '10px' }}
                                        />
                                    </div>
                                </main>
                            </div>
                        </ReportSection>
                    </BasePageLayoutOM>
                </div>
            ))}

            {filteredUnderConstructionItemsArr.map((arr, arrIdx) => (
                <div className={'reportPage page a4-150dpiSpecialOM'} key={'Under construction' + arrIdx}>
                    <BasePageLayoutOM title={'SubMarket Insights'} subtitle={props.headerSubtitle}>
                        <ReportSection title={'Construction'} className={'Construction'} titleSmall={true}>
                            <div className={'expose-wrapper white-theme'} style={{ marginTop: '-130px' }}>
                                <main>
                                    <div className={'cblock'}>
                                        <ConstructionTable
                                            items={arr}
                                            tableTitle={'Under construction'}
                                            tableTitleStyle={{ marginBottom: '10px' }}
                                        />
                                    </div>
                                </main>
                            </div>
                        </ReportSection>
                    </BasePageLayoutOM>
                </div>
            ))}

            {/*language=SCSS*/}
            <style jsx>
                {`
                    .buttons-wrapper {
                        display: flex;
                        justify-content: center;
                        .buttons-item {
                            border: 1px solid rgba(255, 255, 255, 0.45);
                            padding: 5px 10px;
                            transition: 0.2s all linear;
                            cursor: pointer;
                            &.active {
                                background: #1fb6dc;
                            }
                        }
                    }
                `}
            </style>
        </>
    )
}
