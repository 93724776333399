import React from 'react'
import { SupplyAndDemandItem } from '../../../../generated/graphql'
import { ApexBarLinearChart } from '../../../charts/ApexBarLinearChart'
type Props = { supplyAndDemand: SupplyAndDemandItem[]; projectedOccupancy: number }
export function ConstructionSupplyAndDemand(props: Props) {
    const { supplyAndDemand, projectedOccupancy } = props
    const preparedData = {}
    for (const supply of supplyAndDemand) {
        if (!preparedData[supply.year]) {
            preparedData[supply.year] = {
                occupancy: 0,
                supply: 0,
                demand: 0,
            }
        }
        preparedData[supply.year].occupancy += supply.occupancy
        preparedData[supply.year].supply += supply.supply
        preparedData[supply.year].demand += supply.demand
    }

    const chartData = {
        data: [
            {
                name: 'New supply',
                type: 'column',
                data: [],
            },
            {
                name: 'Demand',
                type: 'column',
                data: [],
            },
            {
                name: 'Occupancy',
                type: 'line',
                data: [],
            },
        ],
        labels: [],
    }

    for (const key in preparedData) {
        chartData.labels.push(key)
        chartData.data[0].data.push(preparedData[key]['supply'])
        chartData.data[1].data.push(preparedData[key]['demand'])
        chartData.data[2].data.push(preparedData[key]['occupancy'])
    }

    return (
        <section className="cblock cblock--main" id="intro">
            <div className="container">
                <div className="row">
                    <div className="cblock__head animatedin in stop">
                        <h2>Supply & Demand</h2>
                    </div>
                    <div className="cblock__body">
                        <p>
                            For the next five years, new supply in the submarket will be very minimal, however{' '}
                            <b>
                                projected occupancy over the next 5 years is expected to be well above{' '}
                                {projectedOccupancy}%.
                            </b>
                        </p>
                        <p>
                            The affordability of this rental submarket will continue to create demands as more jobs are
                            created and home values continue to rise
                        </p>
                    </div>
                    <div className="cblock__body">
                        <ApexBarLinearChart
                            data={chartData.data}
                            labels={chartData.labels}
                            title={'SUPPLY AND DEMAND'}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}
