import React, { useCallback } from 'react'

type Props = {
    onClose?: Function
    onDownloadPdf?: Function
    onShare?: Function
}

export default function ReportButtonBar(props: Props) {
    const { onClose, onDownloadPdf, onShare } = props

    const onClickClose = useCallback(
        (event) => {
            event.preventDefault()
            if (!!onClose) onClose()
        },
        [onClose],
    )

    const onClickDownloadPdf = useCallback(
        (event) => {
            event.preventDefault()
            if (!!onDownloadPdf) onDownloadPdf()
        },
        [onDownloadPdf],
    )

    const onClickShare = useCallback(
        (event) => {
            event.preventDefault()
            if (!!onShare) onShare()
        },
        [onShare],
    )

    return (
        <div className="ReportButtonBar ButtonBar d-flex justify-content-between">
            <div>
                <button type="button" onClick={onClickClose} className="btn btn-outline-primary">
                    Close
                </button>
            </div>
            <div className="ml-auto">
                <button type={'button'} onClick={onClickDownloadPdf} className="btn btn-outline-primary">
                    Download PDF
                </button>
                <button type={'button'} onClick={onClickShare} className="btn btn-primary">
                    Share
                </button>
            </div>
        </div>
    )
}
