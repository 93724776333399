import React from 'react'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../store'
import { Spinner } from '../../../../../controls/Spinner'
import { KpiCard } from 'components/pages/dashboard/components/KpiCard'
import BasePageLayoutOM from '../../BasePageLayoutOM'
import ReportSection from '../../ReportSection'

export const MultiFamilyInsightsOM = (props: any) => {
    const multiFamilyInsights = useSelector(
        (state: ReduxStoreState) => state.operatingAssumptions?.submarketInsights?.multiFamilyInsights,
    )

    return !!multiFamilyInsights ? (
        <>
            <div className={'reportPage page a4-150dpiSpecialOM'}>
                <BasePageLayoutOM title={'SubMarket Insights'} subtitle={props.headerSubtitle}>
                    <ReportSection title={'MultiFamily Insights'} className={'MultiFamilyInsights'} titleSmall={true}>
                        <div className="boxes-wrapper">
                            {multiFamilyInsights.effectiveRent?.value && (
                                <KpiCard
                                    title={'Avg. Effective Rent'}
                                    value={multiFamilyInsights.effectiveRent.value}
                                    className="multiFamilyInsights basicMultiFamilyInsights"
                                    symbol="dollar"
                                />
                            )}

                            {multiFamilyInsights.vacancy?.value && (
                                <KpiCard
                                    title={'Avg. Vacancy'}
                                    value={multiFamilyInsights.vacancy.value / 100}
                                    className="multiFamilyInsights basicMultiFamilyInsights"
                                    symbol="percent"
                                />
                            )}

                            {multiFamilyInsights.totalInventory?.value && (
                                <KpiCard
                                    title={'Total Inventory'}
                                    value={multiFamilyInsights.totalInventory.value}
                                    className="multiFamilyInsights basicMultiFamilyInsights"
                                    symbol="none"
                                />
                            )}

                            {multiFamilyInsights.askingRent?.value &&
                                multiFamilyInsights.askingRent?.quarter &&
                                multiFamilyInsights.askingRent?.year &&
                                multiFamilyInsights.askingRent?.trend && (
                                    <KpiCard
                                        title={`Asking Rent (${multiFamilyInsights.askingRent.quarter} ${multiFamilyInsights.askingRent.year})`}
                                        value={multiFamilyInsights.askingRent.value}
                                        className="multiFamilyInsights"
                                        symbol="dollar"
                                        trend={multiFamilyInsights.askingRent.trend}
                                    />
                                )}

                            {multiFamilyInsights.avgAnnualAbsorptionRate?.value &&
                                multiFamilyInsights.avgAnnualAbsorptionRate?.startYear &&
                                multiFamilyInsights.avgAnnualAbsorptionRate?.endYear &&
                                multiFamilyInsights.avgAnnualAbsorptionRate?.startQuarter &&
                                multiFamilyInsights.avgAnnualAbsorptionRate?.endQuarter && (
                                    <KpiCard
                                        title={`Absorption Rate (${multiFamilyInsights.avgAnnualAbsorptionRate?.startQuarter} ${multiFamilyInsights.avgAnnualAbsorptionRate?.startYear} - ${multiFamilyInsights.avgAnnualAbsorptionRate?.endQuarter} ${multiFamilyInsights.avgAnnualAbsorptionRate?.endYear})`}
                                        value={multiFamilyInsights.avgAnnualAbsorptionRate.value / 100}
                                        className="multiFamilyInsights basicMultiFamilyInsights"
                                        symbol="percent"
                                    />
                                )}

                            {multiFamilyInsights.annualNetAbsorption?.value &&
                                multiFamilyInsights.annualNetAbsorption?.startYear &&
                                multiFamilyInsights.annualNetAbsorption?.endYear &&
                                multiFamilyInsights.annualNetAbsorption?.startQuarter &&
                                multiFamilyInsights.annualNetAbsorption?.endQuarter && (
                                    <KpiCard
                                        title={`Household Formation (${multiFamilyInsights.annualNetAbsorption?.startQuarter} ${multiFamilyInsights.annualNetAbsorption?.startYear} - ${multiFamilyInsights.annualNetAbsorption?.endQuarter} ${multiFamilyInsights.annualNetAbsorption?.endYear})`}
                                        value={multiFamilyInsights.annualNetAbsorption.value}
                                        className="multiFamilyInsights basicMultiFamilyInsights"
                                        symbol="units"
                                    />
                                )}
                        </div>
                        <div className="other">
                            {multiFamilyInsights.stories.slice(0, 3).map((story, i) => (
                                <div className="story" key={i}>
                                    <h6>{story.title}</h6>
                                    <p>{story.description}</p>
                                </div>
                            ))}
                        </div>
                    </ReportSection>
                </BasePageLayoutOM>
            </div>

            <div className={'reportPage page a4-150dpiSpecialOM'}>
                <BasePageLayoutOM title={'SubMarket Insights'} subtitle={props.headerSubtitle}>
                    <ReportSection title={'MultiFamily Insights'} className={'MultiFamilyInsights'} titleSmall={true}>
                        <div className="other">
                            {multiFamilyInsights.stories.slice(3).map((story, i) => (
                                <div className="story" key={i}>
                                    <h6>{story.title}</h6>
                                    <p>{story.description}</p>
                                </div>
                            ))}
                        </div>
                    </ReportSection>
                </BasePageLayoutOM>
            </div>

            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .other {
                        text-align: justify;
                    }

                    .boxes-wrapper {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                        :global(.title) {
                            min-height: 70px;
                        }
                    }
                    .other {
                        flex: 1;
                    }
                    .story:not(:first-child) {
                        margin-top: 50px;
                    }
                    h6 {
                        font-weight: 700;
                    }
                `}
            </style>
        </>
    ) : (
        <Spinner />
    )
}
