import React from 'react'
import { Modal } from 'react-bootstrap'

type Props = {
    show?: boolean
    title?: string
    size?: string
    onAbort?: Function
    children?: React.ReactNode
}

export default function NarrativeModal(props: Props) {
    const { show, title, size, onAbort, children } = props

    return (
        <>
            {/*// @ts-ignore*/}
            <Modal show={show} onHide={() => onAbort?.()} className={'NarrativeModal'} size={size || ''}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{children}</Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" onClick={() => onAbort?.()}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
