import React from 'react'
import { ConstructionProperty } from '../../../../generated/graphql'
import { metersToMiles } from '../../../../utils'

type Props = {
    tableTitle: string
    items: ConstructionProperty[]
    tableTitleStyle?: any
}
export function ConstructionTable(props: Props) {
    const { tableTitle, items } = props

    if (!items) {
        return null
    }

    return (
        <section className="cblock cblock--main markPdfPageBreakBefore">
            <div className="container">
                <div className="row">
                    <div className="cblock__body">
                        <div className={'table-title'} style={props.tableTitleStyle || {}}>
                            {tableTitle}
                        </div>
                        <table className="table table-borderless table-striped table--smallmobile animatedin in stop">
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>No. Units</th>
                                    <th>Submarket</th>
                                    <th>Impr Rating</th>
                                    <th className="text-right">Loc. Rating</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <div>
                                                    <b>{item.name}</b> at
                                                </div>
                                                <div>
                                                    <b>{item.address}</b>
                                                </div>
                                                <div className={'distance'}>{metersToMiles(item.distance)} mi</div>
                                            </td>
                                            <td>{item.noUnits}</td>
                                            <td>{item.subMarket}</td>
                                            <td>{item.imprRating}</td>
                                            <td className="text-right font-weight-bold">{item.locRating}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/*language=scss*/}
            <style jsx>{`
                .table-title {
                    text-align: center;
                    font-size: 2rem;
                    text-transform: uppercase;
                }
                .table {
                    color: white;
                    border: 1px solid rgba(255, 255, 255, 0.45);
                    tr {
                        //display: flex;
                        //justify-content: space-between;
                        td,
                        th {
                            min-width: 90px;
                            //&:nth-child(1) {
                            //    flex-grow: 1;
                            //}
                        }
                    }
                    .distance {
                        font-size: 0.7rem;
                        color: rgba(255, 255, 255, 0.45);
                        margin-top: 5px;
                    }
                }
            `}</style>
        </section>
    )
}
