import React from 'react'

export function DealHighlightsLoans(props: { dealHighlights: any; noTitle?: boolean }) {
    let loans = []
    if (props.dealHighlights?.details) {
        try {
            const details = JSON.parse(props.dealHighlights.details)
            loans = details.loan
        } catch (e) {
            console.log(e.message)
        }
    }

    const showValuesBlock =
        +props?.dealHighlights?.cashoncash !== 0 ||
        +props?.dealHighlights?.terms !== 0 ||
        +props?.dealHighlights?.irr !== 0 ||
        +props?.dealHighlights?.equitymulti !== 0

    const markPdfPageBreak =
        showValuesBlock && props?.dealHighlights?.content && loans?.length > 0 ? ' markPdfPageBreak' : ''
    return (
        <section className={'cblock cblock--main' + markPdfPageBreak} id="intro">
            <div className="container">
                <div className="row">
                    {!props?.noTitle && (
                        <div className="cblock__head animatedin in stop">
                            <h1>Deal Highlights</h1>
                        </div>
                    )}

                    <div className="cblock__body">
                        {showValuesBlock && (
                            <React.Fragment>
                                <div className="row">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <img src="img/Picture1.png" />
                                                </td>
                                                <td>
                                                    <img src="img/Picture2.png" />
                                                </td>
                                                <td>
                                                    <img src="img/Picture3.png" />
                                                </td>
                                                <td>
                                                    <img src="img/Picture4.png" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td id="aterms">
                                                    Investment Term: {props?.dealHighlights?.terms || ' '} Years
                                                </td>
                                                <td id="acashoncash">
                                                    Expected Average Cash-On-Cash:{' '}
                                                    {props?.dealHighlights?.cashoncash || ' '}%
                                                </td>
                                                <td id="airr">
                                                    Expected Investor IRR: {props?.dealHighlights?.irr || ' '}%
                                                </td>
                                                <td id="aequitymulti">
                                                    Expected Investor Equity Multiple:{' '}
                                                    {props?.dealHighlights?.equitymulti || ' '}x
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="distancer" />
                                <div className="distancer" />
                            </React.Fragment>
                        )}

                        {props?.dealHighlights?.content && (
                            <React.Fragment>
                                <div className="row">
                                    <div
                                        className="col-12"
                                        dangerouslySetInnerHTML={{
                                            // eslint-disable-next-line @typescript-eslint/naming-convention
                                            __html: props?.dealHighlights?.content,
                                        }}
                                    />
                                </div>

                                <div className="distancer" />
                                <div className="distancer" />
                            </React.Fragment>
                        )}

                        {loans?.length > 0 && (
                            <React.Fragment>
                                <div className="row" id="projectloans">
                                    <div className="col-lg-6 animatedin in stop">
                                        <p className="cblock__highlight" style={{ marginLeft: -15 }}>
                                            Deal summary:
                                        </p>
                                    </div>
                                </div>
                                <ul className="loans-wrapper container">
                                    {loans.map((loan) => {
                                        return (
                                            <li className="" key={loan}>
                                                <p className="">{loan}</p>
                                            </li>
                                        )
                                    })}
                                </ul>
                                <div className="distancer" />
                                <div className="distancer" />
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
            {/*language=scss*/}
            <style jsx>{`
                table {
                    #aterms,
                    #acashoncash,
                    #airr,
                    #aequitymulti {
                        padding: 0 15px 0 0;
                    }
                }
                .loans-wrapper {
                    margin-left: -15px;
                }
            `}</style>
        </section>
    )
}
