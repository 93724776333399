import React from 'react'
import { DealOutlineLoan } from '../../../../store/types/fullproject'
import { ApexBarChart } from '../../../charts/ApexBarChart'
import { ApexPieChart } from '../../../charts/ApexPieChart'
const numeral = require('numeral')

export function DealHighlightsFinancingRequirements(props: { dealOutline: any }) {
    const filteredLoans: DealOutlineLoan[] =
        props?.dealOutline?.loans?.filter((loan) => {
            return loan.showOnDealHighlightsPage === '1'
        }) || []

    const chartData = {
        labels: [] as string[],
        values: [] as number[],
    }

    for (const loan of filteredLoans) {
        chartData.labels.push(loan.type)
        chartData.values.push(+loan.amount)
    }

    if (!filteredLoans?.length || filteredLoans?.length == 0) {
        return null
    }
    const markPdfPageBreak = filteredLoans?.length > 0 ? ' markPdfPageBreak' : ''
    return (
        <section className={'cblock' + markPdfPageBreak} id="financing_requirements">
            {filteredLoans?.length > 0 && (
                <div className="container">
                    <div className="row">
                        <p className="cblock__sect animatedin in stop">
                            <span>Financing requirements</span>
                        </p>
                        <div className="cblock__body">
                            <div className="table-responsive">
                                <table className="table table-borderless table-striped table--smallmobile animatedin in stop">
                                    <thead>
                                        <tr>
                                            <th>Financing type</th>
                                            <th>Amount needed</th>
                                            <th>Requested loan interest rate</th>
                                            <th>LTV request</th>
                                            <th>Lender type</th>
                                            <th className="text-right">Equity type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredLoans.map((loan: DealOutlineLoan, index) => {
                                            return <LoanTableRow key={index} loan={loan} />
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {props?.dealOutline?.financingRequirementsChartVisible === '1' && (
                            <React.Fragment>
                                <div className="cblock__body">
                                    {props?.dealOutline?.financingRequirementsChartType === 'pie' && (
                                        <ApexPieChart values={chartData.values} labels={chartData.labels} width={400} />
                                    )}
                                    {props?.dealOutline?.financingRequirementsChartType === 'bar' && (
                                        <ApexBarChart values={chartData.values} labels={chartData.labels} />
                                    )}
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            )}

            {/*language=SCSS*/}
            <style jsx>{`
                #financing_requirements .table {
                    color: white;
                }
            `}</style>
        </section>
    )
}

export function LoanTableRow(props: { loan: DealOutlineLoan }) {
    const emptyValue = '---'

    const formatAmount = (amount: number) => {
        return numeral(amount).format('0,0.00')
    }

    switch (props.loan.type) {
        case 'Equity':
            return (
                <tr>
                    <td>{props.loan.type}</td>
                    <td>{formatAmount(+props.loan.amount)}</td>
                    <td>{emptyValue}</td>
                    <td>{props.loan.lpv_request}</td>
                    <td>{emptyValue}</td>
                    <td className="text-right font-weight-bold">{props.loan.equity_type}</td>
                </tr>
            )
        case 'Debt':
            return (
                <tr>
                    <td>{props.loan.type}</td>
                    <td>{formatAmount(+props.loan.amount)}</td>
                    <td>{props.loan.intrest}</td>
                    <td>{props.loan.lpv_request}</td>
                    <td>{props.loan.lender_type}</td>
                    <td className="text-right font-weight-bold">{emptyValue}</td>
                </tr>
            )
        case 'Mezzanine':
        case 'Mortgage':
            return (
                <tr>
                    <td>{props.loan.type}</td>
                    <td>{formatAmount(+props.loan.amount)}</td>
                    <td>{props.loan.intrest}</td>
                    <td>{props.loan.lpv_request}</td>
                    <td>{emptyValue}</td>
                    <td className="text-right font-weight-bold">{emptyValue}</td>
                </tr>
            )
        default: {
            return (
                <tr>
                    <td>{props.loan.type || emptyValue}</td>
                    <td>{props.loan.amount || emptyValue}</td>
                    <td>{props.loan.intrest || emptyValue}</td>
                    <td>{props.loan.lpv_request || emptyValue}</td>
                    <td>{props.loan.lender_type || emptyValue}</td>
                    <td className="text-right font-weight-bold">{props.loan.equity_type || emptyValue}</td>
                </tr>
            )
        }
    }
}
