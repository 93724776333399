import React from 'react'
import NarrativeModal from './NarrativeModal'

type Props = {
    show?: boolean
    message?: string
    onDismiss?: Function
}
export default function ErrorModal(props: Props) {
    const { show, message, onDismiss } = props

    return (
        <NarrativeModal show={show} title={'Error!'} onAbort={onDismiss}>
            <p>{message}</p>
        </NarrativeModal>
    )
}
