import React from 'react'
export function GridStats(props: { proforma: any; retail?: any[] }) {
    let gridStats: Array<{ title: string; value: string }>
    if (!props.proforma) {
        gridStats = [
            {
                title: 'Gross Potential Revenue per Year',
                value: '',
            },
            {
                title: 'Gross Operating Expenses per Year',
                value: '',
            },
            {
                title: 'NOI per Year',
                value: '',
            },
            {
                title: 'Gross Potential Revenue per Unit/Year',
                value: '',
            },
            {
                title: 'Gross Operating Expenses per Unit/Year',
                value: '',
            },
            {
                title: 'NOI per Unit/Year',
                value: '',
            },
        ]
    } else if (props.proforma.custom) {
        gridStats = JSON.parse(props.proforma.custom).cols
    } else {
        gridStats = []
        const proforma = props.proforma
        const units = parseInt(proforma.units)

        const grossrev = parseInt(proforma.gross_revenue)
        gridStats.push({
            title: 'Gross Potential Revenue per Year',
            value: '$' + grossrev.toLocaleString(),
        })
        const grossexpanses = parseInt(proforma.totalcost)
        gridStats.push({
            title: 'Gross Operating Expenses per Year',
            value: '$' + grossexpanses.toLocaleString(),
        })
        const grossnoi = grossrev - grossexpanses
        gridStats.push({
            title: 'NOI per Year',
            value: '$' + grossnoi.toLocaleString(),
        })
        const grossrevunit = Math.floor(grossrev / units)
        gridStats.push({
            title: 'Gross Potential Revenue per Unit/Year',
            value: '$' + grossrevunit.toLocaleString(),
        })
        const grossexpansesunit = Math.floor(grossexpanses / units)
        gridStats.push({
            title: 'Gross Operating Expenses per Unit/Year',
            value: '$' + grossexpansesunit.toLocaleString(),
        })
        const grossnoiunit = Math.floor(grossnoi / units)
        gridStats.push({
            title: 'NOI per Unit/Year',
            value: '$' + grossnoiunit.toLocaleString(),
        })
    }

    return (
        <React.Fragment>
            {/*Table with proforma stats*/}
            <div className="grid grid--stats grid--property my-5">
                <div className="grid__item grid__item--large animatedin in stop">
                    <div className="d-flex">
                        <div className="grid__primary">
                            <p id="grossrev" className="stat-val">
                                {gridStats[0].value}
                            </p>
                            <p id="grossrevt" className="stat-desc">
                                {gridStats[0].title}
                            </p>
                        </div>
                        <div
                            className="grid__primary"
                            style={{
                                borderTop: '1px solid rgba(255,255,255,0.1)',
                                paddingTop: 25,
                                marginTop: 20,
                            }}
                        >
                            <p id="grossrevunit" className="stat-val">
                                {gridStats[3].value}
                            </p>
                            <p id="grossrevunitt" className="stat-desc">
                                {gridStats[3].title}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="grid__item grid__item--large animatedin in stop">
                    <div className="d-flex">
                        <div className="grid__primary">
                            <p id="grossexpanses" className="stat-val">
                                {gridStats[1].value}
                            </p>
                            <p id="grossexpansest" className="stat-desc">
                                {gridStats[1].title}
                            </p>
                        </div>
                        <div
                            className="grid__primary"
                            style={{
                                borderTop: '1px solid rgba(255,255,255,0.1)',
                                paddingTop: 25,
                                marginTop: 20,
                            }}
                        >
                            <p id="grossexpansesunit" className="stat-val">
                                {gridStats[4].value}
                            </p>
                            <p id="grossexpansesunitt" className="stat-desc">
                                {gridStats[4].title}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="grid__item grid__item--large animatedin in stop">
                    <div className="d-flex">
                        <div className="grid__primary">
                            <p id="grossnoi" className="stat-val">
                                {gridStats[2].value}
                            </p>
                            <p id="grossnoit" className="stat-desc">
                                {gridStats[2].title}
                            </p>
                        </div>
                        <div
                            className="grid__primary"
                            style={{
                                borderTop: '1px solid rgba(255,255,255,0.1)',
                                paddingTop: 25,
                                marginTop: 20,
                            }}
                        >
                            <p id="grossnoiunit" className="stat-val">
                                {gridStats[5].value}
                            </p>
                            <p id="grossnoiunitt" className="stat-desc">
                                {gridStats[5].title}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="grid__item grid__item--icon animatedin in stop">
                    <div className="d-flex">
                        <div>
                            <i className="seeicon-apartment"></i>
                            <p id="numunits" className="stat-val">
                                {props.proforma?.units || ''}
                            </p>
                            <p className="stat-desc">Units</p>
                        </div>
                    </div>
                </div>

                <div className="grid__item grid__item--icon animatedin in stop">
                    <div className="d-flex">
                        <div>
                            <i className="seeicon-shop"></i>
                            <p id="totalsize" className="stat-val">
                                {props.proforma ? `${parseInt(props.proforma.totalsize).toLocaleString()} SF` : ''}
                            </p>
                            <p className="stat-desc">Net Rentable Area</p>
                        </div>
                    </div>
                </div>
                {props.retail &&
                    props.retail.map((item, ind) => {
                        return (
                            <div className="grid__item grid__item--icon animatedin in stop">
                                <div className="d-flex">
                                    <div>
                                        <p id="totalsize" className="stat-val">
                                            {item.value}
                                        </p>
                                        <p className="stat-desc">{item.title}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
            </div>
        </React.Fragment>
    )
}
