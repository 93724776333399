import React from 'react'
import { Legend } from '../../../../operating-assumptions/submarket-insights/submarket-info/Legend'
import { MapAdapter } from '../../../../../defaultElements/MapAdapter'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../store'
import { Marker, Polygon } from 'react-google-maps'
import theme from '../../../../operating-assumptions/submarket-insights/submarket-info/map-theme.json'
import { Spinner } from '../../../../../controls/Spinner'
import { KpiCard } from 'components/pages/dashboard/components/KpiCard'
import BasePageLayoutOM from '../../BasePageLayoutOM'
import ReportSection from '../../ReportSection'

export const formattedDescription = (description) => {
    if (!!description) {
        let newDescription = description

        let descriptionCopy = newDescription.toLowerCase()
        const vacancyIdx = descriptionCopy.indexOf('vacancy')
        let numOfParagraphs = 0
        if (vacancyIdx !== -1) {
            for (let i = vacancyIdx; i > 0; i--) {
                if (descriptionCopy[i] === '.') {
                    if (descriptionCopy[i - 1].match(/[a-z0-9]/i) && descriptionCopy[i + 1].match(/\s/i)) {
                        numOfParagraphs++
                        newDescription =
                            newDescription.slice(0, i + 1) +
                            '<br/><br/>' +
                            (numOfParagraphs % 3 == 0 ? '!!##SPLIT##!!' : '') +
                            newDescription.slice(i + 1)
                        break
                    }
                }
            }
        }

        descriptionCopy = newDescription.toLowerCase()
        const askingRentIdx = descriptionCopy.indexOf('asking rent')
        if (askingRentIdx !== -1) {
            for (let i = askingRentIdx; i > 0; i--) {
                if (descriptionCopy[i] === '.') {
                    if (descriptionCopy[i - 1].match(/[a-z0-9]/i) && descriptionCopy[i + 1].match(/\s/i)) {
                        numOfParagraphs++
                        newDescription =
                            newDescription.slice(0, i + 1) +
                            '<br/><br/>' +
                            (numOfParagraphs % 3 == 0 ? '!!##SPLIT##!!' : '') +
                            newDescription.slice(i + 1)
                        break
                    }
                }
            }
        }

        descriptionCopy = newDescription.toLowerCase()
        const askingRentsIdx = descriptionCopy.indexOf('asking rents')
        if (askingRentsIdx !== -1 && askingRentIdx === -1) {
            for (let i = askingRentsIdx; i > 0; i--) {
                if (descriptionCopy[i] === '.') {
                    if (descriptionCopy[i - 1].match(/[a-z0-9]/i) && descriptionCopy[i + 1].match(/\s/i)) {
                        numOfParagraphs++
                        newDescription =
                            newDescription.slice(0, i + 1) +
                            '<br/><br/>' +
                            (numOfParagraphs % 3 == 0 ? '!!##SPLIT##!!' : '') +
                            newDescription.slice(i + 1)
                        break
                    }
                }
            }
        }

        descriptionCopy = newDescription.toLowerCase()
        const netAbsorptionIdx = descriptionCopy.indexOf('net absorption')
        if (netAbsorptionIdx !== -1) {
            for (let i = netAbsorptionIdx; i > 0; i--) {
                if (descriptionCopy[i] === '.') {
                    if (descriptionCopy[i - 1].match(/[a-z0-9]/i) && descriptionCopy[i + 1].match(/\s/i)) {
                        numOfParagraphs++
                        newDescription =
                            newDescription.slice(0, i + 1) +
                            '<br/><br/>' +
                            (numOfParagraphs % 3 == 0 ? '!!##SPLIT##!!' : '') +
                            newDescription.slice(i + 1)
                        break
                    }
                }
            }
        }

        for (let i = newDescription.length - 1; i > 0; i--) {
            if (newDescription[i] === '.') {
                const lastSentence = newDescription.slice(i + 1)
                if (lastSentence.indexOf('Next Update') !== -1) {
                    newDescription = newDescription.slice(0, i + 1)
                }
                break
            }
        }
        return newDescription.split('!!##SPLIT##!!')
    }
}

export const EconomicOverviewOM = (props: any) => {
    const items = [
        { name: 'The Property', color: '#5791FF' },
        { name: 'Rent Comparables', color: '#EC404A' },
        { name: 'Sale Comparables', color: '#AE81E7' },
    ]
    const economic = useSelector((state: ReduxStoreState) => state.operatingAssumptions?.submarketInsights?.economic)
    const submarketInfo = useSelector(
        (state: ReduxStoreState) => state.operatingAssumptions?.submarketInsights?.submarketInfo,
    )

    const economicStory = economic?.stories?.find((story) => story.title === 'Economic Overview' && !!story.description)
    const outlookStory = economic?.stories?.find((story) => story.title === 'Outlook' && !!story.description)
    const economicStoryArr = !!economicStory ? formattedDescription(economicStory.description) : null
    return !!economic && !!submarketInfo ? (
        <>
            <div className={'reportPage page a4-150dpiSpecialOM'}>
                <BasePageLayoutOM title={'Market Overview'} subtitle={props.headerSubtitle}>
                    <ReportSection title={'Economic Overview'} className={'EconomicOverview'} titleSmall={true}>
                        <div className="boxes-wrapper">
                            {economic.highlights?.jobGrowth?.quarter &&
                                economic.highlights?.jobGrowth?.year &&
                                economic.highlights?.jobGrowth?.value && (
                                    <KpiCard
                                        title={`${economic.highlights.jobGrowth.quarter} ${economic.highlights.jobGrowth.year} Job Growth`}
                                        value={economic.highlights.jobGrowth.value * 100}
                                        className="submarketInsights"
                                    />
                                )}

                            {economic.highlights?.householdIncomeGrowth?.quarter &&
                                economic.highlights?.householdIncomeGrowth?.year &&
                                economic.highlights?.householdIncomeGrowth?.value && (
                                    <KpiCard
                                        title={`${economic.highlights.householdIncomeGrowth.quarter} ${economic.highlights.householdIncomeGrowth.year} Income Growth`}
                                        value={economic.highlights.householdIncomeGrowth.value * 100}
                                        className="submarketInsights"
                                    />
                                )}

                            {economic.populationGrowth?.year && economic.populationGrowth?.value && (
                                <KpiCard
                                    title={`${economic.populationGrowth.year} Population Growth`}
                                    value={economic.populationGrowth.value * 100}
                                    className="submarketInsights"
                                />
                            )}
                        </div>

                        <div className="legends">
                            <Legend items={items} />
                        </div>
                        <MapAdapter
                            defaultZoom={11}
                            defaultCenter={submarketInfo.propertyLocation}
                            height={380}
                            defaultOptions={{ styles: theme }}
                        >
                            <Marker
                                position={submarketInfo.propertyLocation}
                                title={'The Property'}
                                icon={'/_img/icons/blue-bar.svg'}
                            />
                            {submarketInfo.comparables
                                .filter((c) => c.isRentComp && c.data?.location)
                                .map((c, i) => {
                                    return (
                                        <Marker
                                            key={i}
                                            position={c.data.location}
                                            title={c.name}
                                            icon={'/_img/icons/red-circle.svg'}
                                        />
                                    )
                                })}
                            {submarketInfo.comparables
                                .filter((c) => c.isSaleComp && c.data?.location)
                                .map((c, i) => {
                                    return (
                                        <Marker
                                            key={i}
                                            position={c.data.location}
                                            title={c.name}
                                            icon={'/_img/icons/purple-circle.svg'}
                                        />
                                    )
                                })}
                            <Polygon
                                paths={submarketInfo.zipGeom}
                                options={{
                                    fillColor: 'rgba(236, 64, 74)',
                                    fillOpacity: 0.15,
                                    strokeWeight: 1,
                                    strokeOpacity: 0.6,
                                    strokeColor: 'rgba(236, 64, 74)',
                                }}
                            />
                        </MapAdapter>
                    </ReportSection>
                </BasePageLayoutOM>
            </div>
            {!!economicStory &&
                economicStoryArr.map((ppp, pppidx) => {
                    return (
                        <div className={'reportPage page a4-150dpiSpecialOM'} key={pppidx}>
                            <BasePageLayoutOM title={'SubMarket Insights'} subtitle={props.headerSubtitle}>
                                <ReportSection
                                    title={pppidx == 0 ? 'Economic Overview' : null}
                                    className={'EconomicOverview'}
                                    titleSmall={true}
                                >
                                    <div className="economic-overview">
                                        {pppidx == 0 && <h6>{economicStory.title}</h6>}
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: ppp,
                                            }}
                                        ></div>
                                    </div>
                                </ReportSection>

                                {pppidx == economicStoryArr.length - 1 &&
                                    !!outlookStory &&
                                    formattedDescription(outlookStory.description).map((ppp, pppidx) => (
                                        <ReportSection
                                            title={'Economic Overview'}
                                            className={'EconomicOverview'}
                                            titleSmall={true}
                                            key={pppidx}
                                        >
                                            <div className="outlook">
                                                {pppidx == 0 && <h6>{outlookStory.title}</h6>}
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: ppp,
                                                    }}
                                                ></div>
                                            </div>
                                        </ReportSection>
                                    ))}
                            </BasePageLayoutOM>
                        </div>
                    )
                })}

            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .economic-overview {
                        text-align: justify;
                    }
                    .outlook {
                        text-align: justify;
                    }
                    .boxes-wrapper {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                    }
                    .other {
                        flex: 1;
                    }
                    .legends {
                        margin-bottom: 10px;
                    }
                    .economic-overview,
                    .outlook {
                        margin-top: 50px;
                    }
                    h6 {
                        font-weight: 700;
                    }
                `}
            </style>
        </>
    ) : (
        <Spinner />
    )
}
