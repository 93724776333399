import React, { useEffect, useMemo, useState } from 'react'

import map from './nmmap.png'

export default function NMAboutUS() {
    return (
        <div className={'NMaboutUsMain'}>
            {/* <h3> About Us</h3>*/}
            <div>
                Northmarq is an industry-leading capital markets resource for commercial real estate investors, offering
                seamless collaboration with top experts in debt, equity, investment sales and loan servicing.
            </div>
            <br />
            <div>
                We combine industry-leading capabilities with an open, flexible structure, enabling our team of seasoned
                professionals to create innovative solutions for clients. Our solid foundation and entrepreneurial
                approach have built an annual transaction volume of over $30 billion, and loan servicing of nearly $70
                billion.
            </div>

            <div className={'imgDiv'}>
                <img src={map} />
            </div>

            <h6 className={'diclaimerTitie'}>DISCLAIMER</h6>
            <div className={'diclaimerText'}>
                This confidential investment package is the property of NorthMarq Capital, LLC, and may be used only by
                parties approved of such delivery. No copies are to be made without the prior written consent of
                NorthMarq Capital, LLC, and no excerpts or summaries thereof may be distributed, reproduced, or included
                in any documents without such consent. While the data in this report was provided by or obtained by
                sources deemed to be reliable, it has not been independently verified by NorthMarq Capital, LLC, and is
                therefore subject to errors, omissions and changes. The accuracy or completeness of its projections or
                assumptions, therefore, cannot be guaranteed. Receipt of this information is considered the same as the
                execution of a letter of non-disclosure and non-circumvention for a period of three years. This
                investment package must be returned as requested by NorthMarq Capital, LLC.
            </div>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    .NMaboutUsMain {
                        background-color: #013da8;
                        margin: 0 auto;
                    }
                    div {
                        text-align: left;
                        color: white;
                    }
                    .imgDiv {
                        margin-top: 150px;
                        margin-bottom: 200px;
                    }
                    .diclaimerTitie {
                        font-size: small;
                    }
                    .diclaimerText {
                        font-size: smaller;
                    }
                `}
            </style>
        </div>
    )
}
