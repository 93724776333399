import React from 'react'

type Props = any
export const CalculatedInfo = (props: Props) => {
    return (
        <div className="d-flex justify-content-end">
            <p className="calc-copy text-right d-inline-flex align-items-center mb-1">
                <i className="calc-icon material-icons-outlined mr-2">bar_chart</i>
                Calculated from more than 2M data points
            </p>
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .calc-copy {
                    font-size: 0.8rem;
                    color: $hint-text-color;
                }
                .calc-icon {
                    font-size: 1.06rem;
                    color: $border;
                }
            `}</style>
        </div>
    )
}
