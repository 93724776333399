import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'

type Props = {
    apiKey: string
    accountId?: number
    projectId?: number
    lender?: any
    narrativeId?: any
}

export function GoogleAnalytics(props: Props) {
    const { accountId, apiKey, projectId, lender, narrativeId } = props
    const router = useRouter()
    let section = ''
    try {
        const path = router.asPath.split('/')
        section = path[path.length - 1]
        if (section.includes('#')) {
            const parts = section.split('#')
            section = parts[0]
        }
    } catch (e) {
        console.log(e.message)
    }
    useEffect(() => {
        if (window.gtag) window.gtag('config', apiKey, { page_path: window.location.pathname + window.location.search })
    }, [router.asPath])

    let gaConfig = ''
    const gaProps = []
    if (section) {
        gaProps.push(`'section': '${section}'`)
    }
    if (lender?.accountid) {
        gaProps.push(`'lender_id': ${lender.accountid}`)
    }
    if (accountId) {
        gaProps.push(`'account_id': ${accountId}`)
    }
    if (narrativeId) {
        gaProps.push(`'narrativeI_d': ${narrativeId}`)
    }
    if (projectId) {
        gaProps.push(`'project_id': ${projectId}`)
    }
    if (gaProps.length > 0) {
        gaConfig = `gtag('set', 
                        {
                           ${gaProps.join(',\n')}
                        });\n`
    }
    return (
        <Head>
            <script async={true} src={`https://www.googletagmanager.com/gtag/js?id=${apiKey}`} />
            <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    __html: `
                         window.dataLayer = window.dataLayer || [];
                        
                        function gtag(){dataLayer.push(arguments);}
                        
                        gtag('js', new Date());
                         
                        ${gaConfig}
                        gtag('config', '${apiKey}', { 'transport_type': 'beacon'});
                        gtag('config', '${apiKey}',
                        {
                            'custom_map': {
                                'dimension1': 'account_id',
                                'dimension2': 'project_id',
                                'dimension3': 'lender_id',
                                'dimension4': 'section',
                                'dimension5': 'user_id',
                                'dimension6': 'user_name',
                                'dimension7': 'comparable_id',
                        }
    });
                    `,
                }}
            />
        </Head>
    )
}
