import React, { useEffect, useMemo, useState } from 'react'
import Logo from '../../../../theme/dealnarrative/icons/NorthMARQ logo.png'
import dateformat from 'dateformat'
import SeecaresLogo from '../../../../theme/dealnarrative/icons/Seecares logo.png'
import { DoRerenderPagesByPDFSize } from './ReportConfig'
import { IMAGES } from '../../../../utils/constants/assets'
import { ReduxHelper } from '../../../../store'

type Props = {
    className?: string
    title?: string
    subtitle?: string
    children?: React.ReactNode
}

export default function BasePageLayoutOM(props: Props) {
    const { className, title, subtitle, children } = props
    const showHeaderAndFooter = true
    const [accountlogo, setLogo] = useState({ alt: '', src: '' })
    useEffect(() => {
        // init google analytics
        const accountLogoUrl = localStorage?.getItem('accountLogoUrl')

        if (accountLogoUrl && accountLogoUrl !== 'null') {
            setLogo(() => ({
                alt: localStorage.getItem('accountName') || '',
                src: '/v1/' + accountLogoUrl || '',
            }))
        } else {
            setLogo(() => ({
                alt: 'Seecares',
                src: IMAGES.SEECARES_LOGO,
            }))
        }
        //setUsername(localStorage.getItem('username') || '')
    }, [])

    const [isNM, setIsNM] = useState(false)
    useEffect(() => {
        if (localStorage) {
            const accountName = localStorage.getItem('accountName')
            if (accountName?.toLowerCase().indexOf('northmarq') > -1) setIsNM(true)
            if (accountName?.toLowerCase().indexOf('Kobi test') > -1) setIsNM(true)
        }
    }, [])
    const imgStyle: any = {}
    if (!isNM) {
        imgStyle.marginTop = '20px'
    }
    return (
        <div
            className={`BasePageLayout d-flex flex-column ${className || ''}`}
            src-title={title}
            src-subtitle={subtitle}
            src-class={className || ''}
        >
            {showHeaderAndFooter && (
                <div className={'pageHeader'}>
                    <div className={'logoOverlay'}>
                        <img
                            src={isNM ? Logo : accountlogo.src || IMAGES.SEECARES_LOGO}
                            alt={isNM ? 'NorthMARQ logo' : accountlogo.alt}
                            style={imgStyle}
                        />
                    </div>
                    <h1>{title}</h1>
                    <h2>{subtitle}</h2>
                </div>
            )}
            <div className={'pageContent'}>
                <div className={'content'}>{children}</div>
            </div>
            {showHeaderAndFooter && (
                <div className={'pageFooter dateMention d-flex justify-content-between'}>
                    <div className={'date'}>{`Report generated ${dateformat(new Date(), 'mmmm dd, yyyy')}`}</div>
                    <div className={'poweredBy'}>
                        Powered by <img src={SeecaresLogo} alt={'Seecares Logo'} />
                    </div>
                </div>
            )}

            {/*language=SCSS*/}
            <style jsx>
                {`
                    .content {
                        min-height: 1500px;
                    }
                `}
            </style>
        </div>
    )
}
