import React from 'react'
import dynamic from 'next/dynamic'
import { ApexOptions } from 'apexcharts'
// eslint-disable-next-line @typescript-eslint/naming-convention
const Chart = dynamic(() => import('react-apexcharts'), {
    ssr: false,
})

export function ApexBarChart(props: { labels: any[]; values: any[]; height?: number }) {
    const chartParams = {
        seriesTitle: '',
        yAxisTitle: '',
        valuePrefix: '',
        valueSuffix: '',
    }

    const options = {
        series: [
            {
                name: chartParams.seriesTitle,
                data: props.values,
            },
        ],
        chart: {
            type: 'bar' as any,
            height: props.height ? props.height : 350,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: props.labels,
        },
        yaxis: {
            title: {
                text: chartParams.yAxisTitle,
                style: {
                    color: '#fff',
                },
            },
            decimalsInFloat: 0,
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            theme: 'dark',
            y: {
                formatter: function (val) {
                    return chartParams.valuePrefix + ' ' + val + ' ' + chartParams.valueSuffix
                },
            },
        },
    }

    return <Chart options={options as ApexOptions} series={options.series} type="bar" width="100%" />
}
