import React from 'react'
import { AreaTableRow } from '../../../../generated/graphql'
import { parseAreaInformationFieldName } from '../../../../utils'
import numeral from 'numeral'

type Props = {
    tableContent: AreaTableRow[]
    title: string
    visible: boolean
    total: number
}

export const AreaInformationContentTable = (props: Props) => {
    const { tableContent, title, total, visible } = props

    const getValue = (percents: number) => {
        return numeral((percents * total) / 100).format('0,0')
    }

    if (visible === false) {
        return null
    }

    return (
        <table className="table table-borderless table-striped table--smallmobile animatedin in stop">
            <thead>
                <tr>
                    <th>{title}</th>
                    <th />
                    <th className="text-right" />
                </tr>
            </thead>
            <tbody>
                {tableContent.map((row, index) => {
                    return (
                        <tr key={index}>
                            <td>{parseAreaInformationFieldName(row.key)}</td>
                            <td>{getValue(row.value)}</td>
                            <td className="text-right font-weight-bold">{row.value} %</td>
                        </tr>
                    )
                })}
            </tbody>
            {/*language=scss*/}
            <style jsx>{`
                .table {
                    color: white;
                    border: 1px solid #1fb6dc;
                    tr {
                        display: flex;
                        justify-content: center;
                        td,
                        th {
                            min-width: 90px;
                            &:nth-child(1) {
                                flex-grow: 1;
                            }
                        }
                    }
                }
            `}</style>
        </table>
    )
}
