import config from './config'

// Convenience function to build the temporary url of locally hosted images. Images should be stored on a hosting platform and
// app should have the complete filename
const getImgPath = (basename, projectId) => {
    if (basename.startsWith('https://') || basename.startsWith('http://')) return basename
    return `${config.NODE_API}/api/v1/uploaded_files/${projectId}/dn/uploads/${basename}`
}

export default getImgPath
