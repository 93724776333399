import React from 'react'
import dynamic from 'next/dynamic'
import { ApexOptions } from 'apexcharts'
// eslint-disable-next-line @typescript-eslint/naming-convention
const Chart = dynamic(() => import('react-apexcharts'), {
    ssr: false,
})

type Props = {
    values: Array<number>
    titles: Array<string>
}

export const ApexBarHorizontalChart = (props: Props) => {
    const options = {
        chart: {
            type: 'bar' as any,
            fontFamily: 'Poppins, Helvetica, Arial, sans-serif',
            height: 650,
            toolbar: {
                show: false,
            },
        },
        colors: ['#1FB6DC', '#2259D9', '#28D2B9'],
        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    position: 'top',
                },
            },
        },
        series: [
            {
                name: '',
                data: props.values,
            },
        ],
        tooltip: {
            theme: 'dark',
            x: {
                formatter: function (val) {
                    return val
                },
            },
            y: {
                formatter: function (val) {
                    return val + '%'
                },
            },
        },
        grid: {
            yaxis: {
                lines: {
                    show: false,
                },
            },
        },
        xaxis: {
            lines: {
                show: false,
            },
            labels: {
                style: {
                    colors: ['#fff'],
                },
                formatter: function (val) {
                    return val + '%'
                },
            },
            categories: props.titles,
        },
        yaxis: {
            lines: {
                show: false,
            },
            labels: {
                style: {
                    colors: '#fff',
                    fontSize: '13px',
                },
                trim: false,
                maxWidth: 350,
                minWidth: 100,
            },
        },
        responsive: [
            {
                breakpoint: 767,
                options: {
                    chart: {
                        height: 600,
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                        },
                    },
                    yaxis: {
                        labels: {
                            maxWidth: 300,
                            minWidth: 0,
                            formatter: function (val) {
                                return val + '%'
                            },
                        },
                    },
                    xaxis: {
                        labels: {
                            rotate: -90,
                            minHeight: 200,
                            maxHeight: 300,
                            formatter: function (val) {
                                return val
                            },
                        },
                    },
                },
            },
        ],
    }

    return <Chart options={options as ApexOptions} series={options.series} type="bar" width="100%" />
}
