import { css } from 'styled-jsx/css'
/*language=SCSS*/
export const miniSiteGlobalStyles = css.global`
    @font-face {
        font-family: 'seeicon';
        src: url('/_css/expose/webfonts/seeicon5a6d.ttf') format('truetype'),
            url('/_css/expose/webfonts/seeicon5a6d.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    .expose-wrapper {
        /* ---- Theme Variables ---- */

        /* Font */
        /* Colors */
        /* Transitions */
        /* Element radius */
        /*---- General - Theme Styles ----*/
        @font-face {
            font-family: 'Larsseit';
            src: url('/_css/expose/webfonts/Larsseit-Light.woff') format('woff'),
                url('/_css/expose/webfonts/Larsseit-Light.woff2') format('woff2');
            font-weight: 300;
            font-style: normal;
        }
        @font-face {
            font-family: 'Larsseit';
            src: url('/_css/expose/webfonts/Larsseit-Bold.woff') format('woff'),
                url('/_css/expose/webfonts/Larsseit-Bold.woff2') format('woff2');
            font-weight: 700;
            font-style: normal;
        }
        p {
            line-height: 1.65em;
            font-weight: 400;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: Poppins, Arial, Helvetica Neue, Helvetica, sans-serif;
            font-weight: 700;
        }

        table {
            width: 100%;
        }

        a {
            color: #1fb6dc;
            text-decoration: none !important;
        }

        a,
        .btn {
            -webkit-transition: all 0.2s linear;
            transition: all 0.2s linear;
        }

        a:hover,
        .btn:hover {
            text-decoration: none !important;
        }

        a:focus,
        .btn:focus {
            outline: none;
            text-decoration: none !important;
        }

        .highlightsrow {
            flex-direction: row;
            flex-wrap: inherit;
        }

        .highlightstableEven {
            margin-right: 80px;
        }
        .highlightstable {
            margin-bottom: 40px;
        }

        figcaption {
            padding-top: 8px;
            font-size: 0.85em;
            text-align: center;
            max-width: 250px;
        }

        /* Helpers */
        .overlay {
            background-color: rgba(36, 40, 49, 0.75);
            position: fixed;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            visibility: hidden;
            z-index: 40;
            -webkit-transition: 0.3s all;
            transition: 0.3s all;
        }

        .notopmargin {
            margin-top: 0 !important;
        }

        .nobottommargin {
            margin-bottom: 0 !important;
        }

        .notoppadding {
            padding-top: 0 !important;
        }

        .noborder {
            border: none !important;
        }

        .notopborder {
            border-top: none !important;
        }

        .hidden-block {
            display: none;
        }

        .delimited {
            margin-bottom: 20px;
        }

        .distancer {
            height: 25px;
        }

        .distancer.distancer--short {
            height: 12px;
        }

        .distancer.distancer--long {
            height: 60px;
        }

        .grey {
            color: rgba(36, 40, 49, 0.85);
        }

        .greybg {
            background-color: #ecedef;
        }

        .intro {
            font-size: 0.9em;
            color: rgba(36, 40, 49, 0.85);
            line-height: 1.4em;
        }

        .badge {
            font-weight: 400;
            border-radius: 3px;
            padding: 4px 8px;
        }

        .badge.badge-success {
            background-color: rgba(0, 200, 83, 0.12);
            color: #00c853;
        }

        .badge.badge-danger {
            background-color: rgba(242, 71, 56, 0.12);
            color: #f24738;
        }

        .picto-confirmation {
            width: 70px !important;
            height: auto !important;
        }

        .avatar {
            display: inline-block;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            background-color: #ecedef;
        }

        .underline {
            position: relative;
        }

        .underline::after {
            content: '';
            display: inline-block;
            position: absolute;
            left: -5px;
            right: -5px;
            bottom: 8px;
            height: 30%;
            background-color: rgba(31, 182, 220, 0.15);
            -webkit-transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
        }

        .animatedin {
            opacity: 0;
            -webkit-transform: translateY(18px);
            transform: translateY(18px);
            -webkit-transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
            transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }

        .animatedin.in {
            opacity: 1;
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }

        .animatedin.stop {
            -webkit-transform: none;
            transform: none;
        }

        .links-grid a {
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            margin-bottom: 12px;
        }

        .links-grid a .seeicon-arrow {
            float: right;
            margin-left: auto;
            font-size: 0.5em;
        }

        /* Dropdown Menu */
        .dropdown-menu {
            padding: 0;
            margin: 0;
            border: 1px solid #d9dbdf;
            border: none;
            border-radius: 0;
            -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.125);
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.125);
            font-size: 0.92rem;
        }

        .dropdown-menu > li > a {
            padding: 12px 28px;
            background-color: #32bfe2;
            color: #fff;
        }

        .dropdown-menu > li > a:hover {
            background-color: #49c6e5;
            color: #fff;
        }

        .dropdown-menu > li.divider {
            margin: 0;
        }

        .dropdown-menu.dropdown-menu--withicons > li > a {
            padding-right: 60px;
            position: relative;
        }

        .dropdown-menu.dropdown-menu--withicons > li > a [class^='icon-'] {
            position: absolute;
            right: 26px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            opacity: 0.35;
        }

        .dropdown-menu.dropdown-menu--animated {
            display: block;
            visibility: hidden;
            opacity: 0;
            -webkit-transform-origin: top center;
            transform-origin: top center;
            -webkit-transform: scale(0.94) translateY(20px);
            transform: scale(0.94) translateY(20px);
        }

        .show > .dropdown-menu:not(.animated) {
            display: block;
        }

        .show > .dropdown-menu--animated {
            -webkit-transition: 0.2s ease-out;
            transition: 0.2s ease-out;
            visibility: visible;
            opacity: 1;
            -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
        }

        /* Modal */
        .modal .close {
            position: absolute;
            right: 0;
            top: 0;
            font-size: 0.85em;
            margin: 0;
            -webkit-transition: 0.2s all;
            transition: 0.2s all;
            outline: none !important;
        }

        .modal .close i {
            -webkit-transition: 0.2s all;
            transition: 0.2s all;
            display: inline-block;
        }

        .modal .close:hover i {
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
        }

        .modal-content {
            border: none;
            border-radius: 3px;
            position: relative;
        }

        .modal-header {
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            border-bottom: none;
            position: relative;
        }

        .modal-title {
            text-align: center;
            font-size: 0.9em;
            -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
        }

        .modal__inner {
            max-width: 240px;
            margin: 0 auto;
        }

        /* Buttons */
        .btn {
            -webkit-transition: all 0.2s linear;
            transition: all 0.2s linear;
            text-decoration: none;
            border: none;
            border-radius: 0;
            padding: 16px 32px;
            font-size: 13px;
            line-height: 1em;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 2px;
            color: #fff;
        }

        .btn:hover {
            text-decoration: none;
            border: none;
        }

        .btn:focus {
            outline: none !important;
        }

        .btn.btn-primary,
        .btn.btn-primary:focus {
            background-color: #fff;
            color: #000;
            position: relative;
            overflow: hidden;
        }

        .btn.btn-primary span,
        .btn.btn-primary:focus span {
            display: inline-block;
            -webkit-transition: all 350ms cubic-bezier(0.215, 0.61, 0.355, 1);
            transition: all 350ms cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .btn.btn-primary::after,
        .btn.btn-primary:focus::after {
            content: ${`"\\e900"`};
            font-family: 'seeicon';
            display: inline-block;
            position: absolute;
            right: -30px;
            top: 38%;
            -webkit-transition: all 550ms cubic-bezier(0.215, 0.61, 0.355, 1);
            transition: all 550ms cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .btn.btn-primary:hover,
        .btn.btn-primary:focus:hover {
            background-color: #fff;
            color: #000;
            -webkit-transition-delay: 0.1s;
            transition-delay: 0.1s;
        }

        .btn.btn-primary:hover span,
        .btn.btn-primary:focus:hover span {
            -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
            -webkit-transition-delay: 0.1s;
            transition-delay: 0.1s;
        }

        .btn.btn-primary:hover::after,
        .btn.btn-primary:focus:hover::after {
            right: 16px;
        }

        .btn.btn-outline,
        .btn.btn-outline:focus {
            background-color: transparent;
            -webkit-box-shadow: inset 0 0 0 2px #fff;
            box-shadow: inset 0 0 0 2px #fff;
            color: #fff;
        }

        .btn.btn-outline:hover,
        .btn.btn-outline:focus:hover {
            background-color: #fff;
            color: #242831;
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        .btn.btn--wide {
            min-width: 300px;
        }

        .btn-default {
            background-color: rgba(255, 255, 255, 0.35);
            color: #242831;
        }

        .btn-default:hover {
            background-color: #fff;
            color: #1fb6dc;
        }

        .btn-text {
            border-bottom: 1px solid rgba(31, 182, 220, 0.35);
        }

        .btn-text:hover {
            color: #242831;
            border-bottom-color: transparent;
        }

        .btn-text-white {
            color: #fff;
            border-bottom: 1px solid rgba(255, 255, 255, 0.35);
        }

        .btn-text-white:hover {
            color: #1fb6dc;
            border-bottom-color: transparent;
        }

        .text-muted .btn-text-white {
            color: rgba(255, 255, 255, 0.55);
        }

        .text-muted .btn-text-white:hover {
            color: #1fb6dc;
            border-bottom-color: transparent;
        }

        .btn-closemenu {
            display: none;
        }

        .btn--withicon i {
            margin-right: 6px;
            position: relative;
            font-size: 0.9em;
            top: 1px;
        }

        .btn--withlasticon i {
            margin-left: 8px;
            position: relative;
            max-height: 33px;
            top: 1px;
            margin-top: -2px;
            margin-bottom: -2px;
            -webkit-transition: 0.2s ease-out;
            transition: 0.2s ease-out;
            display: inline-block;
        }

        .btn--withlasticon .seeicon-arrow,
        .btn--withlasticon .seeicon-arrow-alt {
            font-size: 0.7em;
            position: relative;
            top: -1px;
        }

        .btn--withlasticon:hover .seeicon-arrow,
        .btn--withlasticon:hover .seeicon-arrow-alt {
            -webkit-transform: translateX(5px);
            transform: translateX(5px);
        }

        /* Table */
        .table > thead > tr > th {
            font-size: 12px;
            font-weight: 400;
            text-transform: uppercase;
            color: rgba(255, 255, 255, 0.45);
            line-height: 1.2em;
        }

        .table > tbody > tr > td {
            line-height: 1.2em;
            border-top-color: rgba(255, 255, 255, 0.12);
        }

        .table .text-large {
            font-size: 1.1em;
        }

        .table-tight > tbody > tr > td {
            padding-top: 9px;
            padding-bottom: 9px;
        }

        .table-striped > tbody > tr:nth-of-type(odd) {
            background-color: rgba(0, 0, 0, 0.15);
        }

        .row-highlight {
            background-color: #1fb6dc !important;
        }

        .row-highlight .text-large {
            font-size: 1.4em;
        }

        .row-highlight > td {
            vertical-align: middle;
        }

        .row-highlight--light {
            background-color: rgba(31, 182, 220, 0.55) !important;
        }

        .row-highlight--light > td {
            border-top: 1px solid #242831;
            vertical-align: middle;
        }

        /* Header & Navbar */
        .site-header {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 10;
        }

        .site-logo {
            width: 150px !important;
        }

        .navbar {
            padding: 0;
        }

        .navbar-nav {
            padding: 40px 0;
        }

        .navbar-nav .nav-link {
            color: #fff;
            font-size: 0.85em;
            font-weight: 700;
        }

        .navbar-nav .nav-link:hover {
            color: #fff;
        }

        .navbar-nav .btn-outline,
        .navbar-nav .btn-outline:focus {
            padding: 15px 24px;
            font-size: 0.85em;
            text-transform: none;
            letter-spacing: 0;
            color: #fff;
            -webkit-box-shadow: inset 0 0 0 2px #fff;
            box-shadow: inset 0 0 0 2px #fff;
        }

        .navbar-nav .btn-outline:hover,
        .navbar-nav .btn-outline:focus:hover {
            background-color: #fff;
            color: #1fb6dc;
        }

        .navbar-nav--right {
            -webkit-transition: all 350ms cubic-bezier(0.215, 0.61, 0.355, 1);
            transition: all 350ms cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .navbar-brand {
            padding: 15px 20px;
            padding-left: 25px;
            text-align: center;
            -webkit-transition: all 350ms cubic-bezier(0.215, 0.61, 0.355, 1);
            transition: all 350ms cubic-bezier(0.215, 0.61, 0.355, 1);
            color: #fff;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }

        .navbar-brand:hover {
            color: #fff;
        }

        .navbar-brand i {
            font-size: 1.5em;
            -webkit-transition: all 350ms cubic-bezier(0.215, 0.61, 0.355, 1);
            transition: all 350ms cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .navbar-brand .navbar-brand__tagline {
            font-size: 12px;
            margin: 0;
            margin-top: 4px;
            margin-left: 12px;
        }

        .btn-closemenu {
            position: absolute;
            right: 0;
            top: 0;
            padding: 20px 26px;
            color: rgba(36, 40, 49, 0.4);
            font-size: 1.2em;
            display: none;
        }

        .btn-closemenu:hover {
            color: rgba(36, 40, 49, 0.8);
        }

        .mobilebar {
            background-color: #242831;
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            height: 50px;
            z-index: 39;
            display: none;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-transition: all 350ms cubic-bezier(0.19, 1, 0.22, 1);
            transition: all 350ms cubic-bezier(0.19, 1, 0.22, 1);
            width: 100%;
        }

        .mobilebar .btn-default {
            padding: 11px 15px;
            font-size: 0.85em;
            letter-spacing: 0;
            text-transform: none;
        }

        .mobilebar .btn-default:hover {
            background-color: #fff;
            color: #1fb6dc;
        }

        .mobilebar__actions {
            padding: 2px;
            padding-right: 20px;
            -webkit-box-flex: 0.7;
            -ms-flex: 0.7;
            flex: 0.7;
        }

        .mobilebar__actions--last {
            text-align: right;
        }

        .mobilebar__branding {
            color: #fff;
        }

        .mobilebar__branding i {
            font-size: 24px;
        }

        .mobilebar__branding img {
            width: 120px;
        }

        .btn-mobilemenu {
            padding: 14px 14px;
            background-color: transparent;
            border: 0;
        }

        .btn-mobilemenu .title {
            color: #fff;
            font-size: 10px;
            font-weight: 700;
            float: left;
            margin-left: 5px;
            -webkit-transition: 0.2s all;
            transition: 0.2s all;
        }

        .btn-mobilemenu .bars {
            float: left;
        }

        .btn-mobilemenu .icon-bar {
            display: block;
            background-color: #fff;
            -webkit-transition: 0.2s all ease-out;
            transition: 0.2s all ease-out;
            width: 28px;
            height: 2px;
        }

        .btn-mobilemenu .icon-bar + .icon-bar {
            margin-top: 6px;
        }

        .btn-mobilemenu .icon-bar.icon-bar2 {
            width: 24px;
        }

        .btn-mobilemenu .icon-bar.icon-bar3 {
            width: 20px;
        }

        .btn-mobilemenu:hover {
            color: #fff;
        }

        .btn-mobilemenu:hover .title {
            color: #fff;
        }

        .btn-mobilemenu:hover .icon-bar {
            background-color: #fff;
        }

        .btn-mobilemenu:hover .title {
            color: #fff;
        }

        .btn-mobilemenu:hover .icon-bar1 {
            -webkit-transform: scale(0.8, 1);
            transform: scale(0.8, 1);
            -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
        }

        .btn-mobilemenu:hover .icon-bar3 {
            -webkit-transform: scale(0.85, 1);
            transform: scale(0.85, 1);
            -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
        }

        /*---- Main Content ----*/
        /* Layout */

        .content {
            padding-left: 250px;
            padding-right: 50px;
        }

        .markPdfPageBreak {
            page-break-after: always !important;
        }

        .markPdfPageBreakBefore {
            page-break-before: always !important;
        }
        .cblock {
            padding-top: 89px;
        }

        .cblock h2 {
            margin-bottom: 35px;
            font-size: 2.2em;
            line-height: 1.15em;
        }

        .cblock h3 {
            font-size: 1.5em;
        }

        .cblock--topdelimited {
            margin-top: 78px;
        }

        .cblock--topline {
            border-top: 1px solid rgba(255, 255, 255, 0.12);
        }

        /* Content Nav */
        .content__nav {
            -webkit-transition: all 500ms cubic-bezier(0.23, 1, 0.32, 1);
            transition: all 500ms cubic-bezier(0.23, 1, 0.32, 1);
            -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            z-index: 10;
            text-align: right;
            padding-bottom: 60px;
        }

        .content__nav.is_stuck {
            padding-bottom: 0;
        }

        .content__nav.is_stuck .content-nav {
            padding: 0 5px;
            margin-right: -5px;
        }

        .content-nav {
            border-bottom: 1px solid #3a3e46;
            font-size: 0.8em;
            -webkit-transition: all 500ms cubic-bezier(0.23, 1, 0.32, 1);
            transition: all 500ms cubic-bezier(0.23, 1, 0.32, 1);
            background-color: #242831;
        }

        .content-nav .nav-item + .nav-item {
            margin-left: 30px;
        }

        .content-nav .nav-link {
            color: rgba(255, 255, 255, 0.55);
            font-weight: 500;
            padding: 14px 0;
            position: relative;
        }

        .content-nav .nav-link::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 100%;
            left: 0;
            width: 0;
            height: 3px;
            background-color: #1fb6dc;
            -webkit-transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
            transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
        }

        .content-nav .nav-link:hover {
            color: rgba(255, 255, 255, 0.9);
        }

        .content-nav .nav-link.active {
            color: #1fb6dc;
        }

        .content-nav .nav-link.active:after {
            width: 100%;
        }

        /* Content */
        .cblock--main {
            padding-top: 43px;
        }

        .cblock--main .cblock__sect {
            border-top: none;
        }

        .cblock__head {
            position: relative;
            margin-bottom: 20px;
            width: 100%;
        }

        .cblock__head h1 {
            font-size: 3.2em;
            line-height: 1.1em;
            margin-bottom: 40px;
        }

        .cblock__head--intro {
            padding-right: 30px;
        }

        .cblock__sect {
            width: 100%;
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: #1fb6dc;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            margin-bottom: 20px;
        }

        .cblock__sect span {
            background-color: #242831;
            padding-top: 3px;
            padding-right: 8px;
            position: relative;
            top: -6px;
        }

        .cblock__subsect {
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: rgba(31, 182, 220, 0.7);
            position: relative;
            margin-top: 55px;
            margin-bottom: 35px;
        }

        .cblock__subsect::before {
            content: '';
            display: inline-block;
            width: 30px;
            height: 1px;
            background-color: rgba(255, 255, 255, 0.1);
            margin-right: 10px;
        }

        .cblock__body {
            width: 100%;
        }

        .cblock__topintro {
            margin-top: 0;
        }

        .cblock__intro {
            font-size: 1.25em;
            line-height: 1.45em;
            color: rgba(255, 255, 255, 0.65);
        }

        .cblock__highlight {
            font-size: 1.35em;
            line-height: 1.55em;
        }

        .hero-img img,
        .cblock__fullimg img {
            width: 100%;
            height: auto;
        }

        .cblock__cta {
            padding-top: 12px;
            text-align: right;
        }

        .box {
            background-color: rgba(0, 0, 0, 0.25);
        }

        .box .cblock__intro {
            color: #fff;
        }

        .box__body {
            padding: 65px 75px;
        }

        .box__twocols {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
        }

        .box__twocols .box__copy {
            padding-right: 50px;
        }

        .box__cta {
            padding-top: 15px;
        }

        .btn-linkedin {
            background-color: rgba(255, 255, 255, 0.15);
            padding-top: 13px;
            color: #fff;
        }

        .btn-linkedin i {
            color: #1fb6dc;
            font-size: 1.3em;
        }

        .boxes--schools {
            display: -ms-grid;
            display: grid;
            -ms-grid-columns: 1fr 1fr 1fr;
            grid-template-columns: 1fr 1fr 1fr;
            -ms-grid-rows: auto;
            grid-template-rows: auto;
            grid-gap: 10px;
        }

        .boxes--schools .box__head {
            padding: 25px 35px;
        }

        .boxes--schools .box__body {
            padding: 25px 35px;
            padding-top: 0;
        }

        .boxes--companies {
            display: -ms-grid;
            display: grid;
            -ms-grid-columns: 1fr 1fr 1fr;
            grid-template-columns: 1fr 1fr 1fr;
            -ms-grid-rows: auto;
            grid-template-rows: auto;
            grid-gap: 10px;
        }

        .boxes--companies .box {
            margin-top: 50px;
            margin-bottom: 15px;
        }

        .boxes--companies .box__img {
            margin-top: -50px;
        }

        .boxes--companies .box__img img {
            width: 90%;
            margin-left: 5%;
            height: 210px; /* change dror */
        }

        .boxes--companies .box__body {
            padding: 25px 35px;
        }

        .boxes--companies h5 {
            margin-bottom: 20px;
        }

        .boxes--companies p {
            color: rgba(255, 255, 255, 0.8);
        }

        .admin-logo {
            position: relative;
        }

        .admin-logo img {
            width: 180px;
            position: absolute;
            right: 20px;
            top: -40px;
        }

        .table-admin > tbody > tr > th {
            text-align: right;
            font-size: 0.9em;
            font-weight: 400;
            opacity: 0.5;
        }

        #zoningdiv th {
            \\twidth: 250px;
        }

        .table-admin > tbody > tr > td {
            font-weight: 700;
        }

        .inline-gallery {
            position: relative;
        }

        .inline-gallery__nav {
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 4;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
        }

        .inline-gallery__nav a {
            background-color: rgba(36, 40, 49, 0.65);
            color: rgba(255, 255, 255, 0.55);
            width: 70px;
            height: 70px;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
        }

        .inline-gallery__nav a:hover {
            background-color: rgba(36, 40, 49, 0.85);
            color: #fff;
        }

        .inline-gallery__nav .gall-prev {
            margin-right: 1px;
        }

        .inline-gallery__nav .gall-prev i {
            display: inline-block;
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }

        .inline-gallery__slide img {
            width: 100%;
            /*height: auto;  */ /*change dror*/
            height: 700px;
        }

        /*change dror*/

        .amenties-gallery__slide img {
            width: 175px;
            /* height: auto;*/ /*change dror*/
            height: 130px;
        }

        .inline-gallery__thumbs {
            margin-top: 12px;
            margin-bottom: 90px;
        }

        .inline-gallery__thumbs .inline-gallery__slide {
            -webkit-box-shadow: 0 0 0 2px #242831;
            box-shadow: 0 0 0 2px #242831;
            -webkit-transition: 0.2s all;
            transition: 0.2s all;
            opacity: 0.45;
            position: relative;
        }

        .inline-gallery__thumbs .inline-gallery__slide:focus {
            outline: none;
        }

        .inline-gallery__thumbs .inline-gallery__slide:hover {
            opacity: 0.65;
            cursor: pointer;
        }

        .inline-gallery__thumbs .inline-gallery__slide.slick-current {
            opacity: 1;
            z-index: 2;
        }

        /* Stats grid */
        .grid--stats {
            display: -ms-grid;
            display: grid;
            -ms-grid-columns: 1fr 1fr;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10px;
        }

        .grid__item {
            background-color: rgba(0, 0, 0, 0.2);
            padding: 20px 25px;
        }

        .grid__item p {
            margin: 0;
            line-height: 1.2em;
        }

        .grid__item .grid__head {
            font-weight: 700;
            margin-bottom: 8px;
            font-size: 1.2em;
        }

        .grid__item .grid__intro {
            font-size: 0.8em;
            opacity: 0.5;
            margin-bottom: 15px;
            min-height: 30px;
        }

        .grid__item .stat-val {
            font-size: 1.8em;
        }

        .grid__item .small {
            font-size: 0.55em;
            line-height: 1.1em;
        }

        .grid__item .stacked {
            display: block;
        }

        .grid__item .grid__link {
            display: block;
            position: relative;
        }

        .grid__item .grid__link::after {
            content: ${`"\\e901"`};
            font-family: 'seeicon';
            position: absolute;
            right: 0;
            top: 25px;
            opacity: 0.35;
            color: #fff;
            -webkit-transition: 0.25s ease-out;
            transition: 0.25s ease-out;
        }

        .grid__item .grid__link:hover {
            opacity: 0.8;
        }

        .grid__item .grid__link:hover::after {
            -webkit-transform: translateX(4px);
            transform: translateX(4px);
        }

        .grid__item--large .stat-val {
            font-size: 2.1em;
        }

        .grid__item--large .stat-desc {
            font-size: 0.9em;
            margin-top: 6px;
        }

        .grid__item--largest .stat-val {
            font-size: 2.4em;
        }

        .grid__item--largest .stat-desc {
            font-size: 0.9em;
            margin-top: 6px;
        }

        .grid__item--full {
            -ms-grid-column: 1;
            grid-column-start: 1;
            grid-column-end: 3;
        }

        .grid__item--full .stat-val {
            font-size: 2.6em !important;
        }

        .grid--dualstat .d-flex > div {
            padding-right: 20px;
        }

        .grid--dualstat .d-flex > div:last-child {
            border-left: 1px solid rgba(255, 255, 255, 0.1);
            padding-left: 20px;
            padding-right: 0;
            -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
        }

        .grid--companies {
            display: -ms-grid;
            display: grid;
            -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-gap: 5px;
        }

        .grid--companies .grid__item {
            padding: 15px;
            background-color: transparent;
        }

        .grid--companies .grid__item img {
            width: 100%;
            height: auto;
            -webkit-transition: 0.2s all;
            transition: 0.2s all;
            opacity: 0.85;
        }

        .grid--companies .grid__item:hover img {
            opacity: 1;
        }

        .grid--employment {
            display: -ms-grid;
            display: grid;
            -ms-grid-columns: 1fr 1fr;
            grid-template-columns: 1fr 1fr;
            grid-gap: 5px;
        }

        .grid--employment-alt {
            display: -ms-grid;
            display: grid;
            -ms-grid-columns: 1fr 1fr;
            grid-template-columns: 1fr 1fr;
            grid-gap: 5px;
        }

        .grid--employment-alt .stacked {
            margin-bottom: 12px;
        }

        .grid--imageslist {
            -ms-grid-columns: 1fr;
            grid-template-columns: 1fr;
        }

        .grid--imageslist .grid__item {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
        }

        .grid--imageslist .grid__stats {
            width: 200px;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            -ms-flex-item-align: start;
            align-self: flex-start;
        }

        .grid--imageslist .grid__content {
            -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            padding-left: 25px;
            padding-right: 25px;
        }

        .grid--imageslist .grid__img {
            margin: -20px -25px;
            margin-right: 0;
        }

        .grid--imageslist .grid__img img {
            width: 130px;
            /*height: auto;*/ /*change dror*/
            height: 100px;
        }

        .grid--property {
            -ms-grid-columns: 1fr 1fr 1fr 1fr;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            -ms-grid-rows: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
        }

        .grid--property .grid__item .d-flex {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
        }

        .grid--property .grid__item .d-flex .grid__secondary {
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            padding-top: 25px;
            margin-top: 20px;
        }

        .grid--property .grid__item .d-flex .grid__secondary .stat-val {
            font-size: 1.5em;
        }

        .grid--property .grid__item .d-flex .grid__secondary .stat-desc {
            font-size: 0.8em;
        }

        .grid--property .grid__item--large {
            -ms-grid-row: 1;
            -ms-grid-row-span: 2;
            grid-row: 1/3;
        }

        .grid--property .grid__item--large .grid__primary .stat-desc {
            min-height: 35px;
        }

        .grid__item--icon i {
            float: right;
            font-size: 3em;
            margin-top: 5px;
            margin-left: 15px;
            opacity: 0.25;
            line-height: 1em;
        }

        .grid--units {
            display: -ms-grid;
            display: grid;
            -ms-grid-columns: 1fr 1fr 1fr;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 10px;
        }

        .grid--units .stat-desc {
            font-size: 1.1em;
        }

        .grid--amenities {
            display: -ms-grid;
            display: grid;
            -ms-grid-columns: 1fr;
            grid-template-columns: 1fr;
            grid-gap: 10px;
            overflow: hidden;
        }

        .grid--amenities .grid__item {
            padding: 0;
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
        }

        .grid--amenities .grid__img {
            width: 300px;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            overflow: hidden;
            position: relative;
        }

        .grid--amenities .grid__img img {
            width: 100%;
            height: auto;
            /* -webkit-filter: blur(2px);
          filter: blur(2px);*/
            -webkit-transform: scale(1.05);
            transform: scale(1.05);
        }

        .grid--amenities .grid__desc {
            -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding: 45px 80px;
            z-index: 1;
        }

        .grid--amenities .grid__desc h6 {
            color: rgba(114, 215, 233, 0.5);
        }

        .grid--amenities .grid__desc p {
            margin-top: 20px;
            line-height: 1.7em;
        }

        .grid--crime .grid__item .d-flex {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
        }

        .grid--crime .grid__item .d-flex > * {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
        }

        .grid--crime .grid__secondary {
            margin-top: 12px;
            padding-top: 12px;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
        }

        .grid--crime .grid__secondary .stat-val {
            font-size: 1.6em;
            color: rgba(255, 255, 255, 0.4);
        }

        .grid--crime .grid__secondary .stat-desc {
            color: rgba(255, 255, 255, 0.35);
        }

        .grid--demographics {
            display: -ms-grid;
            display: grid;
            -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-gap: 10px;
        }

        .grid--demographics .grid__item {
            text-align: center;
        }

        .grid--demographics .grid__item i {
            font-size: 2.6em;
            opacity: 0.25;
            display: block;
            margin-top: 15px;
            margin-bottom: 12px;
        }

        .grid--proforma {
            -ms-grid-columns: 1fr 1fr 1fr;
            grid-template-columns: 1fr 1fr 1fr;
            margin-bottom: 10px;
        }

        .grid--proforma .grid__item {
            padding: 65px 45px;
        }

        .grid--proforma .stat-val {
            font-size: 2.3em;
            margin-bottom: 12px;
        }

        .grid--proforma .stat-desc {
            font-size: 1.05em;
            margin-top: 25px !important;
        }

        .grid--proforma .stat-head {
            margin-bottom: 7px;
            font-size: 0.85em;
            color: rgba(255, 255, 255, 0.45);
        }

        .grid--proforma .grid__primary .stat-head {
            color: rgba(114, 215, 233, 0.65);
        }

        .grid--proforma .grid__secondary {
            margin-top: 18px;
            padding-top: 18px;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
        }

        .grid--proforma .grid__secondary .stat-val {
            color: #fff;
            font-size: 1.6em;
        }

        .grid--proforma-alt {
            -ms-grid-columns: 1fr 1fr;
            grid-template-columns: 1fr 1fr;
        }

        .grid--proforma-alt .grid__item {
            padding: 55px 50px;
        }

        .grid--proforma-alt .grid__item p {
            line-height: 1.55em;
        }

        .grid--proforma-alt .grid__item .stat-val {
            margin-bottom: 8px;
        }

        .grid--proforma-alt .grid__item .stat-desc {
            font-size: 0.9em;
        }

        .grid--proforma-alt .grid__item--full {
            -ms-grid-column: 1;
            -ms-grid-column-span: 2;
            grid-column: 1/3;
        }

        .grid--proforma-alt .grid__item--dual .d-flex > * {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
        }

        .grid--proforma-alt .grid__item--dual .grid__primary {
            padding-right: 40px;
            border-right: 1px solid rgba(255, 255, 255, 0.1);
            position: relative;
        }

        .grid--proforma-alt .grid__item--dual .grid__primary::after {
            content: ${`"\\e901"`};
            font-family: 'seeicon';
            position: absolute;
            right: 0;
            top: 50%;
            width: 32px;
            height: 32px;
            -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
            background-color: #242831;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            border: 1px solid rgba(255, 255, 255, 0.1);
        }

        .grid--proforma-alt .grid__item--dual .grid__secondary {
            padding-left: 40px;
        }

        .grid__item--caprate p {
            font-size: 1.2em;
        }

        .cap-img {
            width: 200px;
            opacity: 0.5;
            margin-left: 35px;
        }

        .btn-pro-forma {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            letter-spacing: 0;
            text-transform: none;
            color: #fff;
            font-size: 1.1em;
            padding: 28px 25px;
            background-color: transparent;
            -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.15);
            box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.15);
        }

        .btn-pro-forma i {
            font-size: 1.8em;
            display: inline-block;
            margin-right: 25px;
            top: 0;
        }

        .btn-pro-forma .seeicon-arrow {
            font-size: 0.8em;
            margin-left: 12px;
            -webkit-transition: 0.25s ease-out;
            transition: 0.25s ease-out;
        }

        .btn-pro-forma:hover .seeicon-arrow-alt {
            -webkit-transform: translateX(8px);
            transform: translateX(8px);
        }

        .stat-val {
            font-size: 1.5em;
            color: #1fb6dc;
            line-height: 1.2em !important;
        }

        .stat-desc {
            font-size: 0.8em;
            color: rgba(255, 255, 255, 0.65);
            margin-top: 4px !important;
            line-height: 1.3em !important;
        }

        .plan:hover .plan__icon {
            opacity: 0;
        }

        .plan__icon {
            position: absolute;
            right: 0;
            top: 0;
            background-color: rgba(36, 40, 49, 0.4);
            color: rgba(255, 255, 255, 0.65);
            width: 40px;
            height: 40px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            z-index: 10;
            -webkit-transition: 0.2s all;
            transition: 0.2s all;
        }

        .list-stats {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
        }

        .list-stats > li {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            padding-right: 20px;
        }

        .list-stats > li + li {
            border-left: 1px solid rgba(255, 255, 255, 0.1);
            padding-left: 20px;
        }

        .list-stats p {
            margin: 0;
        }

        .list-stats small {
            font-size: 0.5em;
        }

        .list-withicons {
            margin: 0;
        }

        .list-withicons > li {
            position: relative;
            padding-left: 50px;
            margin: 16px 0;
            min-height: 38px;
        }

        .list-withicons .list-picto {
            position: absolute;
            left: 0;
            width: 35px;
            height: 35px;
            background-color: rgba(114, 215, 233, 0.15);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            margin-top: 3px;
            font-size: 1.3em;
            color: #72d7e9;
        }

        .list-transactions > li {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            position: relative;
            padding: 20px 0;
            padding-left: 100px;
        }

        .list-transactions > li:nth-child(even) {
            background-color: rgba(0, 0, 0, 0.2);
        }

        .list-transactions > li:last-child::after {
            display: none;
        }

        .list-transactions > li::before {
            content: '';
            display: inline-block;
            width: 12px;
            height: 12px;
            border: 3px solid #72d7e9;
            position: absolute;
            left: 40px;
            margin-top: 20px;
        }

        .list-transactions > li::after {
            content: '';
            display: inline-block;
            width: 1px;
            background-color: rgba(255, 255, 255, 0.1);
            position: absolute;
            left: 46px;
            top: 45px;
            bottom: -18px;
        }

        .list-transactions > li > * {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            margin: 0;
            padding-right: 15px;
            line-height: 1.25em;
        }

        .list-transactions > li .text-muted {
            font-size: 0.85em;
            display: block;
            margin-bottom: 6px;
        }

        .list-transactions > li .list-transactions__date {
            color: #72d7e9;
            max-width: 150px;
        }

        .list-transactions > li .list-transactions__price {
            text-align: right;
            max-width: 150px;
        }

        ul.list-unstyled.list-transactions:nth-of-type(odd) {
            \\tbackground-color: rgba(0, 0, 0, 0.15);
        }

        .btn-next {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            border: 1px solid rgba(255, 255, 255, 0.25);
            color: #1fb6dc;
            letter-spacing: 0;
            text-transform: none;
            font-size: 1.6em;
            padding: 40px 20px;
            width: 100%;
        }

        .btn-next:hover {
            border: 1px solid #1fb6dc;
            color: #1fb6dc;
        }

        .btn-next:hover i {
            -webkit-transform: translateX(8px);
            transform: translateX(8px);
        }

        .btn-next i {
            font-size: 0.7em;
            margin-left: 10px;
            -webkit-transition: 0.2s ease-out;
            transition: 0.2s ease-out;
            display: inline-block;
        }

        .btn-next__copy {
            -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
        }

        .btn-next__img {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            margin: -10px;
            margin-left: 0;
        }

        .btn-next__img img {
            width: 80px;
            height: auto;
        }

        /* Comparison blocks */
        .blocks {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            margin: 0 -10px;
        }

        .blocks .block {
            width: calc(50% - 20px);
        }

        .block {
            position: relative;
            display: block;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.25);
            margin: 10px;
            margin-top: 140px;
        }

        .block:hover {
            color: #fff;
            background-color: rgba(0, 0, 0, 0.35);
            -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
        }

        .block h5 {
            font-size: 2em;
            line-height: 1.15em;
            margin: 0;
            margin-bottom: 8px;
        }

        .block__img {
            -webkit-transition: 2s ease;
            transition: 2s ease;
            padding: 0 20px;
            margin-top: -140px;
        }

        .block__img img {
            max-width: 460px;
            margin: 0 auto;
            display: block;
            width: 100%;
            height: auto;
        }
        .comparable--block img {
            width: 460px;
            margin-top: -50px;
            margin-right: auto;
            margin-bottom: 0px;
            margin-left: auto;
            display: block;
            height: 350px;
        }

        .block__body {
            padding: 25px;
            padding-bottom: 30px;
            -webkit-transition: 0.4s ease;
            transition: 0.4s ease;
            z-index: 4;
            max-width: 510px;
            margin: 0 auto;
        }

        .block__body .table {
            margin-top: 20px;
        }

        .block__body .table > tbody > tr > td {
            width: 50%;
        }

        .block__body .table > tbody > tr:first-child > td {
            border-top: none;
        }

        .block__body .table > tbody > tr:first-child > td.year {
            border-right: 1px solid rgba(255, 255, 255, 0.1);
        }

        .block__body .table p {
            margin: 0;
            line-height: 1.25em;
        }

        .block__body .table .stat-val {
            font-size: 1.8em;
        }

        .block__foot {
            text-align: right;
        }
        #comparables .block__foot {
            position: absolute;
            bottom: 28px;
            right: 28px;
        }
        #comparables .block__body table {
            margin-bottom: 60px;
        }

        .blocks--contact .block {
            margin-top: 0;
        }

        .blocks--contact .block:hover {
            -webkit-transform: none;
            transform: none;
        }

        .blocks--contact .block__body {
            padding: 35px 50px;
            max-width: inherit;
        }

        .blocks--contact .list-contacts {
            margin: 0;
            font-size: 1.2em;
        }

        .blocks--contact .list-contacts > li {
            margin: 0;
        }

        .blocks--contact h4 {
            margin: 0;
            margin-bottom: 35px;
        }

        .blocks--deals .block__body .table > tbody > tr > td {
            padding-left: 20px;
        }

        .blocks--deals .block__body .table > tbody > tr > td:first-child {
            padding-left: 0;
        }

        .blocks--sponsors .block {
            margin-top: 30px;
        }

        .blocks--sponsors .block:hover {
            -webkit-transform: none;
            transform: none;
            cursor: default;
        }

        .blocks--sponsors .block__img {
            margin-top: -30px;
            margin-bottom: 15px;
            padding: 0 40px;
            text-align: right;
        }

        .blocks--sponsors .block__img img {
            width: auto;
            margin: 0;
            display: inline-block;
            height: 100px;
        }

        .blocks--sponsors .block__body {
            padding-left: 40px;
            padding-right: 40px;
        }

        .person {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
        }

        .person + .person {
            margin-top: 30px;
        }

        .person p {
            font-size: 0.8em;
            line-height: 1.2em;
            text-align: center;
        }

        .person .text-muted {
            opacity: 0.5;
            color: #fff !important;
        }

        .person__img {
            width: 100%;
            object-fit: contain;
        }

        /* Forms */
        .form .form-control {
            -webkit-transition: all 0.2s linear;
            transition: all 0.2s linear;
            font-size: 1em;
            -webkit-box-shadow: none;
            box-shadow: none;
            border-radius: 3px;
            border: 1px solid rgba(36, 40, 49, 0.12);
            height: 38px;
            color: #242831;
            -webkit-box-shadow: 0 2px 6px rgba(36, 40, 49, 0.05);
            box-shadow: 0 2px 6px rgba(36, 40, 49, 0.05);
        }

        .form .form-control:focus {
            border-color: #8798a3;
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        .form .form-control.form-area {
            height: auto;
        }

        .form .form-control::-webkit-input-placeholder {
            color: rgba(36, 40, 49, 0.5);
        }

        .form .form-control::-moz-placeholder {
            color: rgba(36, 40, 49, 0.5);
        }

        .form .form-control:-ms-input-placeholder {
            color: rgba(36, 40, 49, 0.5);
        }

        .form .form-control:-moz-placeholder {
            color: rgba(36, 40, 49, 0.5);
        }

        .form .form-group > label {
            font-weight: 400;
            font-size: 13px;
            color: rgba(36, 40, 49, 0.6);
            margin-bottom: 4px;
        }

        .form .form-group .form__error {
            display: none;
            font-size: 13px;
            padding: 4px 0;
            color: #f24738;
        }

        .form .form-group--line .form-control {
            border: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            border-bottom: 1px solid rgba(36, 40, 49, 0.2);
            border-radius: 0;
        }

        .form .form-group--delimited {
            margin-top: 30px;
        }

        .form .form-group--tight {
            margin-bottom: 5px;
        }

        .form .has-error .form-control {
            border-color: #f24738;
        }

        .form .has-error .form__error {
            display: block;
        }

        .form .help-block {
            font-size: 13px;
            color: rgba(38, 45, 57, 0.55);
            margin-top: 7px;
        }

        .form .form-group--innerlabel {
            position: relative;
        }

        .form .form-group--innerlabel > label {
            position: absolute;
            left: 12px;
            top: 11px;
            font-weight: 700;
            color: #242831;
        }

        .form .form-group--innerlabel .form-control {
            padding-left: 95px;
        }

        .form .form-group--innerlabel .form-control:focus {
            border-bottom-color: rgba(36, 40, 49, 0.5);
        }

        .form .form-group--innerlabel.form-group--line > label {
            left: 0;
        }

        .form .form-group--innerlabel.form-group--line .form-control {
            padding-left: 80px;
        }

        .custom-control-input:focus ~ .custom-control-label::before {
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        /* Sponsors */
        .sponsors-logo {
            width: 200px;
            float: right;
            margin-left: 25px;
            margin-bottom: 25px;
            /* margin-top: 50px;*/
            margin-top: 0px;
        }

        /*---- Footer ----*/
        .site-footer {
            padding-top: 100px;
            padding-bottom: 25px;
            font-size: 0.85em;
            color: rgba(255, 255, 255, 0.45);
        }

        .site-footer .container--cols > .row {
            width: 100%;
        }

        .site-footer p {
            line-height: 1.25em;
        }

        .site-footer__branding {
            text-align: right;
        }

        .site-footer__branding .seeicon-pancho {
            font-size: 2em;
        }

        .site-footer__branding img {
            width: 125px;
        }

        .list-links {
            margin: 15px 0;
        }

        .list-links--links {
            margin-top: -5px;
        }

        .list-links--links a {
            display: block;
            padding: 7px 0;
            color: rgba(255, 255, 255, 0.45);
            line-height: 1.1em;
        }

        .list-links--links a:hover {
            color: #fff;
        }

        .site-footer__copy {
            margin-top: 50px;
            margin-bottom: 0;
        }

        .site-footer__copy a {
            color: rgba(255, 255, 255, 0.5);
            border-bottom: 1px solid rgba(255, 255, 255, 0.25);
        }

        .site-footer__copy a:hover {
            color: #fff;
            border-bottom-color: transparent;
        }

        .site-footer__connect p {
            padding-top: 1px;
            margin-bottom: 3px;
        }

        .list-contacts a {
            display: block;
            color: #fff;
            padding: 8px 0;
            padding-left: 35px;
            line-height: 1.2em;
            position: relative;
        }

        .list-contacts a i {
            position: absolute;
            left: 0;
            opacity: 0.35;
        }

        .list-contacts a:hover {
            color: #1fb6dc;
        }

        /*---- Misc ----*/
        .tooltipster-default .tooltipster-content {
            font-family: Akkurat, 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande',
                sans-serif;
            font-size: 12px;
            line-height: 1.2em;
        }

        .tooltip.top .tooltip-arrow {
            display: none;
        }

        .tooltipster-default {
            border: none;
            background-color: #fff;
            font-size: 14px;
            color: #242831;
            -webkit-box-shadow: 0 2px 8px rgba(36, 40, 49, 0.1);
            box-shadow: 0 2px 8px rgba(36, 40, 49, 0.1);
        }

        .tooltip_content {
            display: none;
        }

        //.apexcharts-yaxis-texts-g text,
        //.apexcharts-xaxis-texts-g text {
        //    fill: #fff;
        //}

        .slick-dots li button {
            -webkit-transition: 0.2s all;
            transition: 0.2s all;
        }

        .slick-dots li button::before {
            -webkit-transition: 0.2s all;
            transition: 0.2s all;
            color: #fff;
            font-size: 13px;
            opacity: 0.15;
        }

        .slick-dots li.slick-active button::before {
            color: #fff;
            opacity: 0.65;
        }

        [class^='seeicon-'],
        [class*=' seeicon-'] {
            /* use !important to prevent issues with browser extensions that change fonts */
            font-family: 'seeicon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .seeicon-security-cam:before {
            content: ${`"\\e91d"`};
        }

        .seeicon-health:before {
            content: ${`"\\e91e"`};
        }

        .seeicon-spreadsheet:before {
            content: ${`"\\e91c"`};
        }

        .seeicon-property-crime:before {
            content: ${`"\\e91a"`};
        }

        .seeicon-violent-crime:before {
            content: ${`"\\e91b"`};
        }

        .seeicon-school:before {
            content: ${`"\\e914"`};
        }

        .seeicon-college:before {
            content: ${`"\\e915"`};
        }

        .seeicon-income:before {
            content: ${`"\\e916"`};
        }

        .seeicon-age:before {
            content: ${`"\\e917"`};
        }

        .seeicon-homeowners:before {
            content: ${`"\\e918"`};
        }

        .seeicon-singleres:before {
            content: ${`"\\e919"`};
        }

        .seeicon-search:before {
            content: ${`"\\e913"`};
        }

        .seeicon-bed1:before {
            content: ${`"\\e910"`};
        }

        .seeicon-bed2:before {
            content: ${`"\\e911"`};
        }
        .seeicon-bed4 {
            display: inline-block;
            width: 48px;
            height: 48px;
            background: url('/internal/webfonts/4bedrooms.svg') no-repeat top left;
            background-size: contain;
        }
        .seeicon-studio:before {
            content: ${`"\\e912"`};
        }

        .seeicon-li:before {
            content: ${`"\\e90f"`};
        }

        .seeicon-apartment:before {
            content: ${`"\\e90d"`};
        }

        .seeicon-shop:before {
            content: ${`"\\e90e"`};
        }

        .seeicon-arrow:before {
            content: ${`"\\e900"`};
        }

        .seeicon-arrow-alt:before {
            content: ${`"\\e901"`};
        }

        .seeicon-dots:before {
            content: ${`"\\e902"`};
        }

        .seeicon-email:before {
            content: ${`"\\e903"`};
        }

        .seeicon-fb:before {
            content: ${`"\\e904"`};
        }

        .seeicon-in:before {
            content: ${`"\\e905"`};
        }

        .seeicon-location:before {
            content: ${`"\\e906"`};
        }

        .seeicon-pancho:before {
            content: ${`"\\e907"`};
        }

        .seeicon-phone:before {
            content: ${`"\\e908"`};
        }

        .seeicon-plus:before {
            content: ${`"\\e909"`};
        }

        .seeicon-tw:before {
            content: ${`"\\e90a"`};
        }

        .seeicon-warning:before {
            content: ${`"\\e90b"`};
        }

        .seeicon-x:before {
            content: ${`"\\e90c"`};
        }

        @media screen and (max-height: 1000px) {
            .site-side__branding {
                padding-bottom: 35px;
            }
        }

        @media screen and (min-width: 1100px) {
            .grid--location {
                -ms-grid-columns: 1fr 1fr;
                grid-template-columns: 1fr 1fr;
            }
            .grid--location .grid__item {
                margin-left: 15px;
                display: -ms-grid;
                display: grid;
                -ms-grid-columns: auto 1fr;
                grid-template-columns: auto 1fr;
                -ms-grid-rows: auto auto;
                grid-template-rows: auto auto;
                margin-left: 25px;
            }
            .grid--location .grid__item .grid__intro {
                min-height: inherit;
                margin-bottom: 0;
            }
            .grid--location .grid__img {
                -ms-grid-row: 1;
                -ms-grid-row-span: 2;
                grid-row: 1/3;
                margin: 0;
                margin-left: -35px;
            }
            .grid--location .grid__stats {
                width: auto;
                padding-left: 25px;
                padding-right: 25px;
                margin-top: 25px;
            }
            .grid--location .grid__content,
            .grid--location .grid__stats {
                padding-left: 25px;
                padding-right: 20px;
            }
        }

        @media only screen and (min-width: 1200px) {
            .box--sideimgright .box__body--imgright .box__img {
                margin-right: -90px;
                -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                order: 2;
                -webkit-box-flex: 0;
                -ms-flex: 0 0 auto;
                flex: 0 0 auto;
            }
        }

        @media only screen and (min-width: 1300px) {
            .grid--large .grid__item {
                padding: 30px 35px;
            }
            .grid--large .stat-val {
                font-size: 2.1em;
            }
            .grid--large .stat-desc {
                font-size: 1em;
                margin-top: 10px !important;
            }
            .content .cblock .container {
                max-width: 1050px;
            }
            .hero-img img {
                width: auto;
                position: absolute;
                left: 50px;
                top: 0;
                right: 0;
                height: 100%;
                -o-object-fit: cover;
                object-fit: cover;
                -o-object-position: center;
                object-position: center;
            }
            .box--sideimgright {
                margin-right: 90px;
            }
            .box--sideimgright .box__body--imgright {
                padding-right: 0;
            }
            .box--sideimgright .box__body--imgright .box__img {
                margin-right: -90px;
                -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                order: 2;
                -webkit-box-flex: 0;
                -ms-flex: 0 0 auto;
                flex: 0 0 auto;
            }
            .box--sideimgright .box__body--imgright .box__img img {
                width: 100%;
                max-width: 250px;
                height: auto;
            }
            .box--sideimgright .box__body--imgright .box__copy {
                -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                order: 1;
            }
            .box--sideimgleft {
                margin-left: 90px;
                padding-bottom: 65px !important;
            }
            .box--sideimgleft .box__body--imgleft {
                padding-left: 0;
            }
            .box--sideimgleft .box__body--imgleft .box__img {
                margin-left: -90px;
                -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                order: 1;
                -webkit-box-flex: 0;
                -ms-flex: 0 0 auto;
                flex: 0 0 auto;
            }
            .box--sideimgleft .box__body--imgleft .box__img img {
                width: 100%;
                max-width: 580px;
                height: auto;
            }
            .box--sideimgleft .box__body--imgleft .box__twocols .box__copy {
                -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                order: 2;
                padding-left: 65px;
                padding-right: 0;
            }
            .site-footer .container {
                max-width: 1050px;
            }
            .links-grid {
                display: -ms-grid;
                display: grid;
                -ms-grid-columns: 1fr 1fr;
                grid-template-columns: 1fr 1fr;
                grid-gap: 20px;
            }
            .links-grid a {
                margin: 0;
            }
        }

        @media only screen and (max-width: 1300px) {
            .box--sideimgright .box__body--imgright .box__img img {
                max-width: 380px;
            }
            .box--sideimgleft .box__body--imgleft .box__img img {
                max-width: 420px;
            }
            .highlightsrow {
                \\tflex-direction: column;
                flex-wrap: inherit;
            }

            .highlightstableEven {
            }
        }

        @media only screen and (max-width: 1300px) {
            \\t.box--portfolio .box__img img {
                max-width: inherit !important;
                width: 100%;
            }
            .box--portfolio .box__img {
                top: -50px;
                position: relative;
                margin-bottom: -20px;
            }
            .box__twocols.protfoliobox {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
            }
        }

        @media only screen and (max-width: 1200px) {
            .content {
                padding-right: 35px;
                padding-left: 275px;
            }
            .cblock h1 {
                font-size: 2.8em;
            }
            .cblock h2 {
                font-size: 2.2em;
            }
            .hero-img {
                margin-bottom: 10px;
            }
            .block h5 {
                font-size: 1.6em;
            }
            .block .table {
                margin-top: 15px;
                font-size: 0.9em;
            }
            .block .table .stat-val {
                font-size: 1.4em;
            }
            .block__copy {
                font-size: 0.9em;
            }
            .block__head p {
                font-size: 0.9em;
                line-height: 1.3em;
            }
            .blocks--sponsors .block__body {
                padding-left: 20px;
                padding-right: 20px;
            }
            .site-footer {
                font-size: 0.8em;
            }
            .site-footer .site-footer__links {
                width: 30% !important;
            }
            .site-footer .site-footer__connect {
                width: 40% !important;
            }
            .site-footer__branding {
                padding-top: 6px;
            }
            .site-footer__branding .seeicon-pancho {
                font-size: 1.7em;
            }
            .grid--property {
                -ms-grid-columns: 1fr 1fr 1fr;
                grid-template-columns: 1fr 1fr 1fr;
                -ms-grid-rows: 1fr 1fr;
                grid-template-rows: 1fr 1fr;
            }
            .box__twocols {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
            }
            .box--principle {
                margin-top: 70px !important;
            }
            .box--principle .box__body--imgright {
                padding-top: 0;
            }
            .box--principle .box__img {
                top: -50px;
                position: relative;
                margin-bottom: -40px;
            }
            .box__twocols .box__copy {
                padding-right: 0;
            }
            .box--portfolio {
                margin-top: 70px !important;
            }
            .box--portfolio .box__body--imgleft {
                padding-top: 0;
            }
            .box--portfolio .box__img {
                top: -50px;
                position: relative;
                margin-bottom: -20px;
            }
            .box--portfolio .box__img img {
                max-width: inherit !important;
                width: 100%;
            }
            .boxes--companies {
                -ms-grid-columns: 1fr 1fr;
                grid-template-columns: 1fr 1fr;
            }
            .grid--demographics .grid__item .stat-val {
                font-size: 1.7em;
            }
            .grid--proforma .grid__item {
                padding: 50px 30px;
            }
            .grid--proforma-alt {
                -ms-grid-columns: 1fr;
                grid-template-columns: 1fr;
            }
            .grid--proforma-alt .grid__item--full {
                grid-column: auto;
            }
        }

        @media only screen and (max-width: 1100px) {
            .blocks--contact .block {
                width: calc(100% - 20px);
            }
        }

        @media only screen and (max-width: 992px) {
            .site-side {
                width: 200px;
            }
            .site-side__branding {
                padding-top: 35px;
            }
            .site-side__branding .seeicon-pancho {
                font-size: 2em;
            }
            .content {
                padding-right: 25px;
                padding-left: 225px;
            }
            .content__nav {
                display: none;
            }
            .cblock h1 {
                font-size: 3em;
                margin-bottom: 25px;
            }
            .cblock h2 {
                font-size: 1.9em;
            }
            .cblock--main {
                padding-top: 35px;
            }
            .cblock__sect {
                font-size: 11px;
            }
            .cblock__highlight {
                font-size: 1.2em;
            }
            .blocks {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
            }
            .blocks .block {
                width: calc(100% - 20px);
            }
            .block .table .stat-val {
                font-size: 1.8em;
            }
            .grid__item .small {
                font-size: 13px;
            }
            .grid--property {
                -ms-grid-columns: 1fr 1fr;
                grid-template-columns: 1fr 1fr;
            }
            .grid--property .grid__item--large {
                grid-row: inherit;
                -ms-grid-column: 1;
                -ms-grid-column-span: 2;
                grid-column: 1/3;
            }
            .grid--property .grid__item--large .d-flex {
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-direction: row;
                flex-direction: row;
            }
            .grid--property .grid__item--large .d-flex > * {
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
            }
            .grid--property .grid__item--large .d-flex .grid__primary {
                padding-right: 25px;
            }
            .grid--property .grid__item--large .d-flex .grid__secondary {
                border-top: none;
                padding-top: 0;
                margin-top: 0;
                border-left: 1px solid rgba(255, 255, 255, 0.1);
                padding-left: 25px;
            }
            .btn-next {
                font-size: 1.2em;
            }
            .site-footer {
                padding-top: 50px;
            }
            .site-footer .site-footer__links,
            .site-footer .site-footer__connect {
                width: 100% !important;
            }
            .site-footer__links {
                margin-bottom: 20px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
            }
            .site-footer__links > * {
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
            }
            .site-footer__branding {
                text-align: left;
                padding-top: 25px;
            }
            .site-footer__branding .seeicon-pancho {
                font-size: 2em;
            }
            .site-footer__copy {
                font-size: 0.85em;
            }
            .sponsors-logo {
                width: 120px;
            }
            .admin-logo img {
                width: 120px;
            }
            .box__body {
                padding: 35px 45px;
            }
            .box--sideimgright .box__body--imgright .box__img img {
                max-width: 320px;
            }
            .grid--units {
                -ms-grid-columns: 1fr 1fr;
                grid-template-columns: 1fr 1fr;
            }
            .table--smallmobile > thead > tr > th {
                font-size: 0.75em;
            }
            .table--smallmobile > tbody > tr > td {
                font-size: 0.9em !important;
                padding-top: 0.75rem;
                padding-bottom: 0.75rem;
            }
            .grid--amenities {
                -ms-grid-columns: 1fr 1fr;
                grid-template-columns: 1fr 1fr;
            }
            .grid--amenities .grid__item {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
            }
            .grid--amenities .grid__img {
                width: auto;
            }
            .grid--amenities .grid__desc {
                padding: 35px 30px;
                -webkit-box-align: start;
                -ms-flex-align: start;
                align-items: flex-start;
            }
            .list-transactions {
                font-size: 0.9em;
            }
            .inline-gallery__nav a {
                width: 60px;
                height: 60px;
            }
            .table-admin > tbody > tr > td {
                padding-top: 15px;
            }
            .grid--companies {
                -ms-grid-columns: 1fr 1fr 1fr;
                grid-template-columns: 1fr 1fr 1fr;
            }
            .grid--demographics {
                -ms-grid-columns: 1fr 1fr 1fr;
                grid-template-columns: 1fr 1fr 1fr;
            }
            .grid--demographics .grid__item {
                padding-left: 20px;
                padding-right: 20px;
            }
            .grid--demographics .stat-desc {
                font-size: 0.8em;
            }
            .boxes--schools {
                -ms-grid-columns: 1fr;
                grid-template-columns: 1fr;
            }
            .grid--imageslist .grid__item {
                display: -ms-grid;
                display: grid;
                -ms-grid-columns: auto 1fr;
                grid-template-columns: auto 1fr;
                -ms-grid-rows: auto auto;
                grid-template-rows: auto auto;
                margin-left: 25px;
            }
            .grid--imageslist .grid__item .grid__intro {
                min-height: inherit;
                margin-bottom: 0;
            }
            .grid--imageslist .grid__img {
                -ms-grid-row: 1;
                -ms-grid-row-span: 2;
                grid-row: 1/3;
                margin: 0;
                margin-left: -50px;
            }
            .grid--imageslist .grid__stats {
                width: auto;
                padding-left: 25px;
                padding-right: 25px;
                margin-top: 25px;
            }
            .grid--proforma {
                -ms-grid-columns: 1fr;
                grid-template-columns: 1fr;
            }
            .grid--proforma .grid__item {
                padding: 30px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
            }
            .grid--proforma .grid__primary,
            .grid--proforma .grid__secondary {
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
            }
            .grid--proforma .grid__secondary {
                padding-top: 0;
                border-top: none;
                margin-top: 0;
                border-left: 1px solid rgba(255, 255, 255, 0.15);
                padding-left: 20px;
            }
            .grid--proforma .stat-desc {
                width: 100%;
            }
            .grid--proforma-alt .grid__item {
                padding: 30px;
            }
            .grid--proforma-alt .grid__item--caprate .d-flex {
                -webkit-box-orient: vertical;
                -webkit-box-direction: reverse;
                -ms-flex-direction: column-reverse;
                flex-direction: column-reverse;
            }
            .grid--proforma-alt .grid__item--caprate .cap-img {
                margin-bottom: 15px;
            }
        }

        @media screen and (min-width: 769px) {
            .blocks--sponsors .block__copy {
                min-height: 180px;
            }
        }

        @media screen and (min-width: 992px) {
            .site-header::before {
                content: '';
                display: inline-block;
                width: 0;
                height: 60px;
                background-color: rgba(36, 40, 49, 0.85);
                -webkit-transition: all 350ms cubic-bezier(0.215, 0.61, 0.355, 1);
                transition: all 350ms cubic-bezier(0.215, 0.61, 0.355, 1);
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
            }
            .navbar-nav--right {
                -webkit-box-pack: end;
                -ms-flex-pack: end;
                justify-content: flex-end;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-flex: 1;
                -ms-flex: 1 0 auto;
                flex: 1 0 auto;
                padding-right: 25px;
            }
            .navbar-nav--right .nav-item {
                margin-left: 25px;
                position: relative;
                font-weight: 700;
                padding-left: 0;
                padding-right: 0;
            }
            .navbar-nav--right .nav-item--lined {
                position: relative;
            }
            .navbar-nav--right .nav-item--lined .nav-link::after {
                content: '';
                display: inline-block;
                position: absolute;
                top: 42%;
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                width: 0;
                height: 10px;
                background-color: rgba(255, 255, 255, 0.35);
                -webkit-transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
                transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
                line-height: 36px;
                color: #fff;
            }
            .navbar-nav--right .nav-item--lined:hover .nav-link::after {
                width: 100%;
            }
            .scrolled .site-header::before {
                width: 100%;
                opacity: 1;
                -webkit-box-shadow: 0 8px 25px rgba(36, 40, 49, 0.05);
                box-shadow: 0 8px 25px rgba(36, 40, 49, 0.05);
            }
            .scrolled .navbar-brand i {
                font-size: 1.3em;
            }
            .scrolled .navbar-nav--right {
                padding-top: 2px;
                padding-bottom: 0;
            }
            .cblock--intro h2 {
                margin-top: -70px;
            }
            .blocks--projects {
                margin-bottom: 140px;
            }
            .blocks--projects .block:nth-child(2),
            .blocks--projects .block:nth-child(5),
            .blocks--projects .block:nth-child(8),
            .blocks--projects .block:nth-child(11) {
                top: 70px;
            }
            .blocks--projects .block:nth-child(3n + 3) {
                top: 140px;
            }
            .site-footer {
                text-align: left;
            }
            .site-footer .container--cols {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
            }
            .site-footer .container--cols > * {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 auto;
                flex: 0 0 auto;
            }
            .site-footer .site-footer__links {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: start;
                -ms-flex-align: start;
                align-items: flex-start;
            }
            .site-footer .site-footer__links > * {
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
                margin-top: -5px;
            }
            .site-footer .site-footer__branding {
                -webkit-box-flex: 1;
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;
            }
            .site-footer .site-footer__links {
                width: 25%;
            }
            .site-footer .site-footer__connect {
                width: 30%;
            }
            .grid--amenities .grid__item:nth-child(even) .grid__img {
                -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                order: 2;
            }
            .grid--amenities .grid__item:nth-child(even) .grid__desc {
                -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                order: 1;
            }
        }

        @media only screen and (min-width: 768px) {
            .btn-readmore--neighborhood {
                width: calc(50% - 6px);
            }
            .table--large > tbody > tr > td {
                font-size: 1.2em;
                padding-top: 15px;
                padding-bottom: 15px;
            }
        }

        @media only screen and (max-width: 768px) {
            .cblock--intro {
                padding: 80px 0;
            }
            .section-head {
                max-width: 600px;
            }
            .featblock {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
            }
            .featblock:nth-child(even) .featblock__img {
                padding-right: 0;
                padding-left: 35px;
            }
            .featblock__img {
                padding-right: 35px;
            }
            .featblock__msg {
                max-width: 600px;
                padding-top: 35px;
            }
            .featblock__msg h3 {
                margin-bottom: 35px;
            }
            .contacts {
                max-width: 86%;
            }
        }

        @media only screen and (max-width: 767px) {
            body {
                font-size: 15px;
                padding-top: 50px;
            }
            .btn {
                font-size: 12px;
            }
            .btn-next {
                font-size: 1.05em;
            }
            .btn-next i {
                font-size: 0.6em;
            }
            .btn-next__img img {
                width: 60px;
            }
            .site-side {
                width: 80%;
                max-width: 340px;
                left: -80%;
                -webkit-transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
                transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
                z-index: 50;
                background-color: #171a1f;
            }
            .showmenu .overlay {
                opacity: 1;
                visibility: visible;
            }
            .showmenu .site-side {
                left: 0;
            }
            .content {
                padding: 0 20px;
            }
            .cblock h2 {
                margin-bottom: 15px;
            }
            .cblock__sect {
                margin-bottom: 15px;
            }
            .cblock__head {
                margin-bottom: 20px;
            }
            .cblock__intro {
                font-size: 1.1em;
            }
            .grid--amenities {
                -ms-grid-columns: 1fr;
                grid-template-columns: 1fr;
            }
            .list-transactions > li {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
            }
            .list-transactions > li p + p {
                margin-top: 12px;
            }
            .list-transactions > li .list-transactions__price {
                text-align: left;
            }
            .mobilebar {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                position: inherit;
            }
            .mobilebar .btn-outline {
                font-size: 0.8em;
                padding: 12px 16px;
            }
            .cblock h3 {
                font-size: 1.25em;
            }
            .cblock__cta .btn {
                display: block;
            }
            .blocks .block {
                margin-bottom: 25px;
            }
            .blocks--sponsors .block {
                margin-top: 25px;
            }
            .blocks--sponsors .block__img {
                padding: 0 20px;
                margin-top: -25px;
            }
            .blocks--sponsors .block__img img {
                width: 100px;
            }
            .grid {
                grid-gap: 6px;
            }
            .grid__item {
                padding: 15px 20px;
            }
            .grid--dualstat {
                -ms-grid-columns: 1fr;
                grid-template-columns: 1fr;
            }
            .grid--large .grid__item {
                padding: 15px 20px;
            }
            .grid__item--large {
                padding: 15px 20px;
            }
            .grid__item--large .stat-val {
                font-size: 1.8em;
            }
            .grid__item--large .stat-desc {
                font-size: 0.8em;
            }
            .grid--property .grid__item--icon i {
                font-size: 2.3em;
                margin-bottom: 8px;
            }
            .grid--imageslist .grid__item {
                margin-left: 15px;
            }
            .grid--imageslist .grid__img {
                margin-left: -35px;
            }
            .grid--imageslist .grid__img img {
                width: 100px;
            }
            .grid--imageslist .grid__content,
            .grid--imageslist .grid__stats {
                padding-left: 15px;
                padding-right: 15px;
            }
            .grid--imageslist .grid__stats {
                margin-top: 15px;
            }
            .box__body {
                padding: 35px 25px;
            }
            .sponsors-logo {
                width: 90px;
            }
            .admin-logo {
                display: none;
            }
            .cblock--sponsors h1 {
                font-size: 2.6em;
            }
            .inline-gallery--main {
                margin-left: -20px;
                margin-right: -20px;
            }
            .inline-gallery__nav {
                display: none;
            }
            .table-responsive {
                margin-bottom: 8px;
            }
            .table-responsive::-webkit-scrollbar {
                width: 6px;
                height: 6px;
            }
            .table-responsive::-webkit-scrollbar-track {
                background-color: transparent;
            }
            .table-responsive::-webkit-scrollbar-thumb {
                background-color: rgba(255, 255, 255, 0.35);
            }
            .table-responsive > .table {
                min-width: 500px;
            }
            .grid--units {
                -ms-grid-columns: 1fr;
                grid-template-columns: 1fr;
            }
            .grid--units .stat-desc {
                font-size: 0.8em;
            }
            .boxes--companies {
                -ms-grid-columns: 1fr;
                grid-template-columns: 1fr;
            }
            .boxes--schools .box__body {
                padding: 20px;
            }
            .boxes--schools .box__head {
                padding: 20px;
            }
            .boxes--schools .box__head + .box__body {
                padding-top: 0;
            }
            .grid--demographics {
                -ms-grid-columns: 1fr 1fr;
                grid-template-columns: 1fr 1fr;
            }
            .grid--demographics .grid__item .stat-val {
                font-size: 1.8em;
            }
            .grid--companies .grid__item {
                padding: 15px 7px;
            }
            .grid--proforma .grid__item {
                padding: 20px;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
            }
            .grid--proforma .grid__secondary {
                padding-left: 0;
                border-left: 0;
                margin-top: 12px;
                padding-top: 12px;
                border-top: 1px solid rgba(255, 255, 255, 0.15);
            }
            .grid--proforma .grid__secondary .stat-val {
                font-size: 1.5em;
            }
            .grid--proforma .stat-val {
                font-size: 1.8em;
                margin: 0;
            }
            .grid--proforma .stat-desc {
                font-size: 0.9em;
                margin-top: 15px !important;
            }
            .grid--proforma-alt .grid__item {
                padding: 20px;
            }
            .blocks--contact .block {
                margin-bottom: 6px;
            }
            .blocks--contact .block__body {
                padding: 15px 20px;
            }
            .blocks--contact .list-contacts {
                font-size: 1em;
            }
            .grid--employment,
            .grid--employment-alt {
                -ms-grid-columns: 1fr;
                grid-template-columns: 1fr;
            }
        }
        /*# sourceMappingURL=style.css.map */
        .blocks--sponsors p {
            text-align: justify;
        }

        * {
            box-sizing: border-box;
        }

        .row > .column {
            padding: 0 8px;
        }

        .row:after {
            content: '';
            display: table;
            clear: both;
        }

        .column {
            float: left;
            width: 25%;
        }

        /* The Modal (background) */
        .modal {
            display: none;
            position: fixed;
            z-index: 9999999999;
            padding-top: 100px;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
            background-color: black;
        }

        /* Modal Content */
        .modal-content {
            position: relative;
            background-color: #fefefe;
            margin: auto;
            padding: 0;
            width: 90%;
            max-width: 1200px;
        }

        /* The Close Button */
        .closeModal {
            color: white;
            position: absolute;
            top: 30px;
            right: 275px;
            font-size: 35px;
            font-weight: bold;
        }

        .close:hover,
        .close:focus {
            color: #999;
            text-decoration: none;
            cursor: pointer;
        }

        .mySlides {
            display: none;
        }

        .cursor {
            cursor: pointer;
        }

        /* Next & previous buttons */
        .prev,
        .next {
            cursor: pointer;
            position: absolute;
            top: 50%;
            width: auto;
            padding: 16px;
            margin-top: -50px;
            color: white;
            font-weight: bold;
            font-size: 20px;
            transition: 0.6s ease;
            border-radius: 0 3px 3px 0;
            user-select: none;
            -webkit-user-select: none;
        }

        /* Position the "next button" to the right */
        .next {
            right: -5%;
            border-radius: 3px 0 0 3px;
        }

        .prev {
            left: -5%;
            border-radius: 3px 0 0 3px;
        }

        /* On hover, add a black background color with a little bit see-through */
        .prev:hover,
        .next:hover {
            background-color: rgba(0, 0, 0, 0.8);
        }

        /* Number text (1/3 etc) */
        .numbertext {
            color: #f2f2f2;
            font-size: 12px;
            padding: 8px 12px;
            position: absolute;
            top: 0;
        }

        img {
            margin-bottom: -4px;
        }

        .caption-container {
            text-align: center;
            background-color: black;
            padding: 2px 16px;
            color: white;
        }

        .demo {
            opacity: 0.6;
        }

        .active,
        .demo:hover {
            opacity: 1;
        }

        img.hover-shadow {
            transition: 0.3s;
        }

        .hover-shadow:hover {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }

        .highlightsrow {
            flex-direction: initial;
            /*justify-content: space-around;*/
            padding: 20px 0;
        }

        .highlightsrow .selecttbl,
        .highlightsrow .selecttb2 {
            margin: 0 20px;
        }
        .apexcharts-yaxis-texts-g text,
        .expose-wrapper .apexcharts-xaxis-texts-g text {
            fill: #fff;
        }
        table {
            color: white;
        }
        .table--large > tbody > tr > td {
            font-size: 1.2em;
            padding-top: 15px;
            padding-bottom: 15px;
        }
        .table > tbody > tr > td {
            line-height: 1.2em;
            border-top-color: rgba(255, 255, 255, 0.12);
        }
    }
    .slick-dots li button:before {
        font-size: 6px !important;
    }

    #main-content {
        overflow-x: hidden;
        min-height: 100vh;
    }

    .white-theme {
        h1,
        h2,
        h3,
        h4,
        h5 {
            font-family: Montserrat, Poppins, Arial, Helvetica Neue, Helvetica, sans-serif;
            font-weight: 400;
        }
        .site-side {
            background-color: #fff;
            color: black;
            &__tagline {
                border-top: 1px solid rgba(36, 33, 33, 0.12);
                color: #212529 !important;
            }
            &__foot {
                p {
                    color: #212529 !important;
                }
                a {
                    background-color: rgba(237, 227, 227, 0.35);
                    color: #242831;
                    cursor: pointer;
                    font-weight: 400;
                }
            }
            .nav-link {
                color: rgba(0, 0, 0, 0.6) !important;
                font-size: 0.85em !important;
                font-weight: 400;
                &.active {
                    background-color: rgba(0, 0, 0, 0.6);
                    color: rgba(255, 255, 255, 0.6) !important;
                    box-shadow: inset 3px 0 0 rgba(31, 182, 220, 0.8);

                    &:hover {
                        color: rgba(255, 255, 255, 1) !important;
                    }
                }
                &:hover {
                    color: rgba(0, 0, 0, 1) !important;
                }
            }
        }
        main {
            background-color: #fff;
            color: black;
            p {
                color: black;
            }
            .content-nav {
                background-color: #fff;
                border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                li {
                    a {
                        color: rgba(0, 0, 0, 0.6) !important;
                        font-weight: 400 !important;
                        &.active {
                            color: #1fb6dc !important;
                        }
                    }
                }
            }
            .cblock {
                .table,
                table {
                    color: #000 !important;
                    font-weight: 400;
                    border: 1px solid gray;
                    thead {
                        tr {
                            th {
                                color: #000 !important;
                            }
                        }
                    }
                    tbody {
                        color: #000 !important;
                        tr {
                            th,
                            td {
                                color: #000;
                                a {
                                    color: #000 !important;
                                }
                            }
                        }
                    }
                    .distance {
                        color: rgba(0, 0, 0, 0.45);
                    }
                }
                &__sect {
                    color: #1fb6dc;
                    border-top: 1px solid rgba(38, 32, 32, 0.1);
                    background-color: #fff;
                    span {
                        background-color: #fff;
                        font-weight: 400;
                    }
                }
                &__intro {
                    p {
                        color: inherit;
                    }
                }
                .grid {
                    &__item {
                        background-color: rgba(0, 0, 0, 0.6);
                        i {
                            opacity: 1;
                            color: #fff;
                        }
                    }
                    &__head,
                    &__intro {
                        color: #fff;
                    }
                    &__primary {
                        border-right: 1px solid rgba(255, 255, 255, 0.1) !important;
                        &::after {
                            background-color: transparent !important;
                            border: 1px solid rgba(255, 255, 255, 0.1) !important;
                        }
                    }
                }
                .block {
                    .company-name {
                        color: #000;
                    }
                    .person {
                        p {
                            color: #000 !important;
                        }
                    }
                    h5 {
                        color: #1fb6dc;
                    }
                }
            }
            .btn {
                color: #000 !important;
                border: 2px solid #000;
                &:hover {
                    border: 2px solid #000;
                }
            }
            .btn-linkedin {
                background-color: rgba(255, 255, 255, 0.15);
                color: #fff !important;
                border-width: 2px;
                font-weight: 400;
                &:hover {
                    //border-width: 2px !important;
                    //background-color: #fff;
                    color: #1fb6dc !important;
                }
            }
        }
        .apexcharts-legend-text,
        .apexcharts-legend-text text,
        .apexcharts-yaxis-texts-g text,
        .apexcharts-xaxis-texts-g text,
        .apexcharts-title-text {
            fill: black;
            color: #000 !important;
        }

        footer {
            .site-footer__links {
                a {
                    color: #000 !important;
                }
            }
            .list-contacts {
                a {
                    color: #000 !important;
                }
            }
            .site-footer__copy {
                color: rgba(0, 0, 0, 0.45);
            }

            .site-footer__copy a {
                color: rgba(0, 0, 0, 0.5);
                border-bottom: 1px solid rgba(0, 0, 0, 0.25);
            }
        }
        .modal {
            background-color: #fff;
            .item-wrapper {
                background-color: #fff;
                .section-item {
                    background-color: inherit;
                }
            }
            .caption-container {
                background-color: inherit;
            }
            .slider-title,
            .number-text {
                color: #000 !important;
            }
        }
        .closeModal {
            color: #000;
        }
        .buttons-wrapper {
            .buttons-item {
                border: 1px solid rgba(0, 0, 0, 0.45) !important;
            }
        }
        .contacts-wrapper {
            border-color: rgba(0, 0, 0, 0.5);
        }
        .map-filter-wrapper {
            .item {
                &.active {
                    color: #fff;
                }
            }
        }
    }
`
