import React, { useCallback, useMemo } from 'react'
import { ApexPieChart } from '../../../../charts/ApexPieChart'
import { ApexBarChart } from '../../../../charts/ApexBarChart'

export function UnitMix(props: { property: any; noTitle?: boolean }) {
    let totalunits = 0
    const bybedrooms = [0, 0, 0, 0, 0, 0]
    const byselection = {}
    const uniqUnitSizes = []
    const additionalUnitTypes = []
    const project = props.property
    for (let i = 0; i < project.unitmixs.length; i++) {
        const unitmix = project.unitmixs[i]
        if (!uniqUnitSizes.includes(parseInt(unitmix.unitsize))) uniqUnitSizes.push(parseInt(unitmix.unitsize))
    }
    uniqUnitSizes.sort(function (a, b) {
        return a - b
    })

    const getRangesArray = useMemo(() => {
        const rangesArr = []
        const customTypesArr = []
        for (let i = 0; i < uniqUnitSizes.length; i++) {
            if (i === 0) {
                rangesArr.push([uniqUnitSizes[i]])
                continue
            }
            if (uniqUnitSizes[i] != uniqUnitSizes[uniqUnitSizes.length]) {
                if (uniqUnitSizes[i] - uniqUnitSizes[i - 1] > 100) {
                    rangesArr.push([uniqUnitSizes[i]])
                } else {
                    rangesArr[rangesArr.length - 1].push(uniqUnitSizes[i])
                }
            }
        }
        for (let i = 0; i < rangesArr.length; i++) {
            if (i === 0 && Math.max(...rangesArr[i]) < 800) {
                customTypesArr.push('1 Bedroom / 1 - Bath')
            } else if (Math.max(...rangesArr[i]) < 1000) {
                customTypesArr.push('1 Bedroom / 1 - Bath')
            } else if (Math.max(...rangesArr[i]) < 1300) {
                customTypesArr.push('2 Bedroom / 2 - Bath')
            } else if (Math.max(...rangesArr[i]) < 1400) {
                customTypesArr.push('3 Bedroom / 2 - Bath')
            } else {
                customTypesArr.push('4 Bedroom / 2 - Bath')
            }
        }
        return [customTypesArr, rangesArr]
    }, [project])

    const getTypeOfUnit = useCallback((size) => {
        let type = ''
        getRangesArray[1].length
        for (let i = 0; i < getRangesArray[1].length; i++) {
            if (getRangesArray[1][i].includes(size)) {
                type = getRangesArray[0][i]
            }
        }
        return type
    }, [])

    for (let k = 0; k < project.unitmixs.length; k++) {
        const unitmix = project.unitmixs[k]
        let bedBathAvailable = false
        if (!bedBathAvailable) {
            if (parseInt(unitmix?.baths) > 0 || parseInt(unitmix?.bedrooms) >= 0) bedBathAvailable = true
        }
        totalunits += parseInt(unitmix.numofunits)
        const customUnitType: any = !bedBathAvailable
            ? getTypeOfUnit(parseInt(unitmix.unitsize))?.match(/(\d+).*\/ (\d+).*/)
            : []
        bedBathAvailable
            ? unitmix.unitType !== 'retail' && (bybedrooms[unitmix.bedrooms] += parseInt(unitmix.numofunits))
            : (bybedrooms[customUnitType[1] - 1] += parseInt(unitmix.numofunits))

        let key: string | number = ''
        if (unitmix.unitType === 'retail') {
            key = 'Retail'
            if (additionalUnitTypes.indexOf('Retail')) {
                additionalUnitTypes.push('Retail')
            }
        } else {
            key = bedBathAvailable
                ? unitmix.bedrooms + '-' + Math.round(unitmix.baths)
                : customUnitType[1] + '-' + customUnitType[2]
        }
        let x = byselection[key]
        if (!x) {
            x = {}
            x.floorPlan = unitmix.floorPlan
            x.bedrooms = bedBathAvailable ? unitmix.bedrooms : customUnitType[1]
            x.baths = bedBathAvailable ? unitmix.baths : customUnitType[2]
            x.numofunits = parseInt(unitmix.numofunits)
            x.minprice = x.maxprice = unitmix.rentprice
            x.minsize = x.maxsize = unitmix.unitsize
        } else {
            x.numofunits += parseInt(unitmix.numofunits)
            if (unitmix.rentprice < x.minprice) x.minprice = unitmix.rentprice
            else if (unitmix.rentprice > x.maxprice) x.maxprice = unitmix.rentprice
            if (unitmix.unitsize < x.minsize) x.minsize = unitmix.unitsize
            else if (unitmix.unitsize > x.maxsize) x.maxsize = unitmix.unitsize
        }

        byselection[key] = x
    }

    const tableRows = []
    const chartData = {
        series: [],
        labels: [],
    }
    for (let i = 5; i >= -2; i--) {
        for (let j = -1; j <= 4; j++) {
            const x = byselection[i + '-' + j]
            if (x) {
                let price = x.minprice == x.maxprice ? x.minprice : x.minprice + '-' + x.maxprice
                if (!price) price = ' -- '
                let pricesqft =
                    x.minprice == x.maxprice
                        ? (x.minprice / x.minsize).toFixed(2)
                        : (x.minprice / x.minsize).toFixed(2) + '-' + (x.maxprice / x.maxsize).toFixed(2)

                if (!pricesqft) pricesqft = ' -- '

                const size = x.minsize == x.maxsize ? x.minsize : x.minsize + '-' + x.maxsize
                const label = i == 0 && j == 1 ? 'Studio' : i + ' Bedroom / ' + j + ' - Bath'
                tableRows.push({
                    unit: label,
                    numOfUnits: x.numofunits,
                    percent: ((x.numofunits / totalunits) * 100).toFixed(2),
                    size,
                    price,
                    pricesqft,
                })

                //chart data
                chartData.series.push(+((x.numofunits / totalunits) * 100).toFixed(2))
                chartData.labels.push(label)
            }
        }
    }

    if (additionalUnitTypes.length > 0) {
        for (let i = 0; i < additionalUnitTypes.length; i++) {
            const x = byselection[additionalUnitTypes[i]]
            if (x) {
                let price = x.minprice == x.maxprice ? x.minprice : x.minprice + '-' + x.maxprice
                if (!price) price = ' -- '
                let pricesqft =
                    x.minprice == x.maxprice
                        ? (x.minprice / x.minsize).toFixed(2)
                        : (x.minprice / x.minsize).toFixed(2) + '-' + (x.maxprice / x.maxsize).toFixed(2)

                if (!pricesqft) pricesqft = ' -- '

                const size = x.minsize == x.maxsize ? x.minsize : x.minsize + '-' + x.maxsize
                const label = x.floorPlan

                tableRows.push({
                    unit: label,
                    numOfUnits: x.numofunits,
                    percent: ((x.numofunits / totalunits) * 100).toFixed(2),
                    size,
                    price,
                    pricesqft,
                })
                //chart data
                chartData.series.push(+((x.numofunits / totalunits) * 100).toFixed(2))
                chartData.labels.push(label)
            }
        }
    }
    return (
        <section className="cblock markPdfPageBreak" id="unitmix">
            <div className="container">
                <div className="row">
                    {!props?.noTitle && (
                        <p className="cblock__sect animatedin in stop">
                            <span>Unit Mix</span>
                        </p>
                    )}
                    <div className={'cblock__body mt-3 mt-md-5'} style={{ position: 'relative' }}>
                        <div className="grid grid--units">
                            {bybedrooms[4] > 0 && (
                                <div className="grid__item grid__item--icon ">
                                    <i className="seeicon-bed4"></i>
                                    <p id="4bedamount" className="stat-val">
                                        {bybedrooms[4]}
                                    </p>
                                    <p className="stat-desc">4-bedroom</p>
                                </div>
                            )}
                            {bybedrooms[3] > 0 && (
                                <div className="grid__item grid__item--icon ">
                                    <i className="seeicon-bed2"></i>
                                    <p id="3bedamount" className="stat-val">
                                        {bybedrooms[3]}
                                    </p>
                                    <p className="stat-desc">3-bedroom</p>
                                </div>
                            )}
                            {bybedrooms[2] > 0 && (
                                <div className="grid__item grid__item--icon ">
                                    <i className="seeicon-bed1"></i>
                                    <p id="2bedamount" className="stat-val">
                                        {bybedrooms[2]}
                                    </p>
                                    <p className="stat-desc">2-bedroom</p>
                                </div>
                            )}
                            {bybedrooms[1] > 0 && (
                                <div className="grid__item grid__item--icon ">
                                    <i className="seeicon-studio"></i>
                                    <p id="1bedamount" className="stat-val">
                                        {bybedrooms[1]}
                                    </p>
                                    <p className="stat-desc">1-bedroom</p>
                                </div>
                            )}
                            {bybedrooms[0] > 0 && (
                                <div className="grid__item grid__item--icon ">
                                    <i className="seeicon-studio"></i>
                                    <p id="1bedamount" className="stat-val">
                                        {bybedrooms[0]}
                                    </p>
                                    <p className="stat-desc">Studio</p>
                                </div>
                            )}
                            {additionalUnitTypes.length > 0 &&
                                additionalUnitTypes.map((item) => (
                                    <div className="grid__item grid__item--icon ">
                                        <i className="seeicon-studio"></i>
                                        <p id="1bedamount" className="stat-val">
                                            {byselection[item].numofunits}
                                        </p>
                                        <p className="stat-desc">{byselection[item].floorPlan}</p>
                                    </div>
                                ))}
                        </div>
                        <div className="table-responsive">
                            <table className="table table-borderless table-striped table--large table--smallmobile my-4">
                                <thead>
                                    <tr>
                                        <th>Unit type</th>
                                        <th className="text-center">No.</th>
                                        <th className="text-center">%</th>
                                        <th className="text-center">Size (sf)</th>
                                        <th className="text-right">Rent</th>
                                        <th className="text-right">Rent (sf)</th>
                                    </tr>
                                </thead>
                                <tbody id="byselection">
                                    {tableRows.map((row) => (
                                        <tr key={row.unit}>
                                            <td>{row.unit}</td>
                                            <td className="text-center">{row.numOfUnits}</td>
                                            <td className="text-center">{row.percent}%</td>
                                            <td className="text-center">{row.size} sf</td>
                                            <td className="text-right font-weight-bold">${row.price}</td>
                                            <td className="text-right font-weight-bold">{row.pricesqft} $/sf</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div id="unit-mix-chart">
                                {project.unitMixChartVisible &&
                                    +project.unitMixChartVisible === 1 &&
                                    (project.unitMixChartType === 'pie' ? (
                                        <ApexPieChart values={chartData.series} labels={chartData.labels} />
                                    ) : project.unitMixChartType === 'bar' ? (
                                        <ApexBarChart labels={chartData.labels} values={chartData.series} />
                                    ) : null)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*language=scss*/}
            <style jsx>
                {`
                    #byselection {
                        color: #fff;
                    }
                `}
            </style>
        </section>
    )
}
