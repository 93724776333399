import React, { useMemo } from 'react'
import BasePageLayoutOM from '../../BasePageLayoutOM'
import ReportSection from '../../ReportSection'
import { NeighborhoodDemographic } from '../../../../expose/neighborhood/NeighborhoodDemographic'
import { capitalize } from '../../../../../../utils'

export function Demographics(props: any) {
    let paragraphsCount = 0
    let locationDescription = []
    props?.markets[0]?.location?.description?.split('\n')?.map((item, i) => {
        if (item?.length == 0 || item?.trim() == '') {
            return
        } else {
            locationDescription.push(item)
            paragraphsCount++
        }
    })

    const limit = useMemo(() => {
        return Math.floor(paragraphsCount / 2)
    }, [paragraphsCount])

    let address: string = props.dealOutline.address
    address = address?.replace(/[0-9]/g, '')?.toLowerCase()
    // @ts-ignore
    address = address?.split(',')
    if (address?.[address.length - 2]?.[0] == ' ') {
        // @ts-ignore
        address[address.length - 2] = address[address.length - 2].substr(1)
    }
    let city = address?.[address.length - 2]
    city = city?.charAt(0)?.toUpperCase() + city?.slice(1)
    city = city ? capitalize(city) : ''

    const state = address?.[address.length - 1]?.toUpperCase() || ''

    const fullAddress = `${city} ,${state}`

    return (
        <>
            {props.location && props.dealOutline && (
                <>
                    {props?.markets[0]?.mapimg && (
                        <div className={'reportPage page a4-150dpiSpecialOM'}>
                            <BasePageLayoutOM title={'Market Overview'} subtitle={props.headerSubtitle}>
                                <div className={'expose-wrapper white-theme'}>
                                    <main>
                                        <div className={'cblock'} style={{ marginTop: '-80px' }}>
                                            <ReportSection title={null} className={'marketmap'} titleSmall={true}>
                                                <div className="cblock__head ">
                                                    <h1 id="locationname">{props?.markets[0]?.location?.name}</h1>
                                                </div>
                                                <div className="cblock__body">
                                                    <div className="cblock__fullimg mb-5 ">
                                                        {props?.markets[0]?.mapimg && (
                                                            <img
                                                                id="googleimg"
                                                                src={`${props?.markets[0]?.mapimg}`}
                                                                alt="Map"
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </ReportSection>
                                        </div>
                                    </main>
                                </div>
                            </BasePageLayoutOM>
                        </div>
                    )}
                    {locationDescription.length > 0 && (
                        <div className={'reportPage page a4-150dpiSpecialOM'}>
                            <BasePageLayoutOM title={'Market Overview'} subtitle={props.headerSubtitle}>
                                <div className={'expose-wrapper white-theme'}>
                                    <main>
                                        <div className={'cblock'} style={{ marginTop: '-80px' }}>
                                            <ReportSection title={null} className={'Demographics'} titleSmall={true}>
                                                <div className="cblock__head ">
                                                    <h1 id="locationname">{props?.markets[0]?.location?.name}</h1>
                                                </div>
                                                <div id="locationDescription" className="row text-justify">
                                                    <div className={'row'} style={{ display: 'flex' }}>
                                                        <div className="col-md-6">
                                                            {locationDescription?.map((item, i) => {
                                                                if (item?.length == 0 || i > limit) {
                                                                    return null
                                                                }
                                                                return (
                                                                    <p key={i} className={'col-md-12'}>
                                                                        {item}
                                                                    </p>
                                                                )
                                                            })}
                                                        </div>
                                                        <div className="col-md-6">
                                                            {locationDescription?.map((item, i) => {
                                                                if (item?.length == 0 || i <= limit) {
                                                                    return null
                                                                }
                                                                return (
                                                                    <p key={i} className={'col-md-12'}>
                                                                        {item}
                                                                    </p>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </ReportSection>
                                        </div>
                                    </main>
                                </div>
                            </BasePageLayoutOM>
                        </div>
                    )}
                    {props.demographic && (
                        <div className={'reportPage page a4-150dpiSpecialOM'}>
                            <BasePageLayoutOM title={'Market Overview'} subtitle={props.headerSubtitle}>
                                <div className={'expose-wrapper white-theme'}>
                                    <main>
                                        <div className={'cblock'} style={{ marginTop: '-80px' }}>
                                            <ReportSection
                                                title={'Demographic'}
                                                className={'Demographics'}
                                                titleSmall={true}
                                            >
                                                <NeighborhoodDemographic
                                                    demographic={props.demographic}
                                                    city={fullAddress}
                                                    noTitle={true}
                                                    /* style={{}}*/
                                                />
                                            </ReportSection>
                                        </div>
                                    </main>
                                </div>
                            </BasePageLayoutOM>
                        </div>
                    )}
                </>
            )}
        </>
    )
}
