import React, { useMemo } from 'react'

import { useSelector } from 'react-redux'

import numeral from 'numeral'
import { ReduxStoreState } from '../../../../../../store'
import { CalculatedInfo } from '../../../../operating-assumptions/submarket-insights/CalculatedInfo'
import { ApexInsightsPerfomanceChart } from '../../../../operating-assumptions/submarket-insights/performance/ApexInsightsPerfomanceChart'
import { InsightsTable } from '../../../../operating-assumptions/submarket-insights/InsightsTable'
import BasePageLayoutOM from '../../BasePageLayoutOM'
import ReportSection from '../../ReportSection'
type Props = {
    chartData?: {
        labels: []
        values: []
    }
    tableData: []
    headerSubtitle?: string
}

export const InsightsPerformance = (props: any) => {
    const data = useSelector((state: ReduxStoreState) => state.operatingAssumptions.submarketInsights.performance)
    const chartData = useMemo(() => {
        const byYear = data.filter((item) => item.quater == 'Y')
        return {
            labels: [] as string[],
            values: [] as number[],
            chartSeres: [
                {
                    name: 'Asking rent',
                    type: 'column',
                    data: byYear.map((item) => item.askingRent),
                },
                {
                    name: 'Effective rent',
                    type: 'column',
                    data: byYear.map((item) => item.effectiveRent),
                },
                {
                    name: 'Vacancy rate',
                    type: 'area',
                    data: byYear.map((item) => item.vacancyRate),
                },
            ],
            categories: byYear.map((item) => item.year),
            colors: ['#5791FF', '#DC82CF', '#71CAC4', '#A573E4', '#95F293'],
        }
    }, [data])

    const tableData = useMemo(() => {
        const body = []
        for (const row of data) {
            body.push({
                title: '',
                cells: [
                    {
                        content: row.year,
                        bordering: false,
                        align: 'left',
                    },
                    {
                        content: row.quater,
                        bordering: false,
                        align: 'right',
                    },
                    {
                        content: numeral(row.askingRent).format('$0,0'),
                        bordering: false,
                        align: 'right',
                    },
                    {
                        content: numeral(row.askingRentChange).format('0.[00]') + '%',
                        bordering: false,
                        align: 'right',
                    },
                    {
                        content: numeral(row.effectiveRent).format('$0,0'),
                        bordering: false,
                        align: 'right',
                    },
                    {
                        content: numeral(row.effectiveRentChange).format('0.[00]') + '%',
                        bordering: false,
                        align: 'right',
                    },
                    {
                        content: numeral(row.vacancyRate).format('0.[00]') + '%',
                        bordering: false,
                        align: 'right',
                    },
                    {
                        content: numeral(row.vacancyRateChange).format('0.[00]'),
                        bordering: false,
                        align: 'right',
                    },
                ],
            })
        }
        return {
            header: [
                {
                    cells: [
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: 'Quarter',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: 'Asking Price <br/>(Per Unit)',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: 'Asking Rent <br/>% Change',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: 'Effective Rent <br/>(Per Unit)',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: 'Effective Rent <br/>% Change',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: 'Vacancy Rate',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: 'Vacancy <br/>Change (Bps)',
                            bordering: false,
                            align: 'right',
                        },
                    ],
                },
            ],
            body,
        }
    }, [data])

    return (
        <>
            <div className={'reportPage page a4-150dpiSpecialOM'}>
                <BasePageLayoutOM title={'SubMarket Insights'} subtitle={props.headerSubtitle}>
                    <ReportSection
                        title={'History'}
                        className={'History'}
                        titleSmall={true}
                        style={{ position: 'relative', left: '-20px' }}
                    >
                        <CalculatedInfo />
                        <div style={{ width: '100%' }}>
                            <ApexInsightsPerfomanceChart
                                series={chartData.chartSeres}
                                categories={chartData.categories}
                                colors={chartData.colors}
                                height={280}
                            />
                        </div>
                        <div className="mt-3">
                            <InsightsTable tableHeader={tableData.header} tableBody={tableData.body.slice(0, 25)} />
                        </div>
                    </ReportSection>
                </BasePageLayoutOM>
            </div>

            <div className={'reportPage page a4-150dpiSpecialOM'}>
                <BasePageLayoutOM title={'SubMarket Insights'} subtitle={props.headerSubtitle}>
                    <ReportSection
                        title={'History'}
                        className={'History'}
                        titleSmall={true}
                        style={{ position: 'relative', left: '-20px' }}
                    >
                        <div className="mt-3">
                            <InsightsTable tableHeader={tableData.header} tableBody={tableData.body.slice(25)} />
                        </div>
                    </ReportSection>
                </BasePageLayoutOM>
            </div>

            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
            `}</style>
        </>
    )
}
