import React, { useMemo } from 'react'
import { ApexBarPlusLineChart } from './comparison/ApexInsightsComparisonChart'
import { CalculatedInfo } from './CalculatedInfo'
import { InsightsTable } from './InsightsTable'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../store'
import numeral from 'numeral'
type Props = {
    chartData?: {
        labels: []
        values: []
    }
    tableData: []
}

export const InsightsRentTrend = (props: any) => {
    const data = useSelector((state: ReduxStoreState) => state.operatingAssumptions.submarketInsights.comparison)
    const chartData = useMemo(() => {
        return {
            labels: [] as string[],
            values: [] as number[],
            chartSeres: [
                {
                    name: 'Property asking rent',
                    type: 'column',
                    data: data.trends.map((row) => row.propertyRent),
                },
                {
                    name: `${data.submarketName} asking rent`,
                    type: 'column',
                    data: data.trends.map((row) => row.submarketRent),
                },
                {
                    name: 'Comps asking rent',
                    type: 'column',
                    data: data.trends.map((row) => row.compsRent),
                },
                {
                    name: 'Property vacancy rate',
                    type: 'area',
                    data: data.trends.map((row) => row.propertyVacancy),
                },
                {
                    name: `${data.submarketName} vacancy rate`,
                    type: 'line',
                    data: data.trends.map((row) => row.submarketVacancy),
                },
                {
                    name: 'Comps vacancy rate',
                    type: 'line',
                    data: data.trends.map((row) => row.compsVacancy),
                },
            ],
            categories: data.trends.map((row) => row.year),
            colors: ['#5791FF', '#DC82CF', '#71CAC4', '#A573E4', '#E8E24C', '#95F293'],
        }
    }, [data])

    const tableData = useMemo(() => {
        const body = []
        for (const [i, row] of data.trends.entries()) {
            body.push({
                title: '',
                cells: [
                    {
                        content: row.year.toString(),
                        bordering: false,
                        align: 'left',
                    },
                    {
                        content: row.propertyRent ? numeral(row.propertyRent).format('$0,0') : '-',
                        bordering: false,
                        align: 'right',
                    },
                    {
                        content: row.propertyVacancy ? numeral(row.propertyVacancy).format('0.[00]') + '%' : '-',
                        bordering: true,
                        align: 'right',
                    },
                    {
                        content: row.compsRent ? numeral(row.compsRent).format('$0,0') : '-',
                        bordering: false,
                        align: 'right',
                    },
                    {
                        content: row.compsVacancy ? numeral(row.compsVacancy).format('0.[00]') + '%' : '-',
                        bordering: true,
                        align: 'right',
                    },
                    {
                        content: row.submarketRent ? numeral(row.submarketRent).format('$0,0') : '-',
                        bordering: false,
                        align: 'right',
                    },
                    {
                        content: row.submarketVacancy ? numeral(row.submarketVacancy).format('0.[00]') + '%' : '-',
                        bordering: false,
                        align: 'right',
                    },
                ],
            })
        }
        return {
            header: [
                {
                    cells: [
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: 'Property Asking Rent Per Unit',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: 'Property % Vacant',
                            bordering: true,
                            align: 'right',
                        },
                        {
                            content: 'Comps Asking Rent',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: 'Comps Vacancy Rate',
                            bordering: true,
                            align: 'right',
                        },
                        {
                            content: `${data.submarketName} Asking Rent`,
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: `${data.submarketName} Vacancy Rate`,
                            bordering: false,
                            align: 'right',
                        },
                    ],
                },
            ],
            body,
        }
    }, [data])

    return (
        <div className="renttrend">
            <CalculatedInfo />
            <ApexBarPlusLineChart
                series={chartData.chartSeres}
                categories={chartData.categories}
                colors={chartData.colors}
                height={300}
            />
            <div className="mt-3">
                <InsightsTable tableHeader={tableData.header} tableBody={tableData.body} />
            </div>

            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .statbox {
                    padding: 16px 20px;
                    background: $background;
                    border-radius: $radius;
                    &-label {
                        color: $hint-text-color;
                        font-size: 0.9rem;
                        line-height: 1.3em;
                    }
                    &-value {
                        font-size: 1.3rem;
                        font-weight: 500;
                        margin: $spacing-base 0;
                        line-height: 1.2em;
                    }
                    &-desc {
                        font-size: 0.9rem;
                        font-weight: 500;
                        color: $blue-gray;
                        line-height: 1.3em;
                    }
                }
            `}</style>
        </div>
    )
}
