import React, { useMemo } from 'react'
import { useRouter } from 'next/router'

import { propertyPageStyles } from '../../../expose/property/propertyPageStyles'
import { GridStats } from '../../../expose/property/sections/GridStats'

import { UnitMix } from '../../../expose/property/sections/UnitMix'

import { MortgageRecords } from '../../../expose/property/sections/MortgageRecords'
import { TransactionOverview } from '../../../expose/property/sections/TransactionOverview'

import { TaxRecords } from '../../../expose/property/sections/TaxRecords'
import BasePageLayoutOM from '../BasePageLayoutOM'
import ReportSection from '../ReportSection'
import { miniSiteGlobalStyles } from './theme/miniSiteGlobalStyles'
import { ThemeContextProvider } from '../../../../../components/layouts/ThemeContext'
type Props = {
    [key: string]: any
    property: any
    proforma: any
    dealOutline: any
    taxDataVisible: boolean
    headerSubtitle?: string
}
export function Property(props: Props) {
    const router = useRouter()
    const projectId = +router.query.id
    const address = props?.dealOutline?.address
        ? '<div>' + props.dealOutline.address.replace(/,/, ',</div><div>') + '</div>'
        : ''

    let config = null
    if (props.dealOutline.config) {
        try {
            config = JSON.parse(props.dealOutline.config)
        } catch (e) {
            console.log(e.message)
        }
    }

    const compsOnly = config?.comps_only || false
    const retail = useMemo(() => {
        if (props.retailStats && props.retailStats.hasRetail) {
            const out = []
            out.push({
                title: 'Retail Rentable Area',
                value: props?.retailStats?.totalRetailSF + ' SF',
            })
            out.push({
                title: 'Retail Units',
                value: props?.retailStats?.numberOfRetailUnits,
            })
            return out
        }
        return null
    }, [props])

    const showPropertyProforma =
        props?.proforma?.custom ||
        (+props?.proforma?.gross_revenue &&
            +props?.proforma?.totalcost &&
            +props.proforma.totalsale &&
            +props.proforma.totalsize)

    return (
        <>
            {(props.dealOutline.description || showPropertyProforma || props?.property?.externalImgs?.[0]) && (
                <div className={'reportPage page a4-150dpiSpecialOM'}>
                    <BasePageLayoutOM title={'Property Information'} subtitle={props.headerSubtitle}>
                        <ReportSection title={'The Property'} className={'TheProperty'} titleSmall={true}>
                            <div className={'thePropertyMain'}>
                                {props.dealOutline.description && (
                                    <div className={'thePropertyMainLeft'}>{props.dealOutline.description}</div>
                                )}
                                <div className={'thePropertyMainRight'}>
                                    {props?.property?.externalImgs?.[0] && (
                                        <img src={props.property.externalImgs[0].url} />
                                    )}
                                </div>
                            </div>
                            {showPropertyProforma && (
                                <div className={'expose-wrapper white-theme'}>
                                    <main>
                                        <div className={'cblock'}>
                                            <GridStats proforma={props.proforma} retail={retail} />
                                        </div>
                                    </main>
                                </div>
                            )}
                        </ReportSection>
                    </BasePageLayoutOM>
                </div>
            )}

            {!compsOnly && props.property?.unitmixs?.length > 0 && (
                <div className={'reportPage page a4-150dpiSpecialOM'}>
                    <BasePageLayoutOM title={'Property Information'} subtitle={props.headerSubtitle}>
                        <ReportSection title={'Unit Mix'} className={'UnitMix'} titleSmall={true}>
                            <div className={'expose-wrapper white-theme'} style={{ marginTop: '-200px' }}>
                                <main>
                                    <div className={'cblock'}>
                                        <UnitMix property={props.property} noTitle={true} />
                                    </div>
                                </main>
                            </div>
                        </ReportSection>
                    </BasePageLayoutOM>
                </div>
            )}
            {/*!compsOnly && <FloorPlan property={props.property} projectName={props.dealOutline.name} />*/}
            {/*!compsOnly && <UnitAmenities property={props.property} />*/}
            {!compsOnly && (
                <>
                    {props.taxDataVisible && props?.taxRecords?.length > 0 && (
                        <div className={'reportPage page a4-150dpiSpecialOM'}>
                            <BasePageLayoutOM title={'Property Information'} subtitle={props.headerSubtitle}>
                                <ReportSection title={'Tax Records'} className={'TaxRecords'} titleSmall={true}>
                                    <div className={'expose-wrapper white-theme'} style={{ marginTop: '-180px' }}>
                                        <main>
                                            <div className={'cblock'}>
                                                <TaxRecords
                                                    taxRecords={props.taxRecords}
                                                    noTitle={true}
                                                    maxRecords={5}
                                                />
                                            </div>
                                        </main>
                                    </div>
                                </ReportSection>
                            </BasePageLayoutOM>
                        </div>
                    )}
                    {props?.propertyEnrichments?.transactions?.length > 0 && (
                        <div className={'reportPage page a4-150dpiSpecialOM'}>
                            <BasePageLayoutOM title={'Property Information'} subtitle={props.headerSubtitle}>
                                <ReportSection
                                    title={'Transaction History'}
                                    className={'TransactionHistory'}
                                    titleSmall={true}
                                >
                                    <div className={'expose-wrapper white-theme'} style={{ marginTop: '-180px' }}>
                                        <main>
                                            <div className={'cblock'}>
                                                <TransactionOverview
                                                    propertyEnrichments={props.propertyEnrichments}
                                                    noTitle={true}
                                                />
                                            </div>
                                        </main>
                                    </div>
                                </ReportSection>
                            </BasePageLayoutOM>
                        </div>
                    )}

                    {props?.propertyEnrichments?.mortgagerecords?.length > 0 && (
                        <div className={'reportPage page a4-150dpiSpecialOM'}>
                            <BasePageLayoutOM title={'Property Information'} subtitle={props.headerSubtitle}>
                                <ReportSection
                                    title={'Mortgage Records'}
                                    className={'MortgageRecords'}
                                    titleSmall={true}
                                >
                                    <div className={'expose-wrapper white-theme'} style={{ marginTop: '-160px' }}>
                                        <main>
                                            <div className={'cblock'}>
                                                <MortgageRecords
                                                    propertyEnrichments={props.propertyEnrichments}
                                                    noTitle={true}
                                                />
                                            </div>
                                        </main>
                                    </div>
                                </ReportSection>
                            </BasePageLayoutOM>
                        </div>
                    )}
                </>
            )}

            <style jsx>{propertyPageStyles}</style>

            {/*language=SCSS*/}
            <style jsx global>
                {miniSiteGlobalStyles}
            </style>
            <style jsx>
                {`
                    .thePropertyMain {
                        display: flex;
                    }
                    .thePropertyMainLeft {
                        width: 50%;
                        min-width: 50%;
                        margin-right: 10px;
                    }
                `}
            </style>
        </>
    )
}
