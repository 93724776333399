import React from 'react'
import { DealOutline } from '../../../../store/types/fullproject'
import { MapAdapter } from '../../../defaultElements/MapAdapter'
import { Polygon } from 'react-google-maps'
import { CrimeInfoResponse, GoogleMapLocation } from '../../../../generated/graphql'
import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel'

type Props = {
    dealOutline: DealOutline
    city: string
    crimeInfo: CrimeInfoResponse
    location: GoogleMapLocation
    noTitle?: boolean
}

export function NeighborhoodCrime(props: Props) {
    const statistic = props.crimeInfo.stats
    const description = props?.crimeInfo.description?.split('\n') || []

    const colors: Array<string> = [
        '#ff0000',
        '#ea1500',
        '#ff5500',
        '#ff8000',
        '#ffaa00',
        '#ffd500',
        '#ffff00',
        '#aaff00',
        '#15ea00',
        '#00ff00',
    ]

    return (
        <section className="cblock markPdfPageBreak" id="crime">
            <div id="crimecontainer" className="container">
                <div className="row">
                    {!props.noTitle && (
                        <div className="cblock__head">
                            <p className="cblock__sect ">
                                <span>Crime</span>
                            </p>
                            <p> Crime is ranked on a scale of 1 (low crime) to 100 (high crime) </p>
                        </div>
                    )}

                    <div className="cblock__body">
                        <div className="grid grid--stats grid--crime my-5">
                            <div className="grid__item grid__item--largest grid__item--icon ">
                                <i className="seeicon-health" />
                                <div className="d-flex">
                                    <div className="grid__primary">
                                        <p id="crimeviolent" className="stat-val">
                                            {statistic.violentCity}
                                        </p>
                                        <p id="crimeviolenttxt" className="stat-desc">
                                            {props.city} violent crime
                                        </p>
                                    </div>
                                    <div className="grid__secondary">
                                        <p id="crimeviolentusa" className="stat-val">
                                            {statistic.violentCounty}
                                        </p>
                                        <p className="stat-desc">The US average</p>
                                    </div>
                                </div>
                            </div>

                            <div className="grid__item grid__item--largest grid__item--icon ">
                                <i className="seeicon-security-cam" />
                                <div className="d-flex">
                                    <div className="grid__primary">
                                        <p id="crimeproperty" className="stat-val">
                                            {statistic.propertyCity}
                                        </p>
                                        <p id="crimepropertytxt" className="stat-desc">
                                            {props.city} property crime
                                        </p>
                                    </div>
                                    <div className="grid__secondary">
                                        <p id="crimepropertyusa" className="stat-val">
                                            {statistic.propertyCountry}
                                        </p>
                                        <p className="stat-desc">The US average</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4 rowflex">
                            <div className="col-lg-6 text-justify">
                                <p id="crimedesc1" className="">
                                    {description[0]}
                                </p>
                                <p id="crimedesc2" className="">
                                    {description.length > 2 ? description[1] : ''}
                                </p>
                            </div>
                            <div className="col-lg-6">
                                <p id="crimedesc3" className="">
                                    {description.length > 2 ? description[2] : description[1]}
                                </p>
                            </div>
                        </div>
                        {props?.crimeInfo?.map?.length > 0 && (
                            <div className="cblock__fullimg ">
                                <div id="googlemap_crime" style={{ width: '100%', height: '700px' }}>
                                    <MapAdapter defaultZoom={12} defaultCenter={props.crimeInfo.mapCenter} height={700}>
                                        <MarkerWithLabel
                                            position={props?.location}
                                            labelAnchor={{ x: 60, y: 0 }}
                                            icon={'http://maps.google.com/mapfiles/ms/micons/green-dot.png'}
                                        >
                                            <div
                                                style={{
                                                    fontSize: '0.65rem',
                                                    padding: '3px',
                                                    color: '#000',
                                                    backgroundColor: '#fff',
                                                    maxWidth: 120,
                                                    fontWeight: 700,
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {props.dealOutline.address}
                                            </div>
                                        </MarkerWithLabel>

                                        {props?.crimeInfo?.map?.map((polygon, i) => {
                                            return (
                                                <Polygon
                                                    paths={polygon.path}
                                                    options={{
                                                        fillColor: colors[polygon.level],
                                                        fillOpacity: 0.4,
                                                        strokeWeight: 1,
                                                        strokeOpacity: 0.25,
                                                        strokeColor: '#666666',
                                                    }}
                                                    key={i}
                                                />
                                            )
                                        })}
                                    </MapAdapter>
                                </div>

                                <div id="crime-map-legend">
                                    <span className="lower-crime">Lower Crime</span>
                                    <span className="blocks">
                                        {colors.reverse().map((backgroundColor, i) => {
                                            return <span key={i} style={{ backgroundColor }} />
                                        })}
                                    </span>
                                    <span className="higher-crime">Higher Crime</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/*language=scss*/}
            <style jsx>{`
                .rowflex {
                    display: flex !important;
                }
                #crime-map-legend {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-top: 5px;
                    .blocks {
                        margin: 0 10px;
                        display: flex;
                        flex-direction: row;
                        span {
                            display: inline-block;
                            width: 50px;
                            height: 25px;
                        }
                    }
                }
            `}</style>
        </section>
    )
}
