import React, { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'

import { SagaHelper } from '../../../../../store/sagaHelper'
import { showErrorNotification } from '../../../fullproject/notifications'

import { Spinner } from '../../../../controls/Spinner'
import { EconomicOverviewOM } from './submarket/EconomicOverviewOM'
import { MultiFamilyInsightsOM } from './submarket/MultiFamilyInsightsOM'
import { SubmarketVsMsaOM } from './submarket/SubmarketVsMsaOM'
import { SubMarketUnitMix } from './submarket/SubMarketUnitMixOM'
import { InsightsComparisonOM } from './submarket/InsightsComparisonOM'
import { InsightsPerformance } from './submarket/InsightsPerformance'

import { Demographics } from './neighborhood/Demographics'
import { AreaInformation } from './neighborhood/AreaInformation'
import { HousingWrapper } from './neighborhood/HousingWrapper'
import { EmploymentWrapper } from './neighborhood/EmploymentWrapper'
import { CrimeWrapper } from './neighborhood/CrimeWrapper'
import { SchoolsWrapper } from './neighborhood/SchoolsWrapper'

const cloneDeep = require('lodash.clonedeep')

const DEBUG_NOT_SHOW_PAGE = false
export function SubMarketInsights(props: any) {
    const router = useRouter()
    const projectId = +router.query.id
    const { printmode } = useMemo(() => {
        let printmode = false
        if (router.asPath.indexOf('printmode=true') > -1) {
            printmode = true
        }
        return { printmode }
    }, [router])

    const [load, setLoad] = useState(true)

    useEffect(() => {
        setLoad(true)
        SagaHelper.run(['submarketInsights', 'loadSubmarketInsights'], { projectId: +router.query.id })
            .catch((e) => {
                return showErrorNotification(e.message)
            })
            .finally(() => {
                try {
                    if (typeof window != 'undefined') {
                        // @ts-ignore
                        window.isSubMarketLoaded = true
                    }
                } catch (e) {}
                setLoad(false)
            })
    }, [])

    return (
        <>
            {load && <Spinner />}
            {!load && (
                <>
                    {(false || !DEBUG_NOT_SHOW_PAGE) && <EconomicOverviewOM headerSubtitle={props.headerSubtitle} />}

                    {(false || !DEBUG_NOT_SHOW_PAGE) && (
                        <Demographics {...props} headerSubtitle={props.headerSubtitle} />
                    )}
                    {(false || !DEBUG_NOT_SHOW_PAGE) &&
                        props?.commonInfo?.sideMenu?.find((m) => m.id == 'Market - Area Information') && (
                            <AreaInformation {...props} headerSubtitle={props.headerSubtitle} />
                        )}
                    {(false || !DEBUG_NOT_SHOW_PAGE) &&
                        props?.commonInfo?.sideMenu?.find((m) => m.id == 'Market - Housing') && (
                            <HousingWrapper {...props} headerSubtitle={props.headerSubtitle} />
                        )}
                    {(false || !DEBUG_NOT_SHOW_PAGE) && (
                        <EmploymentWrapper {...props} headerSubtitle={props.headerSubtitle} />
                    )}
                    {(false || !DEBUG_NOT_SHOW_PAGE) && (
                        <CrimeWrapper {...props} headerSubtitle={props.headerSubtitle} />
                    )}
                    {(false || !DEBUG_NOT_SHOW_PAGE) && (
                        <SchoolsWrapper {...props} headerSubtitle={props.headerSubtitle} />
                    )}

                    {(true || !DEBUG_NOT_SHOW_PAGE) && <MultiFamilyInsightsOM headerSubtitle={props.headerSubtitle} />}

                    {(false || !DEBUG_NOT_SHOW_PAGE) && <SubmarketVsMsaOM headerSubtitle={props.headerSubtitle} />}
                    {(false || !DEBUG_NOT_SHOW_PAGE) && <SubMarketUnitMix headerSubtitle={props.headerSubtitle} />}

                    {(false || !DEBUG_NOT_SHOW_PAGE) && <InsightsComparisonOM headerSubtitle={props.headerSubtitle} />}

                    {(false || !DEBUG_NOT_SHOW_PAGE) && <InsightsPerformance headerSubtitle={props.headerSubtitle} />}
                </>
            )}
        </>
    )
}
