import BasePageLayoutOM from '../BasePageLayoutOM'
import ReportSection from '../ReportSection'

import React, { useMemo, useState } from 'react'
import { Summary } from '../../../project-wizard/steps/GeneralInputs/Summary'
import { removeItemFromArray } from '../../../../../utils'
import { Form } from 'react-final-form'
import { PropertyAndBorrower } from '../../../project-wizard/steps/GeneralInputs/PropertyAndBorrower'
import { LoanRequest } from '../../../project-wizard/steps/GeneralInputs/LoanRequest'
import { DealDetails } from '../../../project-wizard/steps/GeneralInputs/DealDetails'
import { GITeam } from '../../../project-wizard/steps/GeneralInputs/giTeam'
import { FormApi } from 'final-form'

export const GeneralInputs = (props: any) => {
    const mutators = useMemo(() => ({}), [])
    const decorators = useMemo(() => [], [])
    const [initialValues, setInitialValues] = useState(props.generalInputs)
    const localContext = useMemo(() => ({ form: null as FormApi }), [])
    return (
        <>
            {props?.areaInformation?.enabled && (
                <div className={'reportPage page a4-150dpiSpecialOM'}>
                    <BasePageLayoutOM title={'Deal Information'} subtitle={props.headerSubtitle}>
                        <div className={'expose-wrapper white-theme'}>
                            <main>
                                <div className={'cblock'} style={{ marginTop: '-80px' }}>
                                    <ReportSection
                                        title={'General Inputs'}
                                        className={'GeneralInputs'}
                                        titleSmall={true}
                                    >
                                        {initialValues && (
                                            <Form
                                                onSubmit={() => null}
                                                mutators={mutators}
                                                decorators={decorators}
                                                initialValues={initialValues}
                                                validate={(v) => {
                                                    return null
                                                }}
                                                render={({
                                                    handleSubmit,
                                                    form,
                                                    submitting,
                                                    pristine,
                                                    values,
                                                    submitError,
                                                }) => {
                                                    localContext.form = form
                                                    return (
                                                        <>
                                                            <Summary isPDF={true} />
                                                        </>
                                                    )
                                                }}
                                            />
                                        )}
                                    </ReportSection>
                                </div>
                            </main>
                        </div>
                    </BasePageLayoutOM>
                </div>
            )}
        </>
    )
}
