import React from 'react'

/**
 * Converts newlines in a string to <br/> tags.
 * @param text Text to split
 * @returns {string|unknown[]|number}
 */
export const nlToBr = (text) => {
    const newline = '\n'

    // If null, undefined, not a number nor a string, return nothing. If a number, return the number. If empty string, return nothing.
    if (typeof text === 'number') {
        return text
    } else if (typeof text !== 'string') {
        return ''
    } else if (text === '') {
        return ''
    }

    const lines = text.split(newline)
    return lines.map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ))
}

export const formatDollars = (amount) => {
    return amount != null
        ? Number(amount).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
          })
        : ''
}
