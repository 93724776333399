import React, { useMemo } from 'react'
import dynamic from 'next/dynamic'
import { ApexOptions } from 'apexcharts'
import numeral from 'numeral'
// eslint-disable-next-line @typescript-eslint/naming-convention
const Chart = dynamic(() => import('react-apexcharts'), {
    ssr: false,
})

export function ApexVacancyDistPropertyVsCompsChart(props: {
    labels: any[]
    values: any[]
    height?: number
    colors?: any[]
    median?: number
}) {
    const chartParams = {
        seriesTitle: 'Vacancy',
        yAxisTitle: 'Vacancy (%)',
        xAxisTitle: '',
        valuePrefix: '',
        valueSuffix: '%',
        annotationColor: '#E29AD8',
        annotationLabel: 'Average',
    }
    const series = useMemo(() => {
        return [
            {
                name: chartParams.seriesTitle,
                data: [null, ...props.values.slice(1)],
            },
            {
                name: 'The Property',
                data: [props.values[0], ...new Array(props.values.length - 1).fill(null)],
            },
        ]
    }, [props.values])
    const options = {
        series,
        chart: {
            type: 'bar' as any,
            stacked: true,
            height: props.height ? props.height : 350,
            toolbar: {
                show: false,
            },
        },
        colors: props.colors,
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                borderRadius: 2,
            },
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'middle',
            offsetX: 0,
            style: {
                colors: ['#fff'],
            },
            formatter: function (val) {
                return '' + '' + numeral(val * 100).format('0.[0]') + '' + chartParams.valueSuffix
            },
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: props.labels,
            title: {
                text: chartParams.xAxisTitle,
            },
        },
        yaxis: {
            title: {
                text: chartParams.yAxisTitle,
            },
            decimalsInFloat: 0,
            labels: {
                formatter: function (val) {
                    return '$' + '' + Math.round(val * 10) / 10 + '' + chartParams.valueSuffix
                },
            },
        },
        fill: {
            opacity: 0.9,
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return chartParams.valuePrefix + '' + val + '' + chartParams.valueSuffix
                },
            },
        },
        legend: {
            enabled: true,
            horizontalAlign: 'left',
            position: 'top',
            floating: false,
            markers: {
                width: 8,
                height: 8,
            },
            itemMargin: {
                horizontal: 8,
            },
        },
        annotations: {
            yaxis: [
                {
                    y: props.median,
                    borderColor: chartParams.annotationColor,
                    strokeDashArray: 2,
                    label: {
                        borderWidth: 0,
                        borderRadius: 0,
                        style: {
                            color: chartParams.annotationColor,
                            background: 'transparent',
                        },
                        text: `${chartParams.annotationLabel}: ${chartParams.valuePrefix}${props.median}${chartParams.valueSuffix}`,
                    },
                },
            ],
        },
    }

    return (
        <>
            <Chart
                options={options as ApexOptions}
                series={options.series}
                height={props.height}
                type="bar"
                width="100%"
            />

            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                :global(.apexcharts-legend-marker) {
                    margin-right: 8px !important;
                }
                :global(.apexcharts-tooltip) {
                    padding-bottom: 6px;
                }
                :global(.apexcharts-tooltip-title) {
                    background-color: #fff !important;
                    border-bottom-color: #eee !important;
                    padding: 6px 16px !important;
                    font-weight: 700;
                }
                :global(.apexcharts-tooltip-series-group) {
                    padding: 0 16px !important;
                }
                :global(.apexcharts-tooltip-marker) {
                    width: 10px;
                    height: 10px;
                }
            `}</style>
        </>
    )
}
