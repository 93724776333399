import React from 'react'
import dynamic from 'next/dynamic'
import { ApexOptions } from 'apexcharts'
// eslint-disable-next-line @typescript-eslint/naming-convention
const Chart = dynamic(() => import('react-apexcharts'), {
    ssr: false,
})

type Props = {
    series: Array<{
        name: string
        type: string
        data: []
    }>
    categories: []
    colors: []
    height?: number
}

export function ApexInsightsPerfomanceChart({ series, height, categories, colors }) {
    const chartParams = {
        chartTitle: '',
        seriesTitle: 'Properties',
        yAxisTitle: 'Asking Price (per unit) ($)',
        yAxisTitle2: 'Vacant (%)',
        xAxisTitle: 'Vacancy',
        valuePrefix1: '$',
        valueSuffix1: 'k',
        valuePrefix2: '',
        valueSuffix2: '%',
    }

    const options = {
        series: series,
        chart: {
            height: height ? height : 350,
            type: 'line',
            stacked: false,
            toolbar: {
                show: false,
            },
        },
        colors: colors,
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: [0, 0, 2],
        },
        fill: {
            type: ['solid', 'solid', 'gradient'],
            opacity: [0.9, 0.9, 0.9, 0.2, 1],
            gradient: {
                type: 'vertical',
                shadeIntensity: 0,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100],
            },
        },
        title: {
            text: chartParams.chartTitle,
            align: 'left',
        },
        legend: {
            horizontalAlign: 'left',
            position: 'top',
            floating: false,
            markers: {
                width: 8,
                height: 8,
            },
            itemMargin: {
                horizontal: 8,
            },
        },
        plotOptions: {
            bar: {
                borderRadius: 2,
                columnWidth: '36%',
            },
        },
        xaxis: {
            categories: categories,
        },
        yaxis: [
            {
                seriesName: 'Asking rent',
                axisTicks: {
                    show: true,
                },
                title: {
                    text: chartParams.yAxisTitle,
                },
                labels: {
                    formatter: function (val) {
                        return chartParams.valuePrefix1 + '' + Math.round(val * 10) / 10 + '' + chartParams.valueSuffix1
                    },
                },
            },
            {
                seriesName: 'Asking rent',
                show: false,
                labels: {
                    formatter: function (val) {
                        return chartParams.valuePrefix1 + '' + Math.round(val * 10) / 10 + '' + chartParams.valueSuffix1
                    },
                },
            },
            {
                seriesName: 'Vacancy rate',
                opposite: true,
                axisTicks: {
                    show: true,
                },
                title: {
                    text: chartParams.yAxisTitle2,
                },
                labels: {
                    formatter: function (val) {
                        return chartParams.valuePrefix2 + Math.round(val * 10) / 10 + '' + chartParams.valueSuffix2
                    },
                },
            },
        ],
        tooltip: {
            shared: true,
        },
    }

    return (
        <>
            <Chart options={options as ApexOptions} series={options.series} height={height} />

            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                :global(.apexcharts-legend-marker) {
                    margin-right: 8px !important;
                }
                :global(.apexcharts-tooltip) {
                    padding-bottom: 6px;
                }
                :global(.apexcharts-tooltip-title) {
                    background-color: #fff !important;
                    border-bottom-color: #eee !important;
                    padding: 6px 16px !important;
                    font-weight: 700;
                }
                :global(.apexcharts-tooltip-series-group) {
                    padding: 0 16px !important;
                }
                :global(.apexcharts-tooltip-marker) {
                    width: 10px;
                    height: 10px;
                }
            `}</style>
        </>
    )
}
