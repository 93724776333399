import BasePageLayoutOM from '../../BasePageLayoutOM'
import ReportSection from '../../ReportSection'

import React from 'react'
import { Housing } from '../../../../expose/housing/Housing'
import { NeighborhoodEmployment } from '../../../../expose/neighborhood/NeighborhoodEmployment'
import { ApexBarHorizontalChart } from '../../../../../charts/ApexBarHorizontalChart'
import numeral from 'numeral'

export const EmploymentWrapper = (props: any) => {
    const { industry, occupation } = props.employmentIndustryAndOccupation

    const industryTotal =
        industry?.reduce((accumulator, item) => {
            return accumulator + item.value
        }, 0) || 0

    const occupationTotal =
        occupation?.reduce((accumulator, item) => {
            return accumulator + item.value
        }, 0) || 0

    const industryTitles = []
    const industryValues = []

    const occupationTitles = []
    const occupationValues = []

    let topIndustries = []
    let topOccupation = []

    if (industry) {
        for (const item of industry || []) {
            industryTitles.push(item.label)
            industryValues.push(+((item.value / industryTotal) * 100).toFixed(2))
        }

        topIndustries = [...industry].sort((a, b) => b.value - a.value).slice(0, 3)
    }

    if (occupation) {
        for (const item of occupation || []) {
            occupationTitles.push(item.label)
            occupationValues.push(+((item.value / occupationTotal) * 100).toFixed(2))
        }

        topOccupation = [...occupation].sort((a, b) => b.value - a.value).slice(0, 3)
    }

    const employmentStats = props?.employmentStats
    return (
        <>
            {props.employmentIndustryAndOccupation && props.employmentStats && (
                <>
                    <div className={'reportPage page a4-150dpiSpecialOM'}>
                        <BasePageLayoutOM title={'Market Overview - Employment'} subtitle={props.headerSubtitle}>
                            <div className={'expose-wrapper white-theme'}>
                                <main>
                                    <div className={'cblock'} style={{ marginTop: '-80px' }}>
                                        <ReportSection
                                            title={null}
                                            className={'Employment'}
                                            titleSmall={true}
                                            style={{ position: 'relative', top: '-50px' }}
                                        >
                                            <h2 id="employmentoccupation1" className="mt-5 mb-1 ">
                                                Employment by Occupation
                                            </h2>

                                            <div
                                                className="chart chart--occupations "
                                                id="chart--occupations"
                                                style={{ left: '-30px' }}
                                            >
                                                <ApexBarHorizontalChart
                                                    values={occupationValues}
                                                    titles={occupationTitles}
                                                />
                                            </div>

                                            <p id="emplymentcity" className=" mt-1 text-muted">
                                                This chart illustrates the share breakdown of the primary jobs held by
                                                residents of {props.city}
                                            </p>

                                            <div id="topempo" className="row mt-5">
                                                <div className="col-lg-6">
                                                    <h3 className="mb-3 ">Most Common</h3>
                                                    <p id="topempol" className="">
                                                        The most common job groups, by number of people living in{' '}
                                                        {props.city}, are:
                                                    </p>
                                                </div>
                                            </div>

                                            <div
                                                id="topempc"
                                                className="grid grid--employment-alt mt-2 markPdfPageBreak"
                                            >
                                                {topOccupation.map((item, i) => {
                                                    return (
                                                        <div className="grid__item " key={i}>
                                                            <div className="grid__primary">
                                                                <p id="topempc1a" className="stat-val">
                                                                    {numeral(+item.value).format('0,0')} people
                                                                </p>
                                                                <p id="topempc1b" className="stat-desc">
                                                                    {item.label}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </ReportSection>
                                    </div>
                                </main>
                            </div>
                        </BasePageLayoutOM>
                    </div>

                    <div className={'reportPage page a4-150dpiSpecialOM'}>
                        <BasePageLayoutOM title={'Market Overview - Employment'} subtitle={props.headerSubtitle}>
                            <div className={'expose-wrapper white-theme'}>
                                <main>
                                    <div className={'cblock'} style={{ marginTop: '-80px' }}>
                                        <ReportSection
                                            title={null}
                                            className={'Employment'}
                                            titleSmall={true}
                                            style={{ position: 'relative', top: '-50px' }}
                                        >
                                            <h2 id="empindustrytitle" className="mt-5 mb-1 ">
                                                Employment by Industries
                                            </h2>

                                            <div
                                                className="chart chart--industries "
                                                id="chart--industries"
                                                style={{ left: '-30px' }}
                                            >
                                                <ApexBarHorizontalChart
                                                    values={industryValues}
                                                    titles={industryTitles}
                                                />
                                            </div>

                                            <div id="empindustrydiv" className="row mt-4 ">
                                                <div className="col-lg-6">
                                                    <p id="empindustryliner" className=" mt-3">
                                                        The most common industries in {props.city}, by number of
                                                        employees, are:
                                                    </p>
                                                </div>
                                            </div>

                                            <div id="topind1" className="grid grid--employment-alt mt-2">
                                                {topIndustries.map((item, i) => {
                                                    return (
                                                        <div className="grid__item " key={i}>
                                                            <div className="grid__primary">
                                                                <p id="topempc1a" className="stat-val">
                                                                    {numeral(item.value).format('0,0')} people
                                                                </p>
                                                                <p id="topempc1b" className="stat-desc">
                                                                    {item.label}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div
                                                className="distancer"
                                                style={{
                                                    marginTop: '0 !important',
                                                    height: '0px',
                                                    marginBottom: '0 !important',
                                                }}
                                            />
                                            <div id="mostspec1" className="row mt-5">
                                                <div className="col-lg-6">
                                                    <h3 className=" mb-3">Most Specialized</h3>
                                                    <p id="mostspecliner" className="">
                                                        Compared to other places, {props.city} has an high number of:
                                                    </p>
                                                </div>
                                            </div>

                                            <div
                                                id="mostspecgrid"
                                                className="grid grid--employment-alt mt-2 markPdfPageBreak"
                                            >
                                                <div className="grid__item ">
                                                    <div className="grid__primary">
                                                        <p id="mostspec1a" className="stat-val">
                                                            {employmentStats[1]}
                                                            <span className="small stacked">
                                                                times higher than expected
                                                            </span>
                                                        </p>
                                                        <p id="mostspec1b" className="stat-desc">
                                                            {employmentStats[0]}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="grid__item ">
                                                    <div className="grid__primary">
                                                        <p id="mostspec2a" className="stat-val">
                                                            {employmentStats[3]}{' '}
                                                            <span className="small stacked">
                                                                times higher than expected
                                                            </span>
                                                        </p>
                                                        <p id="mostspec2b" className="stat-desc">
                                                            {employmentStats[2]}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </ReportSection>
                                    </div>
                                </main>
                            </div>
                        </BasePageLayoutOM>
                    </div>
                    {props?.employment?.employmentCompanies?.length > 0 && (
                        <div className={'reportPage page a4-150dpiSpecialOM'}>
                            <BasePageLayoutOM title={'Market Overview - Employment'} subtitle={props.headerSubtitle}>
                                <div className={'expose-wrapper white-theme'}>
                                    <main>
                                        <div className={'cblock'} style={{ marginTop: '-80px' }}>
                                            <ReportSection title={null} className={'Employment'} titleSmall={true}>
                                                <div id="mostspecdist" className="distancer markPdfPageBreak" />

                                                <div className="row">
                                                    <div className="col-lg-6 mt-5">
                                                        <p id="employersavailable" className="cblock__highlight ">
                                                            Here are some of the major employers available in{' '}
                                                            {props.city}:
                                                        </p>
                                                    </div>
                                                </div>

                                                <div id="employmentCompanies" className="grid grid--companies mt-3">
                                                    {props?.employment?.employmentCompanies?.map((item, i) => (
                                                        <div className="grid__item" key={i}>
                                                            <a
                                                                href={item.url}
                                                                target="_blank"
                                                                rel={'noopener noreferrer'}
                                                            >
                                                                <img
                                                                    src={item?.img?.replace('/dev', '')}
                                                                    alt={item.name}
                                                                />
                                                            </a>
                                                        </div>
                                                    ))}
                                                </div>
                                            </ReportSection>
                                        </div>
                                    </main>
                                </div>
                            </BasePageLayoutOM>
                        </div>
                    )}
                </>
            )}
        </>
    )
}
