import BasePageLayoutOM from '../../BasePageLayoutOM'
import ReportSection from '../../ReportSection'
import { AreaInformationContent } from '../../../../expose/areainformation/AreaInformationContent'
import React from 'react'
import { NeighborhoodCrime } from '../../../../expose/neighborhood/NeighborhoodCrime'
import { capitalize } from '../../../../../../utils'

export const CrimeWrapper = (props: any) => {
    let address: string = props.dealOutline.address
    address = address?.replace(/[0-9]/g, '')?.toLowerCase()
    // @ts-ignore
    address = address?.split(',')
    if (address?.[address.length - 2]?.[0] == ' ') {
        // @ts-ignore
        address[address.length - 2] = address[address.length - 2].substr(1)
    }
    let city = address?.[address.length - 2]
    city = city?.charAt(0)?.toUpperCase() + city?.slice(1)
    city = city ? capitalize(city) : ''
    return (
        <>
            {props?.crimeInfo && (
                <div className={'reportPage page a4-150dpiSpecialOM'}>
                    <BasePageLayoutOM title={'Market Overview - Crime'} subtitle={props.headerSubtitle}>
                        <div className={'expose-wrapper white-theme'}>
                            <main>
                                <div className={'cblock'} style={{ marginTop: '-80px' }}>
                                    <ReportSection
                                        title={null}
                                        className={'Crime'}
                                        titleSmall={true}
                                        style={{ position: 'relative', top: '-130px' }}
                                    >
                                        <NeighborhoodCrime
                                            crimeInfo={props.crimeInfo}
                                            location={props.location}
                                            dealOutline={props.dealOutline}
                                            city={city}
                                            noTitle={true}
                                        />
                                    </ReportSection>
                                </div>
                            </main>
                        </div>
                    </BasePageLayoutOM>
                </div>
            )}
        </>
    )
}
