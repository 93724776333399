import React from 'react'
import { ReduxStoreState } from '../../../../../store'
import { useSelector } from 'react-redux'
import { ApexChart } from '../../../../charts/ApexChart'
import { createLinearChartOptions, createVerticalBarChartOptions } from './MortgageRecords'
export function TransactionOverview(props: any) {
    const transactionsSelector = useSelector(
        (state: ReduxStoreState) => state.minisite.propertyEnrichments.transactions,
    )
    const pastTransactionsChartVisibleSelector = useSelector(
        (state: ReduxStoreState) => +state.minisite?.propertyEnrichments?.pastTransactionsChartVisible === 1,
    )
    const pastTransactionsChartTypeSelector = useSelector(
        (state: ReduxStoreState) => state.minisite?.propertyEnrichments?.pastTransactionsChartType,
    )

    const pastTransactionsChartVisible =
        pastTransactionsChartVisibleSelector || +props?.propertyEnrichments?.pastTransactionsChartVisible || false

    const pastTransactionsChartType =
        pastTransactionsChartTypeSelector || props?.propertyEnrichments?.pastTransactionsChartType || 'linear'

    const transactions = transactionsSelector || props?.propertyEnrichments?.transactions || []
    //past transactions chart
    const barChartData = {
        series: [],
        labels: [],
    }
    const linearChartData = {
        labels: [],
        series: [
            {
                name: '',
                data: [],
            },
        ],
    }
    const chartData = []
    if (pastTransactionsChartVisible) {
        for (let k = 0; k < transactions.length; k++) {
            const date = new Date(Date.parse(transactions[k].transactions__date))
            chartData.push({ col: date, row: +transactions[k].saleprice })
            linearChartData.labels.push(date)
            linearChartData.series[0].data.push(+transactions[k].saleprice)
        }

        chartData.sort(function (a, b) {
            return a.col - b.col
        })
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

        for (let j = 0; j < chartData.length; j++) {
            chartData[j].col =
                monthNames[chartData[j].col.getMonth()] +
                ' ' +
                chartData[j].col.getDate() +
                ',' +
                chartData[j].col.getFullYear()
        }

        // console.log(project)
        // console.log('mort', chartData)
        // console.log('project.pastTransactionsChartType', project.pastTransactionsChartType)

        if (pastTransactionsChartType === 'linear') {
            //   createLinearChart(chartAchor, chartData, '', 'Sale price')
        }

        if (pastTransactionsChartType === 'bar') {
            for (let ii = 0; ii < chartData.length; ii++) {
                barChartData.labels.push(chartData[ii].col)
                barChartData.series.push(chartData[ii].row)
            }

            // console.log(barChartData)

            //createVerticalBarChart(chartAchor, barChartData)
        }

        //end past transactions chart
    }
    if (transactions.length == 0) return null
    return (
        <section className="cblock" id="trans">
            <div className="container">
                <div className="row">
                    {!props?.noTitle && (
                        <div className="cblock__head">
                            <p className="cblock__sect ">
                                <span>Transaction History</span>
                            </p>
                        </div>
                    )}

                    <div id="propertyTransactions" className="cblock__body">
                        {transactions.map((transaction, i) => (
                            <ul key={`${i}`} className="list-unstyled list-transactions ">
                                <li>
                                    <p className="list-transactions__date">
                                        <span className="text-muted">Recorded Date</span>
                                        {transaction.transactions__date}
                                    </p>
                                    <p className="list-transactions__buyer">
                                        <span className="text-muted">Buyer</span>
                                        {transaction.buyer}
                                    </p>
                                    <p className="list-transactions__seller">
                                        <span className="text-muted">Seller</span>
                                        {transaction.seller}
                                    </p>
                                    <p className="list-transactions__price">
                                        <span className="text-muted">Sale Price</span>$
                                        {transaction.saleprice
                                            ? parseInt(transaction.saleprice).toLocaleString()
                                            : '--'}
                                    </p>
                                </li>
                            </ul>
                        ))}
                        <p className="small text-muted animatedin">Source: REIS</p>
                    </div>
                    {pastTransactionsChartVisible && (
                        <div id="property-transactions-chart" className="cblock__body">
                            {pastTransactionsChartType === 'linear' && (
                                <ApexChart
                                    type={'line'}
                                    series={linearChartData.series}
                                    options={createLinearChartOptions(chartData, '', 'Sale price')}
                                />
                            )}
                            {pastTransactionsChartType === 'bar' && (
                                <ApexChart
                                    type={'bar'}
                                    series={linearChartData.series}
                                    options={createVerticalBarChartOptions(barChartData, {})}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}
