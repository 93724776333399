import React from 'react'
import dynamic from 'next/dynamic'
import { CHART_COLOR } from '../../utils/constants/chartColors'
import { ApexOptions } from 'apexcharts'
// eslint-disable-next-line @typescript-eslint/naming-convention
const Chart = dynamic(() => import('react-apexcharts'), {
    ssr: false,
})

type ChartData = {
    name: string
    type: string
    data: Array<number>
}

type Props = {
    data: Array<ChartData>
    labels: Array<string | number>
    title?: string
}
export function ApexBarLinearChart(props: Props) {
    const options: any = {
        series: props.data,
        options: {
            chart: {
                height: 350,
                type: 'line' as any,
                stacked: false,
                toolbar: {
                    show: false,
                },
            },
            colors: CHART_COLOR.RED,
            dataLabels: {
                enabled: false,
            },
            stroke: {
                width: [1, 1, 4],
                curve: 'smooth',
            },
            title: {
                text: props.title || '',
                align: 'left',
                offsetX: 110,
                style: {
                    color: '#fff',
                },
            },
            xaxis: {
                categories: props.labels,
            },
            yaxis: [
                {
                    seriesName: 'Demand',
                    opposite: true,
                    title: {
                        text: 'Number of units',
                        style: {
                            color: '#ffffff',
                            opacity: 1,
                        },
                    },
                    labels: {
                        formatter: (v) => Math.round(+v),
                    },
                },
                { seriesName: 'New supply', opposite: false, show: false },
                {
                    seriesName: 'Occupancy',
                    opposite: false,
                    title: {
                        text: 'Occupancy %',
                        style: {
                            color: '#ffffff',
                            opacity: 1,
                        },
                    },
                    labels: {
                        formatter: (v) => Math.round(+v),
                    },
                },
            ],
            tooltip: {
                enabled: true,
                theme: 'dark',
            },
            legend: {
                position: 'top' as any,
                horizontalAlign: 'right',
                offsetX: 40,
                labels: {
                    colors: ['#fff'],
                    // useSeriesColors: true,
                },
            },
        },
    }
    const year = new Date().getFullYear()
    return (
        <>
            <Chart options={options.options as ApexOptions} series={options.series} type="line" height={350} />
            Data after year {year} is forecast
        </>
    )
}
