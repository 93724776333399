import React, { useCallback } from 'react'

type Props = {
    items: Array<{
        name: string
        color: string
    }>
}

export function Legend(props: Props) {
    const { items } = props
    return (
        <div className="legend">
            {items.map((item, ind) => (
                <div className="legend-item" key={ind}>
                    <div className="legend-dot" style={{ backgroundColor: item.color }}></div>
                    <div className="legend-name">{item.name}</div>
                </div>
            ))}

            {/*language=scss*/}
            <style jsx global>{`
                @import './src/scss/colors.scss';

                .legend {
                    display: inline-flex;
                    align-items: center;
                    flex-gap: 30px;
                    gap: 30px;
                    &-item {
                        display: flex;
                        align-items: center;
                    }
                    &-dot {
                        flex: 0 0 auto;
                        width: 8px;
                        height: 8px;
                        background-color: $grayish-blue;
                        border-radius: 8px;
                        margin-right: $spacing-base;
                    }
                    &-name {
                        font-size: 0.9rem;
                        color: $black;
                        line-height: 1.3em;
                    }
                }
            `}</style>
        </div>
    )
}
