import React from 'react'
import { DealOutline } from '../../../../store/types/fullproject'
import { MapAdapter } from '../../../defaultElements/MapAdapter'
import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel'
const numeral = require('numeral')

type School = {
    name: string
    type: string
    location: {
        lat: number
        lng: number
    }
}

type Props = {
    dealOutline: DealOutline
    demographic: string
    city: string
    schools: {
        [key: string]: any
        schoolCount: number
        studentCount: number
        elementary: Array<School>
        middle: Array<School>
        high: Array<School>
    }
    noTitle?: boolean
}

export function NeighborhoodEducation(props: Props) {
    const demographic = props?.demographic?.split(';') || []

    //console.log('{props}', props)
    return (
        <section className="cblock markPdfPageBreak" id="schools">
            <div className="container">
                <div className="row">
                    {!props.noTitle && (
                        <p className="cblock__sect ">
                            <span>Education</span>
                        </p>
                    )}
                    <div id="schoolcontainer" className="cblock__body">
                        <div className="row">
                            <div className="col-lg-6">
                                <h2 id="schoolliner" className="" style={{ width: '125%' }}>
                                    Best Schools in {props.city}
                                </h2>
                            </div>
                        </div>

                        <div className="boxes--schools">
                            <div className="box ">
                                <div className="box__head">
                                    <h5 className="m-0">Elementary Schools</h5>
                                </div>
                                <div className="box__body text-justify">
                                    <ul className="list-unstyled list-withicons">
                                        {demographic[6] && (
                                            <li id="school1">
                                                <i className="seeicon-school list-picto"></i>
                                                {demographic[6]}
                                            </li>
                                        )}
                                        {demographic[7] && (
                                            <li id="school2">
                                                <i className="seeicon-school list-picto"></i>
                                                {demographic[7]}
                                            </li>
                                        )}
                                        {demographic[8] && (
                                            <li id="school1">
                                                <i className="seeicon-school list-picto"></i>
                                                {demographic[8]}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>

                            <div className="box ">
                                <div className="box__head">
                                    <h5 className="m-0">Middle Schools</h5>
                                </div>
                                <div className="box__body">
                                    <ul className="list-unstyled list-withicons">
                                        {demographic[9] && (
                                            <li id="school4">
                                                <i className="seeicon-school list-picto"></i>
                                                {demographic[9]}
                                            </li>
                                        )}
                                        {demographic[10] && (
                                            <li id="school5">
                                                <i className="seeicon-school list-picto"></i>
                                                {demographic[10]}
                                            </li>
                                        )}
                                        {demographic[11] && (
                                            <li id="school6">
                                                <i className="seeicon-school list-picto"></i>
                                                {demographic[11]}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>

                            <div className="box ">
                                <div className="box__head">
                                    <h5 className="m-0">High Schools</h5>
                                </div>
                                <div className="box__body">
                                    <ul className="list-unstyled list-withicons">
                                        {demographic[12] && (
                                            <li id="school7">
                                                <i className="seeicon-school list-picto"></i>
                                                {demographic[12]}
                                            </li>
                                        )}
                                        {demographic[13] && (
                                            <li id="school8">
                                                <i className="seeicon-school list-picto"></i>
                                                {demographic[13]}
                                            </li>
                                        )}
                                        {demographic[14] && (
                                            <li id="school9">
                                                <i className="seeicon-school list-picto"></i>
                                                {demographic[14]}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="row my-5 text-justify">
                            <div className="col-lg-6">
                                <p id="schoolliner2" className="">
                                    {demographic[5]}
                                </p>
                            </div>
                            {/*{demographic?.[15] && demographic?.[15] !== 'none' && (*/}
                            {/*    <div className="col-lg-6">*/}
                            {/*        <p id="schoolliner3" className="">*/}
                            {/*            {demographic[15]}*/}
                            {/*        </p>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </div>

                        <div className="cblock__fullimg">
                            <div id="googlemap_schools" style={{ width: '100%', height: 700 }}>
                                <MapAdapter defaultZoom={15} defaultCenter={props?.schools?.mapCenter} height={700}>
                                    <MarkerWithLabel
                                        position={props?.schools?.mapCenter}
                                        labelAnchor={{ x: 60, y: 0 }}
                                        icon={'http://maps.google.com/mapfiles/ms/micons/green-dot.png'}
                                    >
                                        <div
                                            style={{
                                                fontSize: '0.65rem',
                                                padding: '3px',
                                                color: '#000',
                                                backgroundColor: '#fff',
                                                maxWidth: 120,
                                                fontWeight: 700,
                                                textAlign: 'center',
                                            }}
                                        >
                                            {props.dealOutline.address}
                                        </div>
                                    </MarkerWithLabel>

                                    {props?.schools?.elementary.map((school: School, i) => {
                                        return (
                                            <MarkerWithLabel
                                                key={`${school.name}${i}`}
                                                position={school.location}
                                                labelAnchor={{ x: 60, y: 0 }}
                                            >
                                                <div
                                                    style={{
                                                        fontSize: '0.65rem',
                                                        padding: '3px',
                                                        color: '#000',
                                                        backgroundColor: '#fff',
                                                        maxWidth: 120,
                                                        fontWeight: 700,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {school.name}
                                                </div>
                                            </MarkerWithLabel>
                                        )
                                    })}

                                    {props?.schools?.middle.map((school: School, i) => {
                                        return (
                                            <MarkerWithLabel
                                                key={`${school.name}${i}`}
                                                position={school.location}
                                                labelAnchor={{ x: 60, y: 0 }}
                                            >
                                                <div
                                                    style={{
                                                        fontSize: '0.65rem',
                                                        padding: '3px',
                                                        color: '#000',
                                                        backgroundColor: '#fff',
                                                        maxWidth: 120,
                                                        fontWeight: 700,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {school.name}
                                                </div>
                                            </MarkerWithLabel>
                                        )
                                    })}

                                    {props?.schools?.high.map((school: School, i) => {
                                        return (
                                            <MarkerWithLabel
                                                key={`${school.name}${i}`}
                                                position={school.location}
                                                labelAnchor={{ x: 60, y: 0 }}
                                            >
                                                <div
                                                    style={{
                                                        fontSize: '0.65rem',
                                                        padding: '3px',
                                                        color: '#000',
                                                        backgroundColor: '#fff',
                                                        maxWidth: 120,
                                                        fontWeight: 700,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {school.name}
                                                </div>
                                            </MarkerWithLabel>
                                        )
                                    })}
                                </MapAdapter>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*language=scss*/}
            <style jsx>{`
                #schools {
                }
            `}</style>
        </section>
    )
}
