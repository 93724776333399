import React from 'react'
import dynamic from 'next/dynamic'
import { ApexOptions } from 'apexcharts'
import numeral from 'numeral'
// eslint-disable-next-line @typescript-eslint/naming-convention
const Chart = dynamic(() => import('react-apexcharts'), {
    ssr: false,
})

type Props = {
    series: Array<{
        name: string
        type: string
        data: number[]
    }>
    categories: string[]
    colors: string[]
    height?: number
}

export function ApexPerformanceGrowthChart({ series, height, categories, colors }: Props) {
    const options = {
        series: series,
        chart: {
            height: height ? height : 350,
            type: 'line',
            stacked: false,
            toolbar: {
                show: false,
            },
        },
        colors: colors,
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'straight',
            dashArray: [0, 0, 0, 5, 5, 5],
        },
        legend: {
            horizontalAlign: 'left',
            position: 'top',
            floating: false,
            markers: {
                width: 8,
                height: 8,
            },
            itemMargin: {
                horizontal: 8,
            },
        },
        markers: {
            size: 0,
            hover: {
                sizeOffset: 6,
            },
        },
        xaxis: {
            categories: categories,
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val + `${val !== 0 ? '%' : ''}`
                },
            },
        },
        tooltip: {
            y: {
                formatter: function (value) {
                    if (value === 0 || !!value) {
                        return numeral(value / 100).format('0.[00]%')
                    } else {
                        return null
                    }
                },
            },
        },
    }

    return (
        <>
            <div style={{ flex: 1 }}>
                <Chart
                    options={options as ApexOptions}
                    series={options.series}
                    height={height}
                    type="line"
                    width="100%"
                />
            </div>

            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                :global(.apexcharts-legend-marker) {
                    margin-right: 8px !important;
                }
                :global(.apexcharts-tooltip) {
                    padding-bottom: 6px;
                }
                :global(.apexcharts-tooltip-title) {
                    background-color: #fff !important;
                    border-bottom-color: #eee !important;
                    padding: 6px 16px !important;
                    font-weight: 700;
                }
                :global(.apexcharts-tooltip-series-group) {
                    padding: 0 16px !important;
                }
                :global(.apexcharts-tooltip-marker) {
                    width: 10px;
                    height: 10px;
                }
            `}</style>
        </>
    )
}
