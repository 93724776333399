import React, { useEffect, useMemo, useState } from 'react'
import getImgPath from '../../../../utils/dealnarrative/getImgPath'
import Logo from '../../../../theme/dealnarrative/icons/NorthMARQ logo.png'
import SeecaresLogo from '../../../../theme/dealnarrative/icons/Seecares logo.png'
import dateformat from 'dateformat'
import { IMAGES } from '../../../../utils/constants/assets'
import nmlogo from '../../dashboard/tabs/Fannie/quote/northmarq_logo.png'
import { ReduxHelper } from '../../../../store'

type Props = {
    narrative: any
}

export default function OpeningPage(props: Props) {
    const { narrative } = props
    const [accountlogo, setLogo] = useState({ alt: '', src: '' })
    useEffect(() => {
        // init google analytics
        const accountLogoUrl = localStorage?.getItem('accountLogoUrl')

        if (accountLogoUrl && accountLogoUrl !== 'null') {
            setLogo(() => ({
                alt: localStorage.getItem('accountName') || '',
                src: '/v1/' + accountLogoUrl || '',
            }))
        } else {
            setLogo(() => ({
                alt: 'Seecares',
                src: IMAGES.SEECARES_LOGO,
            }))
        }
        //setUsername(localStorage.getItem('username') || '')
    }, [])

    const [isNM, setIsNM] = useState(false)
    useEffect(() => {
        if (localStorage) {
            const accountName = localStorage.getItem('accountName')
            if (accountName?.toLowerCase().indexOf('northmarq') > -1) setIsNM(true)
            if (accountName?.toLowerCase().indexOf('Kobi test') > -1) setIsNM(true)
        }
    }, [])

    const logoStyle: any = {}
    if (!isNM) {
        logoStyle.marginTop = '50%'
    }

    return (
        <div className="OpeningReportPage d-flex flex-column">
            <div className={'projectImageArea'} style={{ height: '150px' }}>
                <div className={'logoOverlay'}>
                    <img
                        style={logoStyle}
                        src={isNM ? Logo : accountlogo.src || IMAGES.SEECARES_LOGO}
                        alt={isNM ? 'NorthMARQ logo' : accountlogo.alt}
                    />
                </div>
            </div>
            {narrative?.projectImage && (
                <div className={'projectImageArea'}>
                    <img
                        src={getImgPath(narrative?.projectImage, narrative.projectId)}
                        alt={'Project Image'}
                        style={{ width: '100%' }}
                    />
                </div>
            )}
            <div className={'reportTitleArea'}>
                {narrative?.dealType && <div className={'dealType'}>{narrative?.dealType}</div>}
                {narrative?.dealName && <h1 className={'dealName'}>{narrative?.dealName}</h1>}
                {narrative?.propertyAddress && <h2 className={'dealAddress'}>{narrative?.propertyAddress}</h2>}
            </div>
            <div className={'generatedArea dateMention d-flex justify-content-between'}>
                <div className={'date'}>{`Report generated ${dateformat(new Date(), 'mmmm dd, yyyy')}`}</div>
                <div className={'poweredBy'}>
                    Powered by <img src={SeecaresLogo} alt={'Seecares Logo'} />
                </div>
            </div>
        </div>
    )
}
