import React from 'react'
import { InsightsRentTrend } from '../../../../operating-assumptions/submarket-insights/InsightsRentTrend'
import BasePageLayoutOM from '../../BasePageLayoutOM'
import ReportSection from '../../ReportSection'
import { AskingRentDistributionOM } from './AskingRentDistributionOM'
import { VacancyDistribuionOM } from './VacancyDistributionOM'

type Props = {}

export const InsightsComparisonOM = (props: any) => {
    return (
        <>
            <div className={'reportPage page a4-150dpiSpecialOM'}>
                <BasePageLayoutOM title={'SubMarket Insights'} subtitle={props.headerSubtitle}>
                    <ReportSection
                        title={null}
                        className={'Comparison'}
                        titleSmall={true}
                        style={{ position: 'relative', left: '-20px' }}
                    >
                        <h3 className=" mb-3">Rent and Vacancy Trends</h3>
                        <InsightsRentTrend />
                    </ReportSection>
                </BasePageLayoutOM>
            </div>

            <AskingRentDistributionOM isOM={true} headerSubtitle={props.headerSubtitle} />

            <VacancyDistribuionOM isOM={true} headerSubtitle={props.headerSubtitle} />

            {/*language=scss*/}
            <style jsx global>
                {`
                    .main-row {
                        margin-bottom: 40px;
                        width: 100%;
                    }
                `}
            </style>
        </>
    )
}
