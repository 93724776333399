import BasePageLayoutOM from '../../BasePageLayoutOM'
import ReportSection from '../../ReportSection'
import { AreaInformationContent } from '../../../../expose/areainformation/AreaInformationContent'
import React from 'react'
import { NeighborhoodCrime } from '../../../../expose/neighborhood/NeighborhoodCrime'
import { capitalize } from '../../../../../../utils'
import { NeighborhoodEducation } from '../../../../expose/neighborhood/NeighborhoodEducation'

export const SchoolsWrapper = (props: any) => {
    let address: string = props.dealOutline.address
    address = address?.replace(/[0-9]/g, '')?.toLowerCase()
    // @ts-ignore
    address = address?.split(',')
    if (address?.[address.length - 2]?.[0] == ' ') {
        // @ts-ignore
        address[address.length - 2] = address[address.length - 2].substr(1)
    }
    let city = address?.[address.length - 2]
    city = city?.charAt(0)?.toUpperCase() + city?.slice(1)
    city = city ? capitalize(city) : ''
    const state = address?.[address.length - 1]?.toUpperCase() || ''
    const fullAddress = `${city} ,${state}`
    return (
        <>
            {props.dealOutline &&
                props.demographic &&
                (props.schools?.elementary?.length > 0 ||
                    props.schools?.high?.length > 0 ||
                    props.schools?.middle?.length > 0) && (
                    <div className={'reportPage page a4-150dpiSpecialOM'}>
                        <BasePageLayoutOM title={'Market Overview - Education'} subtitle={props.headerSubtitle}>
                            <div className={'expose-wrapper white-theme'}>
                                <main>
                                    <div className={'cblock'} style={{ marginTop: '-80px' }}>
                                        <ReportSection
                                            title={null}
                                            className={'Education'}
                                            titleSmall={true}
                                            style={{ position: 'relative', top: '-130px' }}
                                        >
                                            <NeighborhoodEducation
                                                dealOutline={props.dealOutline}
                                                demographic={props.demographic}
                                                schools={props.schools}
                                                city={fullAddress}
                                                noTitle={true}
                                            />
                                        </ReportSection>
                                    </div>
                                </main>
                            </div>
                        </BasePageLayoutOM>
                    </div>
                )}
        </>
    )
}
