import React, { useMemo, useState } from 'react'
import { RentComparablesPropertyTable } from '../../../expose/rentComparables/RentComparablesPropertyTable'
import { RentComparablesFloorplanTable } from '../../../expose/rentComparables/RentComparablesFloorplanTable'

import { ComparablesMap } from '../../../expose/rentComparables/ComparablesMap'
import { SalesComparables } from '../../../expose/rentComparables/SalesComparables'

import { RentComparablesTable } from '../../../expose/rentComparables/tables/RentComparablesTable'
import { useRouter } from 'next/router'
import { getDistanceInMiles } from '../../../../../utils/getDistance'
import BasePageLayoutOM from '../BasePageLayoutOM'
import ReportSection from '../ReportSection'
import { SaleComparableTable } from '../../../expose/rentComparables/tables/SaleComparablesTable'
const cloneDeep = require('lodash.clonedeep')
const perChunk = 12 // items per chunk
function splitArrToChunck(inputArray: any, perChunk: number) {
    const result = inputArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk)

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
    }, [])
    return result
}

function splitArrToChunckByRows(inputArray: any, maxRows: number, propertyName: string) {
    const titleLength = 6
    const result = []
    let tmpArr = []
    let tmprows = 0
    for (const tmp of inputArray) {
        if (tmprows + tmp[propertyName].length + titleLength > maxRows) {
            result.push(cloneDeep(tmpArr))
            tmpArr = []
            tmprows = 0
        }
        tmpArr.push(tmp)
        tmprows = tmprows + tmp[propertyName].length + titleLength
    }
    if (tmpArr.length > 0) result.push(cloneDeep(tmpArr))
    return result
}

export function Comparables(props: any) {
    const projectId = props?.projectId
    const { rentComparables, dealOutline } = props
    const compsOnly = props.config?.comps_only || false

    const comps = rentComparables?.propertyComparables || []
    const compsArr = splitArrToChunck(comps, perChunk)

    const saleComps = props.comparables?.comparables.filter((item) => item.isSaleComp == '1')
    const saleCompsArr = splitArrToChunck(saleComps, perChunk)

    const markers = useMemo(() => {
        const out = []

        if (rentComparables?.propertyComparables) {
            for (const comp of rentComparables?.propertyComparables) {
                let numberOfUnits = 0

                let totalEffectiveRent = 0
                let totalSf = 0

                if (comp?.units) {
                    for (const unit of comp?.units) {
                        // console.log(unit)
                        numberOfUnits += unit.unitsCount
                        totalEffectiveRent += unit?.effectiveAverageRent * unit.unitsCount
                        totalSf += unit?.sf * unit.unitsCount
                    }
                }

                const distance = getDistanceInMiles(props?.location, comp?.location)
                // console.log('comp', comp)
                out.push({
                    name: comp?.title,
                    address: comp?.address,
                    location: comp?.location,
                    image: comp?.image || null,
                    numberOfUnits,
                    yearBuilt: comp?.yearBuilt,
                    distance: distance || 0,
                    priceSf: totalEffectiveRent !== 0 && totalSf !== 0 ? totalEffectiveRent / totalSf : 0,
                })
            }
        }
        return out
    }, [rentComparables, props.location])

    const [highlightedRow, setHighlightedRow] = useState(null)
    const router = useRouter()

    const { printmode, printCSS } = useMemo(() => {
        let printmode = false
        let printCSS = ''
        if (true || router.asPath.indexOf('printmode=true') > -1) {
            printmode = true
            printCSS = '.row{display: block;flex: none;}'
        }
        return { printmode, printCSS }
    }, [router])
    /*
    const floorPlanComparablesArr = splitArrToChunck(rentComparables?.floorPlanComparables, 3)
    const propertyComparablesArr = splitArrToChunck(
        rentComparables?.propertyComparables.filter((c: any) => c.units?.length > 0),
        3,
    )
*/
    const floorPlanComparablesArr = splitArrToChunckByRows(rentComparables?.floorPlanComparables, 36, 'properties')
    const propertyComparablesArr = splitArrToChunckByRows(
        rentComparables?.propertyComparables.filter((c: any) => c.units?.length > 0),
        30,
        'units',
    )

    const salemarkers = useMemo(() => {
        const out = []
        if (props?.comparables?.comparables) {
            for (const comp of props.comparables.comparables) {
                if (comp?.isSaleComp === '1') {
                    out.push({
                        name: comp?.name,
                        address: comp?.address,
                        location: comp?.data?.location,
                        image: comp?.imgname || null,
                        salePrice: comp?.data?.saleDetails?.price,
                        distance: getDistanceInMiles(comp?.data?.location, props.location),
                    })
                }
            }
        }
        // console.log('out', out)
        return out
    }, [props, location])

    const [salehighlightedRow, setSaleHighlightedRow] = useState(null)
    return (
        <>
            {!props?.hideRentComps && (
                <>
                    {markers.length > 0 && (
                        <div className={'reportPage page a4-150dpiSpecialOM'}>
                            <BasePageLayoutOM title={'Comparables'} subtitle={props.headerSubtitle}>
                                <ReportSection
                                    title={'Rent Comparables'}
                                    className={'RentComparables'}
                                    titleSmall={true}
                                >
                                    <div className={'expose-wrapper white-theme'} style={{ marginTop: '-130px' }}>
                                        <main>
                                            <div className={'cblock'} style={{ marginTop: '100px' }}>
                                                <ComparablesMap
                                                    address={props?.dealOutline?.address}
                                                    location={props?.location}
                                                    markers={markers}
                                                    setHighlightedIndex={setHighlightedRow}
                                                    projectId={projectId}
                                                />
                                            </div>
                                        </main>
                                    </div>
                                </ReportSection>
                            </BasePageLayoutOM>
                        </div>
                    )}

                    {compsArr.map((c, cIdx) => (
                        <div className={'reportPage page a4-150dpiSpecialOM'} key={'compsR' + cIdx}>
                            <BasePageLayoutOM title={'Comparables'} subtitle={props.headerSubtitle}>
                                <ReportSection
                                    title={'Rent Comparables'}
                                    className={'RentComparables'}
                                    titleSmall={true}
                                >
                                    <div className={'expose-wrapper white-theme'} style={{ marginTop: '-130px' }}>
                                        <main>
                                            <div className={'cblock'}>
                                                <RentComparablesTable
                                                    location={props?.location}
                                                    name={dealOutline?.name}
                                                    rentComparables={comps || []}
                                                    model={props?.originationModel}
                                                    unitMix={props?.unitMix}
                                                    effectiveRent={rentComparables?.effectiveRent}
                                                    rentSf={rentComparables?.effectiveRentPerSqFt}
                                                    highlightedRow={highlightedRow}
                                                    allowDelete={props?.allowDelete}
                                                    chunkSize={perChunk}
                                                    tableStyle={{ marginLeft: '-63px' }}
                                                    chunk={cIdx}
                                                />
                                            </div>
                                        </main>
                                    </div>
                                </ReportSection>
                            </BasePageLayoutOM>
                        </div>
                    ))}

                    {floorPlanComparablesArr.map((floorPlanComparables, floorPlanComparablesIdx) => (
                        <div
                            className={'reportPage page a4-150dpiSpecialOM'}
                            key={'floorPlanComparablesIdx' + floorPlanComparablesIdx}
                        >
                            <BasePageLayoutOM title={'Comparables'} subtitle={props.headerSubtitle}>
                                <ReportSection
                                    title={'Rent Comparables'}
                                    className={'RentComparables'}
                                    titleSmall={true}
                                    style={{ marginTop: '-90px' }}
                                >
                                    <div className={'expose-wrapper white-theme'} style={{ marginTop: '-30px' }}>
                                        <main>
                                            <div className={'cblock'}>
                                                <div className={'row floorPlanComparablesSection'}>
                                                    {floorPlanComparables.map((floorPlanComparable, index) => {
                                                        return (
                                                            <div
                                                                className={(printmode ? '' : 'col-md-6') + ' p-3'}
                                                                key={index}
                                                            >
                                                                <RentComparablesFloorplanTable
                                                                    floorPlanComparable={floorPlanComparable}
                                                                    /*maxRowsPerTable={5}*/
                                                                    titleStyle={{ marginBottom: '10px' }}
                                                                />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </main>
                                    </div>
                                </ReportSection>
                            </BasePageLayoutOM>
                        </div>
                    ))}

                    {propertyComparablesArr.map((propertyComparables, propertyComparablesIdx) => (
                        <div
                            className={'reportPage page a4-150dpiSpecialOM'}
                            key={'floorPlanComparablesIdx' + propertyComparablesIdx}
                        >
                            <BasePageLayoutOM title={'Comparables'} subtitle={props.headerSubtitle}>
                                <ReportSection
                                    title={'Rent Comparables'}
                                    className={'RentComparables'}
                                    titleSmall={true}
                                    style={{ marginTop: '-90px' }}
                                >
                                    <div className={'expose-wrapper white-theme'} style={{ marginTop: '-30px' }}>
                                        <main>
                                            <div className={'cblock'}>
                                                <div className={'row'}>
                                                    {propertyComparables?.map((propertyComparable, index) => {
                                                        return (
                                                            <div
                                                                className={(printmode ? '' : 'col-md-6') + ' p-3'}
                                                                key={index}
                                                            >
                                                                <RentComparablesPropertyTable
                                                                    propertyComparable={propertyComparable}
                                                                    titleStyle={{ marginBottom: '10px' }}
                                                                />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </main>
                                    </div>
                                </ReportSection>
                            </BasePageLayoutOM>
                        </div>
                    ))}
                </>
            )}

            {!props?.hideSalesComps && (
                <>
                    {salemarkers.length > 0 && (
                        <div className={'reportPage page a4-150dpiSpecialOM'}>
                            <BasePageLayoutOM title={'Comparables'} subtitle={props.headerSubtitle}>
                                <ReportSection
                                    title={'Sales Comparables'}
                                    className={'SalesComparables'}
                                    titleSmall={true}
                                >
                                    <div className={'expose-wrapper white-theme'} style={{ marginTop: '-130px' }}>
                                        <main>
                                            <div className={'cblock'} style={{ marginTop: '100px' }}>
                                                <ComparablesMap
                                                    location={props?.location}
                                                    address={dealOutline?.address}
                                                    markers={salemarkers}
                                                    setHighlightedIndex={setSaleHighlightedRow}
                                                    projectId={projectId}
                                                />
                                            </div>
                                        </main>
                                    </div>
                                </ReportSection>
                            </BasePageLayoutOM>
                        </div>
                    )}

                    {saleCompsArr.map((c, cIdx) => (
                        <div className={'reportPage page a4-150dpiSpecialOM'} key={'compsS' + cIdx}>
                            <BasePageLayoutOM title={'Comparables'} subtitle={props.headerSubtitle}>
                                <ReportSection
                                    title={'Sales Comparables'}
                                    className={'SalesComparables'}
                                    titleSmall={true}
                                >
                                    <div className={'expose-wrapper white-theme'} style={{ marginTop: '-130px' }}>
                                        <main>
                                            <div className={'cblock'} style={{ marginTop: '100px' }}>
                                                <SaleComparableTable
                                                    name={dealOutline?.name}
                                                    comparables={props.comparables?.comparables}
                                                    model={props.originationModel}
                                                    location={props?.location}
                                                    highlightedRow={salehighlightedRow}
                                                    allowDelete={props.allowDelete}
                                                    chunkSize={perChunk}
                                                    tableStyle={{ left: '10px', position: 'relative' }}
                                                    chunk={cIdx}
                                                />
                                            </div>
                                        </main>
                                    </div>
                                </ReportSection>
                            </BasePageLayoutOM>
                        </div>
                    ))}
                </>
            )}

            {/*language=scss*/}
            <style>{printCSS}</style>

            {/*language=scss*/}
            <style jsx>{`
                @media print {
                    .floorPlanComparablesSection {
                        // left: -50px;
                        //position: relative;
                    }
                }
            `}</style>
        </>
    )
}
