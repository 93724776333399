import React from 'react'
import dynamic from 'next/dynamic'
import { ApexOptions } from 'apexcharts'
import numeral from 'numeral'
// eslint-disable-next-line @typescript-eslint/naming-convention
const Chart = dynamic(() => import('react-apexcharts'), {
    ssr: false,
})

type Props = {
    series: Array<{
        name: string
        type: string
        data: number[]
    }>
    categories: string[]
    colors: string[]
    height?: number
}

export function ApexUnitMixChart({ series, height, categories, colors }: Props) {
    const options = {
        series: series,
        chart: {
            height: height ? height : 350,
            type: 'bar',
            stacked: false,
            toolbar: {
                show: false,
            },
        },
        colors: colors,
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '40%',
                borderRadius: 2,
            },
        },
        legend: {
            horizontalAlign: 'left',
            position: 'top',
            floating: false,
            markers: {
                width: 8,
                height: 8,
            },
            itemMargin: {
                horizontal: 8,
            },
        },
        xaxis: {
            categories: categories,
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return '$' + val / 1000 + `${val !== 0 ? 'K' : ''}`
                },
            },
            title: {
                text: 'Avg Rent',
            },
        },
        tooltip: {
            y: {
                formatter: function (value) {
                    return numeral(value).format('$0,0')
                },
            },
        },
    }

    return (
        <>
            <Chart options={options as ApexOptions} series={options.series} height={height} type="bar" width="100%" />

            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                :global(.apexcharts-legend-marker) {
                    margin-right: 8px !important;
                }
                :global(.apexcharts-tooltip) {
                    padding-bottom: 6px;
                }
                :global(.apexcharts-tooltip-title) {
                    background-color: #fff !important;
                    border-bottom-color: #eee !important;
                    padding: 6px 16px !important;
                    font-weight: 700;
                }
                :global(.apexcharts-tooltip-series-group) {
                    padding: 0 16px !important;
                }
                :global(.apexcharts-tooltip-marker) {
                    width: 10px;
                    height: 10px;
                }
            `}</style>
        </>
    )
}
