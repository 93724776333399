import BasePageLayoutOM from '../../BasePageLayoutOM'
import ReportSection from '../../ReportSection'
import { AreaInformationContent } from '../../../../expose/areainformation/AreaInformationContent'
import React from 'react'

export const AreaInformation = (props: any) => {
    const initialValue = 5
    return (
        <>
            {props?.areaInformation?.enabled && (
                <div className={'reportPage page a4-150dpiSpecialOM'}>
                    <BasePageLayoutOM title={'Market Overview'} subtitle={props.headerSubtitle}>
                        <div className={'expose-wrapper white-theme'}>
                            <main>
                                <div className={'cblock'} style={{ marginTop: '-80px' }}>
                                    <ReportSection
                                        title={'Area Information'}
                                        className={'AreaInformation'}
                                        titleSmall={true}
                                    >
                                        <div style={{ marginBottom: '-50px' }}>{initialValue} Miles</div>
                                        <AreaInformationContent
                                            noTitle={true}
                                            areaInformation={props.areaInformation.marketAreas}
                                            initialValue={initialValue}
                                        />
                                    </ReportSection>
                                </div>
                            </main>
                        </div>
                    </BasePageLayoutOM>
                </div>
            )}
        </>
    )
}
