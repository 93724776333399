import React, { useMemo } from 'react'
import dynamic from 'next/dynamic'
import { ApexOptions } from 'apexcharts'
// eslint-disable-next-line @typescript-eslint/naming-convention
const Chart = dynamic(() => import('react-apexcharts'), {
    ssr: false,
})

export function ApexVacancyDistPropertyVsSubmarketChart(props: {
    labels: any[]
    values: any[]
    height?: number
    colors?: any[]
    highlightIndex: number
}) {
    const chartParams = {
        seriesTitle: 'Properties',
        yAxisTitle: 'Vacancy (%)',
        xAxisTitle: '',
        valuePrefix: '',
        valueSuffix: '',
    }

    const series = useMemo(() => {
        return [
            {
                name: 'Vacancy (%)',
                data: props.values.map((v, i) => (i != props.highlightIndex ? v : null)),
            },
            {
                name: 'The Property',
                data: props.values.map((v, i) => (i == props.highlightIndex ? v : null)),
            },
        ]
    }, [props.highlightIndex, props.values])
    const options = {
        series,
        chart: {
            type: 'bar' as any,
            height: props.height ? props.height : 350,
            stacked: true,
            toolbar: {
                show: false,
            },
        },
        colors: props.colors,
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '40%',
                borderRadius: 2,
            },
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'middle',
            offsetX: 0,
            style: {
                colors: ['#fff'],
            },
            formatter: function (val) {
                return val + '%'
            },
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: props.labels,
            title: {
                text: chartParams.xAxisTitle,
            },
        },
        yaxis: {
            title: {
                text: chartParams.yAxisTitle,
            },
            decimalsInFloat: 0,
        },
        fill: {
            opacity: 0.9,
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                },
            },
        },
        legend: {
            enabled: true,
            horizontalAlign: 'left',
            position: 'top',
            floating: false,
            markers: {
                width: 8,
                height: 8,
            },
            itemMargin: {
                horizontal: 8,
            },
        },
    }

    return (
        <>
            <Chart
                options={options as ApexOptions}
                series={options.series}
                height={props.height}
                type="bar"
                width="100%"
            />

            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                :global(.apexcharts-legend-marker) {
                    margin-right: 8px !important;
                }
                :global(.apexcharts-tooltip) {
                    padding-bottom: 6px;
                }
                :global(.apexcharts-tooltip-title) {
                    background-color: #fff !important;
                    border-bottom-color: #eee !important;
                    padding: 6px 16px !important;
                    font-weight: 700;
                }
                :global(.apexcharts-tooltip-series-group) {
                    padding: 0 16px !important;
                }
                :global(.apexcharts-tooltip-marker) {
                    width: 10px;
                    height: 10px;
                }
            `}</style>
        </>
    )
}
