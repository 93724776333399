import React, { useMemo, useState } from 'react'
import { AreaTableRow, MarketAreaInfo } from '../../../../generated/graphql'
import { AreaInformationContentTable } from './AreaInformationContentTable'
import { Field, Form } from 'react-final-form'
import { ReactSelectAdapter } from '../../../controls/inputs/ReactSelectAdapter'
import numeral from 'numeral'
import { useRouter } from 'next/router'

export function AreaInformationContent(props: {
    areaInformation: MarketAreaInfo[]
    noTitle?: boolean
    initialValue?: number
}) {
    const options = []

    const reducer = (accum, item) => {
        return accum + item.value
    }

    if (props.areaInformation) {
        for (const areaItem of props.areaInformation) {
            let sumOfItems = 0

            if (areaItem.familyHouseHoldsVisible) {
                sumOfItems += areaItem.familyHouseHolds.reduce(reducer, 0)
            }
            if (areaItem.houseHoldStructureVisible) {
                sumOfItems += areaItem.houseHoldStructure.reduce(reducer, 0)
            }
            if (areaItem.householdChildrenVisible) {
                sumOfItems += areaItem.householdChildren.reduce(reducer, 0)
            }
            if (areaItem.householdOwnerShipVisible) {
                sumOfItems += areaItem.householdOwnerShip.reduce(reducer, 0)
            }
            if (areaItem.incomeVisible) {
                sumOfItems += areaItem.income.reduce(reducer, 0)
            }
            if (areaItem.occupancyVisible) {
                sumOfItems += areaItem.occupancy.reduce(reducer, 0)
            }
            if (areaItem.yearBuiltVisible) {
                sumOfItems += areaItem.yearBuilt.reduce(reducer, 0)
            }

            sumOfItems += areaItem.properties
            sumOfItems += areaItem.totalUnits
            sumOfItems += areaItem.totalUnitsSQFt

            if (sumOfItems > 0) {
                options.push({ label: `${areaItem.radius} Miles`, value: areaItem.radius })
            }
        }
    }
    const initialValue = props.initialValue || options?.[0]?.value || 3

    const [visibleBlock, setVisibleBlock] = useState(initialValue)

    const getMedianYear = (values: AreaTableRow[]) => {
        let totalBuildings = 0
        let medianBuildings = 0

        for (const item of values) {
            totalBuildings += item.value
        }

        const medianCounter = Math.floor(totalBuildings / 2)
        let year = ''
        for (const item of values) {
            medianBuildings += item.value
            if (medianBuildings > medianCounter) {
                year = item.key
                break
            }
        }

        switch (year) {
            case '1939_or_earlier':
                return 1935
            case '1940_to_1949':
                return 1945
            case '1950_to_1959':
                return 1955
            case '1960_to_1969':
                return 1965
            case '1970_to_1979':
                return 1975
            case '1980_to_1989':
                return 1985
            case '1990_to_1999':
                return 1995
            case '2000_to_2010':
                return 2005
            case '2010_or_later':
                return 2015
        }
        return 0
    }
    const router = useRouter()
    const { printmode } = useMemo(() => {
        let printmode = false
        console.log('props.initialValue', props.initialValue)
        if (props.initialValue != null) {
            printmode = true
        }
        if (router.asPath.indexOf('printmode=true') > -1) {
            printmode = true
        }
        return { printmode }
    }, [router, props.initialValue])
    return (
        <React.Fragment>
            <section className="cblock cblock--main" id="intro">
                <div className="container">
                    <div className="row">
                        {!props?.noTitle && (
                            <div className="cblock__head animatedin in stop">
                                <h1>Area Information</h1>
                            </div>
                        )}
                        {!printmode && (
                            <div className="cblock__body">
                                <div className="row" style={{ display: 'flex' }}>
                                    <div className="col-3" />
                                    <div className="col-6">
                                        <Form
                                            onSubmit={() => {
                                                console.log('hello =)')
                                            }}
                                            validate={(values) => {
                                                setVisibleBlock(values.radius)
                                                return null
                                            }}
                                            render={({ handleSubmit, form, submitting, pristine, values }) => {
                                                return (
                                                    <Field
                                                        component={ReactSelectAdapter}
                                                        name={`radius`}
                                                        placeholder="Radius"
                                                        initialValue={initialValue}
                                                        options={options}
                                                        customBackgroundColor={'#242831'}
                                                    />
                                                )
                                            }}
                                        />
                                    </div>
                                    <div className="col-3" />
                                </div>
                            </div>
                        )}

                        {props.areaInformation.map((areaInformation, index) => {
                            return (
                                <div
                                    className={`cblock__body ${
                                        visibleBlock === areaInformation.radius ? '' : 'd-none'
                                    }`}
                                    key={index}
                                >
                                    <div className="row" style={{ display: 'flex' }}>
                                        <div className="col-6">
                                            {/*//*/}

                                            <table className="table table-borderless table-striped table--smallmobile animatedin in stop">
                                                <thead>
                                                    <tr>
                                                        <th>Area characteristics</th>
                                                        <th className="text-right"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Properties in the Area</td>
                                                        <td className="text-right font-weight-bold">
                                                            {numeral(areaInformation.properties).format('0,0')}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Units in the Area</td>
                                                        <td className="text-right font-weight-bold">
                                                            {numeral(areaInformation.totalUnits).format('0,0')}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Unit SqFt in the Area</td>
                                                        <td className="text-right font-weight-bold">
                                                            {numeral(areaInformation.totalUnitsSQFt).format('0,0')}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <AreaInformationContentTable
                                                title={'Household Ownership'}
                                                tableContent={areaInformation.householdOwnerShip}
                                                visible={areaInformation.householdOwnerShipVisible}
                                                total={areaInformation.totalUnits}
                                            />

                                            <AreaInformationContentTable
                                                title={'Households with Children'}
                                                tableContent={areaInformation.householdChildren}
                                                visible={areaInformation.householdChildrenVisible}
                                                total={areaInformation.totalUnits}
                                            />

                                            <AreaInformationContentTable
                                                title={'Year Built'}
                                                tableContent={areaInformation.yearBuilt}
                                                visible={areaInformation.yearBuiltVisible}
                                                total={areaInformation.properties}
                                            />

                                            {getMedianYear(areaInformation.yearBuilt) > 0 && (
                                                <div className="row">
                                                    <div className="col-12">
                                                        <b>Median Year Built:</b>{' '}
                                                        {getMedianYear(areaInformation.yearBuilt)}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6">
                                            <AreaInformationContentTable
                                                title={'Housing and Occupancy'}
                                                tableContent={areaInformation.occupancy}
                                                visible={areaInformation.occupancyVisible}
                                                total={areaInformation.totalUnits}
                                            />

                                            <AreaInformationContentTable
                                                title={'Family Households'}
                                                tableContent={areaInformation.familyHouseHolds}
                                                visible={areaInformation.familyHouseHoldsVisible}
                                                total={areaInformation.totalUnits}
                                            />

                                            <AreaInformationContentTable
                                                title={'Household Structure'}
                                                tableContent={areaInformation.houseHoldStructure}
                                                visible={areaInformation.houseHoldStructureVisible}
                                                total={areaInformation.properties}
                                            />

                                            <AreaInformationContentTable
                                                title={'Income'}
                                                tableContent={areaInformation.income}
                                                visible={areaInformation.incomeVisible}
                                                total={areaInformation.totalUnits}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            {/*language=scss*/}
            <style jsx>{`
                .table {
                    border: 1px solid #1fb6dc;
                    color: white;
                }
            `}</style>
        </React.Fragment>
    )
}
