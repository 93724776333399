import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import numeral from 'numeral'
import { ReduxStoreState } from '../../../../../../store'
import { Spinner } from '../../../../../controls/Spinner'
import { InsightsTable } from '../../../../operating-assumptions/submarket-insights/InsightsTable'
import { ApexSubmarketVsMsaChart } from '../../../../operating-assumptions/submarket-insights/multi-family-performance/ApexSubmarketVsMsaChart'
import { ApexPerformanceGrowthChart } from '../../../../operating-assumptions/submarket-insights/multi-family-performance/ApexPerformanceGrowthChart'
import BasePageLayoutOM from '../../BasePageLayoutOM'
import ReportSection from '../../ReportSection'

export const SubmarketVsMsaOM = (props: any) => {
    const performanceByPropertyAge = useSelector(
        (state: ReduxStoreState) => state.operatingAssumptions.submarketInsights.performanceByPropertyAge,
    )
    const performanceGrowth = useSelector(
        (state: ReduxStoreState) => state.operatingAssumptions.submarketInsights.performanceGrowth,
    )
    const submarketName = useSelector(
        (state: ReduxStoreState) => state.operatingAssumptions.submarketInsights.submarketName,
    )

    const submarketNameSplitted = submarketName.split(', ')
    const subName = submarketNameSplitted.length === 1 ? null : submarketNameSplitted[0]
    const metroName = submarketNameSplitted.length === 1 ? submarketNameSplitted[0] : submarketNameSplitted[1]

    const yearlyTitles = performanceGrowth.market?.yearly?.map((item) => item.title || null)
    const showYearlyData = yearlyTitles?.every((item) => !!item)

    const askingRentChartData = useMemo(() => {
        return {
            labels: [] as string[],
            values: [] as number[],
            chartSeries: [
                {
                    name: 'Mean',
                    type: 'column',
                    data: [
                        performanceByPropertyAge.submarket?.askingRent?.mean || null,
                        performanceByPropertyAge.market?.askingRent?.mean || null,
                    ],
                },
                {
                    name: 'Similarly Aged',
                    type: 'column',
                    data: [
                        performanceByPropertyAge.submarket?.askingRent?.similarlyAged || null,
                        performanceByPropertyAge.market?.askingRent?.similarlyAged || null,
                    ],
                },
                {
                    name: 'Median',
                    type: 'column',
                    data: [
                        performanceByPropertyAge.submarket?.askingRent?.median || null,
                        performanceByPropertyAge.market?.askingRent?.median || null,
                    ],
                },
            ],
            categories: ['Submarket', 'Metro'],
            colors: ['#5791FF', '#DC82CF', '#71CAC4'],
        }
    }, [performanceByPropertyAge])

    const vacancyRateChartData = useMemo(() => {
        return {
            labels: [] as string[],
            values: [] as number[],
            chartSeries: [
                {
                    name: 'Mean',
                    type: 'column',
                    data: [
                        performanceByPropertyAge.submarket?.vacancyRent?.mean || null,
                        performanceByPropertyAge.market?.vacancyRent?.mean || null,
                    ],
                },
                {
                    name: 'Similarly Aged',
                    type: 'column',
                    data: [
                        performanceByPropertyAge.submarket?.vacancyRent?.similarlyAged || null,
                        performanceByPropertyAge.market?.vacancyRent?.similarlyAged || null,
                    ],
                },
                {
                    name: 'Median',
                    type: 'column',
                    data: [
                        performanceByPropertyAge.submarket?.vacancyRent?.median || null,
                        performanceByPropertyAge.market?.vacancyRent?.median || null,
                    ],
                },
            ],
            categories: ['Submarket', 'Metro'],
            colors: ['#5791FF', '#DC82CF', '#71CAC4'],
        }
    }, [performanceByPropertyAge])

    const yearlyChartFallbackData = yearlyTitles.map(() => null)

    const performanceGrowthChartData = useMemo(() => {
        if (!showYearlyData) return null
        return {
            labels: [] as string[],
            values: [] as number[],
            chartSeries: [
                {
                    name: 'Asking Rent Growth Submarket',
                    type: 'line',
                    data: !!performanceGrowth.submarket?.yearly?.length
                        ? performanceGrowth.submarket.yearly.map((item) => +(item.askingRent * 100).toFixed(2) || null)
                        : yearlyChartFallbackData,
                },
                {
                    name: 'Vacancy Rates Submarket',
                    type: 'line',
                    data: !!performanceGrowth.submarket?.yearly?.length
                        ? performanceGrowth.submarket.yearly.map((item) => item.vacancyRate || null)
                        : yearlyChartFallbackData,
                },
                {
                    name: 'Inventory Growth Submarket',
                    type: 'line',
                    data: !!performanceGrowth.submarket?.yearly?.length
                        ? performanceGrowth.submarket.yearly.map((item) => +(item.inventory * 100).toFixed(2) || null)
                        : yearlyChartFallbackData,
                },

                {
                    name: 'Asking Rent Growth Market',
                    type: 'line',
                    data: !!performanceGrowth.market?.yearly?.length
                        ? performanceGrowth.market.yearly.map((item) => +(item.askingRent * 100).toFixed(2) || null)
                        : yearlyChartFallbackData,
                },
                {
                    name: 'Vacancy Rates Market',
                    type: 'line',
                    data: !!performanceGrowth.market?.yearly?.length
                        ? performanceGrowth.market.yearly.map((item) => item.vacancyRate || null)
                        : yearlyChartFallbackData,
                },
                {
                    name: 'Inventory Growth Market',
                    type: 'line',
                    data: !!performanceGrowth.market?.yearly?.length
                        ? performanceGrowth.market.yearly.map((item) => +(item.inventory * 100).toFixed(2) || null)
                        : yearlyChartFallbackData,
                },
            ],
            categories: performanceGrowth.market.yearly.map((item) => item.title),
            colors: ['#5791FF', '#DC82CF', '#71CAC4', '#5791FF', '#DC82CF', '#71CAC4'],
        }
    }, [performanceGrowth, showYearlyData])

    const SubVsMsaTableData = useMemo(() => {
        return {
            header: [
                {
                    cells: [
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: 'MEAN',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: 'SIMILARLY AGED',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: 'MEDIAN',
                            bordering: false,
                            align: 'right',
                        },
                    ],
                },
            ],
            body: [
                {
                    title: '',
                    cells: [
                        {
                            content: 'Asking Rent',
                            bordering: false,
                            align: 'left',
                            bold: true,
                            width: 200,
                        },
                        {
                            content: 'Sub',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: !!performanceByPropertyAge.submarket?.askingRent?.mean
                                ? numeral(performanceByPropertyAge.submarket?.askingRent?.mean).format('$0,0')
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: !!performanceByPropertyAge.submarket?.askingRent?.similarlyAged
                                ? numeral(performanceByPropertyAge.submarket?.askingRent?.similarlyAged).format('$0,0')
                                : 'n/a',

                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: !!performanceByPropertyAge.submarket?.askingRent?.median
                                ? numeral(performanceByPropertyAge.submarket?.askingRent?.median).format('$0,0')
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                    ],
                },

                {
                    title: '',
                    cells: [
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: 'Met',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: !!performanceByPropertyAge.market?.askingRent?.mean
                                ? numeral(performanceByPropertyAge.market?.askingRent?.mean).format('$0,0')
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: !!performanceByPropertyAge.market?.askingRent?.similarlyAged
                                ? numeral(performanceByPropertyAge.market?.askingRent?.similarlyAged).format('$0,0')
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: !!performanceByPropertyAge.market?.askingRent?.median
                                ? numeral(performanceByPropertyAge.market?.askingRent?.median).format('$0,0')
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                    ],
                },

                {
                    title: '',
                    cells: [
                        {
                            content: 'Vacancy Rate',
                            bordering: false,
                            align: 'left',
                            bold: true,
                            width: 200,
                        },
                        {
                            content: 'Sub',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: !!performanceByPropertyAge.submarket?.vacancyRent?.mean
                                ? numeral(performanceByPropertyAge.submarket?.vacancyRent?.mean / 100).format('0.[00]%')
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: !!performanceByPropertyAge.submarket?.vacancyRent?.similarlyAged
                                ? numeral(performanceByPropertyAge.submarket?.vacancyRent?.similarlyAged / 100).format(
                                      '0.[00]%',
                                  )
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: !!performanceByPropertyAge.submarket?.vacancyRent?.median
                                ? numeral(performanceByPropertyAge.submarket?.vacancyRent?.median / 100).format(
                                      '0.[00]%',
                                  )
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                    ],
                },

                {
                    title: '',
                    cells: [
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: 'Met',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: !!performanceByPropertyAge.market?.vacancyRent?.mean
                                ? numeral(performanceByPropertyAge.market?.vacancyRent?.mean / 100).format('0.[00]%')
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: !!performanceByPropertyAge.market?.vacancyRent?.similarlyAged
                                ? numeral(performanceByPropertyAge.market?.vacancyRent?.similarlyAged / 100).format(
                                      '0.[00]%',
                                  )
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: !!performanceByPropertyAge.market?.vacancyRent?.median
                                ? numeral(performanceByPropertyAge.market?.vacancyRent?.median / 100).format('0.[00]%')
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                    ],
                },

                {
                    title: '',
                    cells: [
                        {
                            content: 'Inventory\u{002A}',
                            bordering: false,
                            align: 'left',
                            bold: true,
                            width: 200,
                        },
                        {
                            content: 'Sub',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: !!performanceByPropertyAge.submarket?.inventory?.mean
                                ? numeral(performanceByPropertyAge.submarket?.inventory?.mean / 100).format('0.[00]%')
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: !!performanceByPropertyAge.submarket?.inventory?.similarlyAged
                                ? numeral(performanceByPropertyAge.submarket?.inventory?.similarlyAged / 100).format(
                                      '0.[00]%',
                                  )
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: !!performanceByPropertyAge.submarket?.inventory?.median
                                ? numeral(performanceByPropertyAge.submarket?.inventory?.median).format('0,00')
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                    ],
                },

                {
                    title: '',
                    cells: [
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: 'Met',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: !!performanceByPropertyAge.market?.inventory?.mean
                                ? numeral(performanceByPropertyAge.market?.inventory?.mean / 100).format('0.[00]%')
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: !!performanceByPropertyAge.market?.inventory?.similarlyAged
                                ? numeral(performanceByPropertyAge.market?.inventory?.similarlyAged / 100).format(
                                      '0.[00]%',
                                  )
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: !!performanceByPropertyAge.market?.inventory?.median
                                ? numeral(performanceByPropertyAge.market?.inventory?.median).format('0,00')
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                    ],
                },
            ],
        }
    }, [performanceByPropertyAge])

    const quarterlyGrowthTableData = useMemo(() => {
        return {
            header: [
                {
                    cells: [
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: 'Quarterly',
                            bordering: true,
                            align: 'center',
                            colspan: 3,
                            customStyles: {
                                borderBottom: '5px solid #EAEEF1',
                                color: '#161C26',
                                textTransform: 'capitalize',
                                fontSize: '0.85rem',
                            },
                        },
                        {
                            content: 'Annualized',
                            bordering: false,
                            align: 'center',
                            colspan: 4,
                            customStyles: {
                                borderBottom: '5px solid #EAEEF1',
                                color: '#161C26',
                                textTransform: 'capitalize',
                                fontSize: '0.85rem',
                            },
                        },
                    ],
                },
                {
                    cells: [
                        {
                            content: '',
                            bordering: false,
                            align: 'right',
                            colspan: 4,
                        },
                        {
                            content: '',
                            bordering: true,
                            align: 'right',
                        },
                        {
                            content: '',
                            bordering: false,
                            align: 'right',
                            colspan: 4,
                        },
                    ],
                },
                {
                    cells: [
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: performanceGrowth.market?.quarterly?.[0]?.title || 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: performanceGrowth.market?.quarterly?.[1]?.title || 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: performanceGrowth.market?.quarterly?.[2]?.title || 'n/a',
                            bordering: true,
                            align: 'right',
                        },
                        {
                            content: '1 YR',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: '3 YR',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: '5 YR',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: '5 YR FORECAST',
                            bordering: false,
                            align: 'right',
                        },
                    ],
                },
            ],
            body: [
                {
                    title: '',
                    cells: [
                        {
                            content: 'Asking Rent Growth',
                            bordering: false,
                            align: 'left',
                            bold: true,
                            width: 200,
                        },
                        {
                            content: 'Sub',
                            bordering: false,
                            align: 'left',
                        },
                    ]
                        .concat(
                            !!performanceGrowth.submarket?.quarterly?.length
                                ? performanceGrowth.submarket.quarterly.map((item, idx) => ({
                                      content: numeral(item.askingRent).format('0.[00]%'),
                                      bordering: idx === 2,
                                      align: 'right',
                                  }))
                                : [1, 2, 3].map((item, idx) => ({
                                      content: 'n/a',
                                      bordering: idx === 2,
                                      align: 'right',
                                  })),
                        )
                        .concat(
                            !!performanceGrowth.submarket?.annualized?.length
                                ? performanceGrowth.submarket.annualized.map((item) => ({
                                      content: numeral(item.askingRent).format('0.[00]%'),
                                      bordering: false,
                                      align: 'right',
                                  }))
                                : [1, 2, 3, 4].map((item, idx) => ({
                                      content: 'n/a',
                                      bordering: false,
                                      align: 'right',
                                  })),
                        ),
                },
                {
                    title: '',
                    cells: [
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: 'Met',
                            bordering: false,
                            align: 'left',
                        },
                    ]
                        .concat(
                            !!performanceGrowth.market?.quarterly?.length
                                ? performanceGrowth.market.quarterly.map((item, idx) => ({
                                      content: numeral(item.askingRent).format('0.[00]%'),
                                      bordering: idx === 2,
                                      align: 'right',
                                  }))
                                : [1, 2, 3].map((item, idx) => ({
                                      content: 'n/a',
                                      bordering: idx === 2,
                                      align: 'right',
                                  })),
                        )
                        .concat(
                            !!performanceGrowth.market?.annualized?.length
                                ? performanceGrowth.market.annualized.map((item) => ({
                                      content: numeral(item.askingRent).format('0.[00]%'),
                                      bordering: false,
                                      align: 'right',
                                  }))
                                : [1, 2, 3, 4].map((item, idx) => ({
                                      content: 'n/a',
                                      bordering: false,
                                      align: 'right',
                                  })),
                        ),
                },
                {
                    title: '',
                    cells: [
                        {
                            content: 'Vacancy Rates',
                            bordering: false,
                            align: 'left',
                            bold: true,
                            width: 200,
                        },
                        {
                            content: 'Sub',
                            bordering: false,
                            align: 'left',
                        },
                    ]
                        .concat(
                            !!performanceGrowth.submarket?.quarterly?.length
                                ? performanceGrowth.submarket.quarterly.map((item, idx) => ({
                                      content: numeral(item.vacancyRate / 100).format('0.[00]%'),
                                      bordering: idx === 2,
                                      align: 'right',
                                  }))
                                : [1, 2, 3].map((item, idx) => ({
                                      content: 'n/a',
                                      bordering: idx === 2,
                                      align: 'right',
                                  })),
                        )
                        .concat(
                            !!performanceGrowth.submarket?.annualized?.length
                                ? performanceGrowth.submarket.annualized.map((item) => ({
                                      content: numeral(item.vacancyRate / 100).format('0.[00]%'),
                                      bordering: false,
                                      align: 'right',
                                  }))
                                : [1, 2, 3, 4].map((item, idx) => ({
                                      content: 'n/a',
                                      bordering: false,
                                      align: 'right',
                                  })),
                        ),
                },
                {
                    title: '',
                    cells: [
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: 'Met',
                            bordering: false,
                            align: 'left',
                        },
                    ]
                        .concat(
                            !!performanceGrowth.market?.quarterly?.length
                                ? performanceGrowth.market.quarterly.map((item, idx) => ({
                                      content: numeral(item.vacancyRate / 100).format('0.[00]%'),
                                      bordering: idx === 2,
                                      align: 'right',
                                  }))
                                : [1, 2, 3].map((item, idx) => ({
                                      content: 'n/a',
                                      bordering: idx === 2,
                                      align: 'right',
                                  })),
                        )
                        .concat(
                            !!performanceGrowth.market?.annualized?.length
                                ? performanceGrowth.market.annualized.map((item) => ({
                                      content: numeral(item.vacancyRate / 100).format('0.[00]%'),
                                      bordering: false,
                                      align: 'right',
                                  }))
                                : [1, 2, 3, 4].map((item, idx) => ({
                                      content: 'n/a',
                                      bordering: false,
                                      align: 'right',
                                  })),
                        ),
                },
                {
                    title: '',
                    cells: [
                        {
                            content: 'Inventory Growth Rates',
                            bordering: false,
                            align: 'left',
                            bold: true,
                            width: 200,
                        },
                        {
                            content: 'Sub',
                            bordering: false,
                            align: 'left',
                        },
                    ]
                        .concat(
                            !!performanceGrowth.submarket?.quarterly?.length
                                ? performanceGrowth.submarket.quarterly.map((item, idx) => ({
                                      content: numeral(item.inventory).format('0.[00]%'),
                                      bordering: idx === 2,
                                      align: 'right',
                                  }))
                                : [1, 2, 3].map((item, idx) => ({
                                      content: 'n/a',
                                      bordering: idx === 2,
                                      align: 'right',
                                  })),
                        )
                        .concat(
                            !!performanceGrowth.submarket?.annualized?.length
                                ? performanceGrowth.submarket.annualized.map((item, idx) => ({
                                      content: numeral(item.inventory).format('0.[00]%'),
                                      bordering: false,
                                      align: 'right',
                                  }))
                                : [1, 2, 3, 4].map((item, idx) => ({
                                      content: 'n/a',
                                      bordering: false,
                                      align: 'right',
                                  })),
                        ),
                },
                {
                    title: '',
                    cells: [
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: 'Met',
                            bordering: false,
                            align: 'left',
                        },
                    ]
                        .concat(
                            !!performanceGrowth.market?.quarterly?.length
                                ? performanceGrowth.market.quarterly.map((item, idx) => ({
                                      content: numeral(item.inventory).format('0.[00]%'),
                                      bordering: idx === 2,
                                      align: 'right',
                                  }))
                                : [1, 2, 3].map((item, idx) => ({
                                      content: 'n/a',
                                      bordering: idx === 2,
                                      align: 'right',
                                  })),
                        )
                        .concat(
                            !!performanceGrowth.market?.annualized?.length
                                ? performanceGrowth.market.annualized.map((item) => ({
                                      content: numeral(item.inventory).format('0.[00]%'),
                                      bordering: false,
                                      align: 'right',
                                  }))
                                : [1, 2, 3, 4].map((item, idx) => ({
                                      content: 'n/a',
                                      bordering: false,
                                      align: 'right',
                                  })),
                        ),
                },
            ],
        }
    }, [performanceGrowth])

    const yearlyFallbackArray =
        yearlyTitles?.map((item) => ({
            content: 'n/a',
            bordering: false,
            align: 'right',
        })) || []

    const yearlyGrowthTableData = useMemo(() => {
        if (!showYearlyData) return null
        return {
            header: [
                {
                    cells: [
                        {
                            content: '',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: '',
                            bordering: false,
                            align: 'right',
                        },
                    ].concat(
                        performanceGrowth.market.yearly.map((item) => ({
                            content: item.title,
                            bordering: false,
                            align: 'right',
                        })),
                    ),
                },
            ],
            body: [
                {
                    title: '',
                    cells: [
                        {
                            content: 'Rent Growth',
                            bordering: false,
                            align: 'left',
                            bold: true,
                            width: 200,
                        },
                        {
                            content: 'Sub',
                            bordering: false,
                            align: 'left',
                        },
                    ].concat(
                        !!performanceGrowth.submarket?.yearly?.length
                            ? performanceGrowth.submarket.yearly.map((item) => ({
                                  content: numeral(item.askingRent).format('0.[00]%'),
                                  bordering: false,
                                  align: 'right',
                              }))
                            : yearlyFallbackArray,
                    ),
                },
                {
                    title: '',
                    cells: [
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: 'Met',
                            bordering: false,
                            align: 'left',
                        },
                    ].concat(
                        !!performanceGrowth.market?.yearly?.length
                            ? performanceGrowth.market.yearly.map((item) => ({
                                  content: numeral(item.askingRent).format('0.[00]%'),
                                  bordering: false,
                                  align: 'right',
                              }))
                            : yearlyFallbackArray,
                    ),
                },
                {
                    title: '',
                    cells: [
                        {
                            content: 'Vacancy Trends',
                            bordering: false,
                            align: 'left',
                            bold: true,
                            width: 200,
                        },
                        {
                            content: 'Sub',
                            bordering: false,
                            align: 'left',
                        },
                    ].concat(
                        !!performanceGrowth.submarket?.yearly?.length
                            ? performanceGrowth.submarket.yearly.map((item) => ({
                                  content: numeral(item.vacancyRate / 100).format('0.[00]%'),
                                  bordering: false,
                                  align: 'right',
                              }))
                            : yearlyFallbackArray,
                    ),
                },
                {
                    title: '',
                    cells: [
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: 'Met',
                            bordering: false,
                            align: 'left',
                        },
                    ].concat(
                        !!performanceGrowth.market.yearly?.length
                            ? performanceGrowth.market.yearly.map((item) => ({
                                  content: numeral(item.vacancyRate / 100).format('0.[00]%'),
                                  bordering: false,
                                  align: 'right',
                              }))
                            : yearlyFallbackArray,
                    ),
                },
                {
                    title: '',
                    cells: [
                        {
                            content: 'Inventory Growth',
                            bordering: false,
                            align: 'left',
                            bold: true,
                            width: 200,
                        },
                        {
                            content: 'Sub',
                            bordering: false,
                            align: 'left',
                        },
                    ].concat(
                        !!performanceGrowth.submarket?.yearly?.length
                            ? performanceGrowth.submarket.yearly.map((item) => ({
                                  content: numeral(item.inventory).format('0.[00]%'),
                                  bordering: false,
                                  align: 'right',
                              }))
                            : yearlyFallbackArray,
                    ),
                },
                {
                    title: '',
                    cells: [
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: 'Met',
                            bordering: false,
                            align: 'left',
                        },
                    ].concat(
                        !!performanceGrowth.market.yearly?.length
                            ? performanceGrowth.market.yearly.map((item) => ({
                                  content: numeral(item.inventory).format('0.[00]%'),
                                  bordering: false,
                                  align: 'right',
                              }))
                            : yearlyFallbackArray,
                    ),
                },
            ],
        }
    }, [performanceGrowth, showYearlyData])

    return !!performanceByPropertyAge ? (
        <>
            <div className={'reportPage page a4-150dpiSpecialOM'}>
                <BasePageLayoutOM title={'SubMarket Insights'} subtitle={props.headerSubtitle}>
                    <ReportSection
                        title={'MultiFamily Performance'}
                        className={'MultiFamilyPerformance'}
                        titleSmall={true}
                    >
                        <h3 className=" mb-3">Submarket vs MSA</h3>
                        <div className="upper-charts-wrapper" style={{ position: 'relative', left: '-10px' }}>
                            <ApexSubmarketVsMsaChart
                                series={askingRentChartData.chartSeries}
                                categories={askingRentChartData.categories}
                                colors={askingRentChartData.colors}
                                height={280}
                                title="Asking Rent"
                                yaxisLablesType="dollar"
                            />
                            <ApexSubmarketVsMsaChart
                                series={vacancyRateChartData.chartSeries}
                                categories={vacancyRateChartData.categories}
                                colors={vacancyRateChartData.colors}
                                height={280}
                                title="Vacancy Rate"
                                yaxisLablesType="percent"
                            />
                        </div>
                        <div className="names-wrapper">
                            {!!subName && (
                                <div>
                                    <span>Submarket:</span> {subName}
                                </div>
                            )}
                            {!!metroName && (
                                <div>
                                    <span>Metro:</span> {metroName}
                                </div>
                            )}
                        </div>
                        <InsightsTable tableHeader={SubVsMsaTableData.header} tableBody={SubVsMsaTableData.body} />
                        <div>{'\u{002A}Mean Inventory is synonymous with all inventory.'}</div>
                        <div style={{ marginTop: 30, marginBottom: 30 }}>
                            <InsightsTable
                                tableHeader={quarterlyGrowthTableData.header}
                                tableBody={quarterlyGrowthTableData.body}
                            />
                        </div>
                    </ReportSection>
                </BasePageLayoutOM>
            </div>

            {showYearlyData && (
                <div className={'reportPage page a4-150dpiSpecialOM'}>
                    <BasePageLayoutOM title={'SubMarket Insights'} subtitle={props.headerSubtitle}>
                        <ReportSection
                            title={'MultiFamily Performance'}
                            className={'MultiFamilyPerformance'}
                            titleSmall={true}
                        >
                            <div style={{ position: 'relative', left: '-10px' }}>
                                <ApexPerformanceGrowthChart
                                    series={performanceGrowthChartData.chartSeries}
                                    categories={performanceGrowthChartData.categories}
                                    colors={performanceGrowthChartData.colors}
                                    height={350}
                                />
                            </div>
                            <div className="names-wrapper">
                                {!!subName && (
                                    <div>
                                        <span>Submarket:</span> {subName}
                                    </div>
                                )}
                                {!!metroName && (
                                    <div>
                                        <span>Metro:</span> {metroName}
                                    </div>
                                )}
                            </div>
                            <div style={{ marginTop: 30, marginBottom: 30 }}>
                                <InsightsTable
                                    tableHeader={yearlyGrowthTableData.header}
                                    tableBody={yearlyGrowthTableData.body}
                                />
                            </div>
                        </ReportSection>
                    </BasePageLayoutOM>
                </div>
            )}
            {/*language=SCSS*/}
            <style jsx>
                {`
                    .upper-charts-wrapper {
                        display: flex;
                        width: 100%;
                    }
                    .names-wrapper {
                        display: flex;
                        gap: 20px;
                        font-size: 1.1rem;
                        margin-bottom: 10px;

                        span {
                            font-weight: 600;
                        }
                    }
                `}
            </style>
        </>
    ) : (
        <Spinner />
    )
}
